import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';

@Component({
  selector: 'app-finalise-invoice-dialog',
  templateUrl: './finalise-invoice-dialog.component.html',
  styleUrls: ['./finalise-invoice-dialog.component.css'],
  providers: [InvoicingService]
})
export class FinaliseInvoiceDialogComponent implements OnInit {
  invoice: Invoice;
loading: boolean = false;
  constructor(public dialogRef: MatDialogRef<FinaliseInvoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.invoice = this.data.invoice;


  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  finalise() {

    this.loading = true;

    this.invoicingService.finaliseInvoice(this.invoice.invoiceId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.openSnackBar("Invoice Finalised");
        this.loading = false;
        this.dialogRef.close("email");

      }


    })

  }
}
