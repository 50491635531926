import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';

import { ProductTypesData, ProductTypes, ProductType } from '../models/productTypesData';
import { StatusTypesData, StatusType, StatusTypes } from '../models/invoiceStatusData';
import { InvoiceProductsData, InvoiceProducts, Products, Product, InvoiceRecurringProductsData } from '../models/invoicingProductsData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, VoidInvoice, AddProductPOST, AutocompleteInvoice, EmailInvoiceData, PostEmailInvoice, SentInvoiceData } from '../models/invoiceData';


@Injectable()
export class InvoicingService {

  constructor(public http: HttpClient) { }

  getInvoiceStatusType(statusTypeId:number, name: number, isActive:number ): Observable<StatusTypesData> {
    return this.http.get<StatusTypesData>(AppSettings.API_ENDPOINT + "/invoiceStatusType/" + statusTypeId + '/' + name + '/' + isActive);
  }
  getInvoiceProduct(productId: number, productCode: any, productName: any, productTypeId: number, officeId: number, taxId: number, isActive: number, isDisplayInvoice: number, sortId: number, sortDir: string, pageNo: number ): Observable<InvoiceProductsData> {
    return this.http.get<InvoiceProductsData>(AppSettings.API_ENDPOINT + "/invoiceProduct/" +  productId + '/' + productCode + '/' + productName + '/' + productTypeId + '/' + officeId + '/' + taxId + '/' + isActive + '/' + isDisplayInvoice + '/' + sortId + '/' + sortDir + '/' + pageNo);
  }
  assignInvoiceRecurringProduct(productId: number, clientId: any, qty: number): Observable<InvoiceProductsData> {
    return this.http.get<InvoiceProductsData>(AppSettings.API_ENDPOINT + "/invoiceRecurringProduct/add/" +  productId + '/' + clientId + '/' + qty);
  }
  getInvoiceRecurringProduct(productId: number, clientId: any): Observable<InvoiceRecurringProductsData> {
    return this.http.get<InvoiceRecurringProductsData>(AppSettings.API_ENDPOINT + "/invoiceRecurringProduct/" +  productId + '/' + clientId);
  }
  getInvoices(invoiceId: number, clientId: number, officeId: number, invoiceTypeId: number, statusId: number, isActive: number, sortId: number, sortDir: string, pageNo: number ): Observable<InvoiceData> {
    return this.http.get<InvoiceData>(AppSettings.API_ENDPOINT + "/invoice/" +  invoiceId + '/' + clientId + '/' + officeId + '/' + invoiceTypeId + '/' + statusId + '/' + isActive + '/' + sortId + '/' + sortDir + '/' + pageNo);
  }
  updateLineItem(lineItemId: number, lineItemData: any ): Observable<AddLinePOST> {
    return this.http.post<AddLinePOST>(AppSettings.API_ENDPOINT + "/invoiceLineItem/update/" +  lineItemId, lineItemData);
  }
  getInvoiceSettings(invoiceSettingId: number, officeId: number, name: any, isActive: number): Observable<InvoiceSettingsData> {
    return this.http.get<InvoiceSettingsData>(AppSettings.API_ENDPOINT + "/invoiceSettings/" +  invoiceSettingId + '/' + officeId + '/' + name + '/' + isActive);
  }
  addInvoiceSettings(lineItemData: any ): Observable<InvoiceSettingsData> {
    return this.http.post<InvoiceSettingsData>(AppSettings.API_ENDPOINT + "/invoiceSettings/add", lineItemData);
  }
  updateInvoiceSettings(invoiceSettingId: number , lineItemData: any ): Observable<InvoiceSettingsData> {
    return this.http.post<InvoiceSettingsData>(AppSettings.API_ENDPOINT + "/invoiceSettings/update/" + invoiceSettingId, lineItemData);
  }
  addInvoice(invoiceData: any ): Observable<AddInvoicePOST> {
    return this.http.post<AddInvoicePOST>(AppSettings.API_ENDPOINT + "/invoice/add", invoiceData);
  }
  updateInvoice(invoiceId: number, invoiceData: any ): Observable<AddInvoicePOST> {
    return this.http.post<AddInvoicePOST>(AppSettings.API_ENDPOINT + "/invoice/update/" + invoiceId, invoiceData);
  }
  addLineItem(lineItemData: any ): Observable<AddLinePOST> {
    return this.http.post<AddLinePOST>(AppSettings.API_ENDPOINT + "/invoiceLineItem/add", lineItemData);
  }
  getProductTypes(productTypeId: number, name: any, isActive: number): Observable<ProductTypesData> {
    return this.http.get<ProductTypesData>(AppSettings.API_ENDPOINT + "/productType/" +  productTypeId + '/' + name + '/' + isActive);
  }
  updateInvoiceProduct(productId: number, ProductData: any ): Observable<AddLinePOST> {
    return this.http.post<AddLinePOST>(AppSettings.API_ENDPOINT + "/invoiceProduct/update/" + productId, ProductData);
  }
  voidInvoice(invoiceId: number): Observable<VoidInvoice> {
    return this.http.get<VoidInvoice>(AppSettings.API_ENDPOINT + "/voidInvoice/" +  invoiceId);
  }
  addInvoiceProduct(ProductData: any ): Observable<AddProductPOST> {
    return this.http.post<AddProductPOST>(AppSettings.API_ENDPOINT + "/invoiceProduct/add", ProductData);
  }
  searchInvoice(invoiceNumber: any ): Observable<AutocompleteInvoice> {
    return this.http.get<AutocompleteInvoice>(AppSettings.API_ENDPOINT + "/invoice/" + invoiceNumber);
  }
  exportToSage(invoiceId: any ) {
    return this.http.get(AppSettings.API_ENDPOINT + "/invoiceExportToSage/" + invoiceId, {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'});
  }
  downloadInvoice(invoiceId: any ) {
    return this.http.get(AppSettings.API_ENDPOINT + "/downloadInvoice/" + invoiceId, {headers: new HttpHeaders().set('Content-Type', 'application/pdf'), responseType: 'blob'});
  }
  raiseCreditNote(invoiceId: any ): Observable<AddInvoicePOST> {
    return this.http.get<AddInvoicePOST>(AppSettings.API_ENDPOINT + "/raiseCreditNote/" + invoiceId);
  }
  raiseBlankCreditNote(clientId: any ): Observable<AddInvoicePOST> {
    return this.http.get<AddInvoicePOST>(AppSettings.API_ENDPOINT + "/raiseBlankCreditNote/" + clientId);
  }
  finaliseInvoice(invoiceId: any ): Observable<AddInvoicePOST> {
    return this.http.get<AddInvoicePOST>(AppSettings.API_ENDPOINT + "/finaliseInvoice/" + invoiceId);
  }
  sendInvoice(invoiceId: number, invoiceData: any ): Observable<PostEmailInvoice> {
    return this.http.post<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/sendInvoice/" + invoiceId, invoiceData);
  }
  completeInvoice(invoiceId: number ): Observable<PostEmailInvoice> {
    return this.http.get<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/completeInvoice/" + invoiceId);
  }
  invoiceSentItems(clientId: number, pageNo: number ): Observable<SentInvoiceData> {
    return this.http.get<SentInvoiceData>(AppSettings.API_ENDPOINT + "/invoiceSentItems/" + clientId + '/' + pageNo);
  }
  changeInvoiceDate(invoiceId: number, invoiceData: any ): Observable<PostEmailInvoice> {
    return this.http.post<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/changeInvoiceDate/" + invoiceId, invoiceData);
  }
  changeInvoicePaymentType(invoiceId: number, paymentTypeId: number, isPermanent: number ): Observable<PostEmailInvoice> {
    return this.http.get<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/changeInvoicePaymentType/" + invoiceId + '/' + paymentTypeId + '/' + isPermanent);
  }
  changeInvoiceTariff(invoiceId: number, tariffId: number ): Observable<PostEmailInvoice> {
    return this.http.get<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/changeInvoiceTariff/" + invoiceId + '/' + tariffId);
  }
  downloadAllInvoices(){
    return this.http.get(AppSettings.API_ENDPOINT + "/downloadAllInvoices", {headers: new HttpHeaders().set('Content-Type', 'application/pdf'), responseType: 'blob'});
  }
  deleteInvoiceRecurringProduct(productId: number, clientId: number ): Observable<PostEmailInvoice> {
    return this.http.get<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/deleteInvoiceRecurringProduct/" + productId + '/' + clientId);
  }

  updateInvoiceRecurringProduct(recurringId: number, quantity: number ): Observable<PostEmailInvoice> {
    return this.http.get<PostEmailInvoice>(AppSettings.API_ENDPOINT + "/invoiceRecurringProduct/update/" + recurringId + '/' + quantity);
  }
  
}


