import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

import { InvoicingService } from '../../services/invoicing.service';
import { InvoiceData, Invoices, Invoice, LineItems, AddInvoiceData } from '../../models/invoiceData';

@Component({
  selector: 'app-raise-credit-note-dialog',
  templateUrl: './raise-credit-note-dialog.component.html',
  styleUrls: ['./raise-credit-note-dialog.component.css'],
  providers: [InvoicingService]
})
export class RaiseCreditNoteDialogComponent implements OnInit {
  invoiceData: AddInvoiceData;

  constructor(private router: Router, private route: ActivatedRoute, public dialogRef: MatDialogRef<RaiseCreditNoteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) {


   }

  ngOnInit() {
    this.invoiceData = this.data.invoiceData;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  createNote(invoiceData: any) {
    console.log(invoiceData);
    this.invoicingService.raiseCreditNote(invoiceData.invoiceId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Credit note created");
        this.router.navigate(['/invoicing/invoice/' + resp.invoiceId]);
        this.dialogRef.close();
      }
    })
  }
}
