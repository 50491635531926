export class MeData {
    errors: any | null;
    user: Me[];

  }
  export class Me{

    emailAddress: string;
    userId: number;
    userName: string;
    officeName: string;
    officeId: number;
    roles: Roles[];
  }

  export class Roles {
    name: string;
    roleId: number;

  }
  export class LoginDetails {
    email: string;
    password: string;

  }
