import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { UserSessionService } from '../../services/user-session.service';
import { InvoicingService } from '../../services/invoicing.service';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettings, InvoiceSetting } from '../../models/invoiceData';
import { UserData, UserDetails, Users, User } from '../../models/userData';


@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.css'],
  providers: [ClientsContactsService, OfficesUsersRolesService, UserSessionService, InvoicingService]
})
export class PrintInvoiceComponent implements OnInit {
  invoiceId: number = 0;
  selectedInvoice: Invoice;
  lineItems: LineItems[] = [];
  invoiceSettings: InvoiceSettings;
  officeId: number = 0;
  officeIdSubject: Observable<string>;
  clientId: number = 0;
  clientDetails: Client;
  officeDetails: Office;
  userDetails: User;
  sessionCheck: any;

  constructor(public officesUsersRolesService: OfficesUsersRolesService, private route: ActivatedRoute, public clientsContactsService: ClientsContactsService, public invoicingService: InvoicingService, public userSessionService: UserSessionService, public router: Router, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {

    this.officeIdSubject = userSessionService.getOfficeId();

    this.sessionCheck = setTimeout(() => {
     
      window.print();

    }, 75 * 10 * 1);
  }

  ngOnInit() {

    this.selectedInvoice = new Invoice();
    this.clientDetails = new Client();
    this.officeDetails = new Office();
    this.userDetails = new User();

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.route.params.forEach(params => {
      this.invoiceId = params["invoiceId"];
      //call your function, like getUserInfo()
      this.getInvoice(this.invoiceId);
    });

    this.invoiceSettings = new InvoiceSettings();
    this.getInvoiceSettings(this.officeId)
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getUser(userId: number) {

    this.officesUsersRolesService.getUsers(userId, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.userDetails = resp.userDetails.users[0];
      }

    })

  }

  getInvoice(invoiceId: number) {

    this.invoicingService.getInvoices(invoiceId, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.selectedInvoice = resp.invoices.invoices[0];
        this.lineItems = resp.invoices.invoices[0].lineItems;
        this.clientId = resp.invoices.invoices[0].clientId;
        this.getClient(this.clientId);
        this.getOffice(this.officeId);
      }

    })

  }

  getOffice(officeId: number) {

    this.officesUsersRolesService.getOffices(officeId, 0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.officeDetails = resp.officeDetails.offices[0];
      }

    })

  }

  getClient(clientId: number) {

    this.clientsContactsService.getClients(clientId, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.clientDetails = resp.clientDetails.clients[0];
      }

    })

  }

  getInvoiceSettings(officeId: number) {

    this.invoicingService.getInvoiceSettings(0, officeId, 0, 1).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.invoiceSettings = resp.invoiceSettings[0];
       // this.getUser(this.invoiceSettings.ownerId);
      }
    })

  }
}
