import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { MessagesService } from '../../services/messages.service';

import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';


@Component({
  selector: 'app-view-contacts-dialog',
  templateUrl: './view-contacts-dialog.component.html',
  styleUrls: ['./view-contacts-dialog.component.css']
})
export class ViewContactsDialogComponent implements OnInit {
  contactData: Contacts[] =[];

  constructor(public dialogRef: MatDialogRef<ViewContactsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.contactData = this.data.contactsData;
  }


  closeDialog(contact){

    let temp = {

      value: contact

    }

    this.dialogRef.close(temp);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
}
