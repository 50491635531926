import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';



import { UserSessionService } from '../../services/user-session.service';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';
import { ProductTypesData, ProductTypes, ProductType } from '../../models/productTypesData';

import { ViewInvoiceProductDialogComponent } from '../../components/view-invoice-product-dialog/view-invoice-product-dialog.component';


@Component({
  selector: 'app-invoicing-products',
  templateUrl: './invoicing-products.component.html',
  styleUrls: ['./invoicing-products.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [UserSessionService, InvoicingService]
})
export class InvoicingProductsComponent implements OnInit {


  listOfProducts: Products[] = [];
  listOfProductTypes: ProductType[] = [];
  noOfProducts: number = 0;
  productTypeId: number = 0;
  pageNo: number = 0;

  isActiveFilter: number = 1;

  productStates = [
    { value: 0, viewValue: 'Any' }
  ];
  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];

  constructor(public userSessionService: UserSessionService, public invoicingService: InvoicingService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {

    this.getInvoicingProducts(this.productTypeId, this.isActiveFilter, 1);
    this.getProductTypes();
  }


  public getInvoicingProducts(productTypeId: number, isActive: number, pageNo: number) {

    this.invoicingService.getInvoiceProduct(0, 0, 0, productTypeId, 0, 0, isActive, 0, 1, 'ASC', pageNo).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {

        this.listOfProducts = resp.invoiceProducts.products;
        this.noOfProducts = resp.invoiceProducts.totalCount;
      }
    })

  }
  setPage(event) {
    console.log(event);
    this.pageNo = event.pageIndex;

    this.getInvoicingProducts(this.productTypeId, this.isActiveFilter, event.pageIndex + 1);
  }
  getProductTypes() {

    this.invoicingService.getProductTypes(0, 0, 1).subscribe(resp => {

      this.listOfProductTypes = resp.productTypes;

      this.listOfProductTypes.forEach((type, i) => {

        this.productStates.push({ value: i + 1, viewValue: type.name });

      });

    })

  }


  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  reset() {

    this.productTypeId = 0;
    this.isActiveFilter = 1;
    this.getInvoicingProducts(this.productTypeId, this.isActiveFilter, 1);

  }



  viewInvoiceProductDialog(product): void {

    let dialogRef = this.dialog.open(ViewInvoiceProductDialogComponent, {
      width: '1000px',
      data: { product: product }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoicingProducts(this.productTypeId, this.isActiveFilter, 1);
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }
}
