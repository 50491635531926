import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { InvoicingService } from '../../services/invoicing.service';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';
import { ProductTypesData, ProductTypes, ProductType } from '../../models/productTypesData';


@Component({
  selector: 'app-add-line-item-dialog',
  templateUrl: './add-line-item-dialog.component.html',
  styleUrls: ['./add-line-item-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService, ClientsContactsService]
})
export class AddLineItemDialogComponent implements OnInit {
  newLineItem: AddLineItem;
  rForm: FormGroup;
  productTypes: ProductType[] = [];
  listOfProducts: Product[] = [];
  officeId: number = 1;
  listOfTaxCodes: TaxCode[] = [];
  productTypeId: number;
  loading: boolean = false;
  isVATExempt: boolean = false;
  clientDetails: Client;

  constructor(public clientsContactsService: ClientsContactsService, public dialogRef: MatDialogRef<AddLineItemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {


    this.rForm = fb.group({
      'quantity': [1, Validators.required],
      'productTypeId': [null, Validators.required],
      'productId': [null, Validators.required],
      'description': [null, Validators.required],
      'unitPrice': [null, Validators.required],
      'isActive': true,
      'taxId': [null, Validators.required],
    });
  }

  ngOnInit() {
    this.clientDetails = new Client();
    this.newLineItem = new AddLineItem();

    this.newLineItem.isActive = true;
    this.newLineItem.quantity = 1;
    this.newLineItem.invoiceId = this.data.invoice.invoiceId;
    this.officeId = this.data.invoice.officeId;
    this.getProductTypes();
    this.getTaxCode();

    this.clientDetails = this.data.client;
    this.isVATExempt = this.clientDetails.isVatExempt;

    if(this.isVATExempt){
      this.newLineItem.taxId = 2;
    }else{
      this.newLineItem.taxId = 1;
    }
  }

  getTaxCode() {

    this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {

      this.listOfTaxCodes = resp.taxCodes;
    })
  }

  getProductTypes() {

    this.invoicingService.getProductTypes(0, 0, 1).subscribe(resp => {

      this.productTypes = resp.productTypes;

    })
  }

  getProducts(productTypeId: any) {
    console.log(productTypeId);

    if (productTypeId > 0) {
      this.invoicingService.getInvoiceProduct(0, 0, 0, productTypeId, this.officeId, 0, 1, 1,  1, 'ASC', 0).subscribe(resp => {
        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
        } else {
            this.listOfProducts = resp.invoiceProducts.products;
        } 
      })
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  updateValues(product: Product){
console.log(product);
    this.newLineItem.unitPrice = product.unitCost;
    this.newLineItem.description = product.description;
    //this.newLineItem.taxId = product.taxId;

  }

  addLineItem(post) {
this.loading = true;
    this.invoicingService.addLineItem(this.newLineItem).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {

        this.openSnackBar("Line Item added");
        this.loading = false;
        this.dialogRef.close();
      }

    })

  }
}
