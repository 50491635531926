import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, InvoiceSettings, InvoiceSetting } from '../../models/invoiceData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';

import { ClientMessagesComponent } from '../../components/client-messages/client-messages.component';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';


import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { ClientData, ClientDetails, Clients, Client, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { BillingData, Billings } from '../../models/billingData';

@Component({
  selector: 'app-add-client-dialog',
  templateUrl: './add-client-dialog.component.html',
  styleUrls: ['./add-client-dialog.component.css'],
  providers: [ClientsContactsService, InvoicingService]
})
export class AddClientDialogComponent implements OnInit {
  rForm: FormGroup;
  selectedOffices: string;
  newClient: Client;
  listOfSourceTypes: SourceTypes[];
  listOfOffices: OfficeData;
  listOfOffices2: Offices[];
  filteredContacts: ContactAutocomplete[] = [];
  officeSelected: Office;
  paymentTypes: PaymentTypes[];
  taxCodes: TaxCodes[];
  listOfTariffs: Products[] = [];
  filteredClients: ClientAutocomplete[] = [];
  officeId: number;
  listOfBillingTypes: Billings[];
  listOfQuarterMonths: any[];
  listOfMonths: any[];

  get offices(): FormArray {
    return <FormArray>this.rForm.get('offices');
  }

  constructor(public dialogRef: MatDialogRef<AddClientDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private router: Router, private route: ActivatedRoute, public invoicingService: InvoicingService, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar) {
    this.rForm = fb.group({

      'clientName': [null, Validators.required],
      'clientAcronym': [{ value: '', disabled: true }, Validators.required],
      'billingDate': [null, Validators.required],
      'billingFrequencyId': [null],
      'clientCode': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      'addressLine1': [null, Validators.required],
      'addressLine2': [null],
      'addressLine3': [null],
      'townCity': [false, Validators.required],
      'postCode': [false],
      'country': [false],
      'county': [false],
      'isTrial': [false],
      'trialClosedDate': [null],
      'serviceStartDate': [null, Validators.required],
      'isActive': [true],
      'clientId': [0, Validators.required],
      'closeDate': [null],
      'sourceTypeId': [null],
      'internalDDI': [false],
      'phoneNo': [false],
      'faxNo': [false],
      'emergencyNo': [null],
      'privateUrl': [null],
      'webUrl': [null],
      'formUrl': [null],
      'answerScript': [null],
      'instruction': [null],
      'hangUpScript': [null],
      'notes': [''],
      'mainContactId': [null],
      'clientAutocomplete': new FormControl(),
      'paymentTypeId': [null],
      'taxId': [null],
      'isOutOfHours': [false],
      'masterAccountId': [null],
      'tariffId': [null],
      'isVatExempt': [false],
      'isInvoiceScheduled': [true],
      'invoiceToEmails': [null],
      'financeContact': [null],
      offices: this.fb.array([]),
      'monthInQuarter': [{ value: 1}],
      'monthNumber': [{ value: 1}],
    });

    this.rForm.controls['clientAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientAutocomplete'].value);
        if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }

      });
  }


  addOffice(selected, id, disabled): void {

    this.offices.push(this.buildOffices(selected, id, disabled));
  }

  buildOffices(selected, id, disabled): FormGroup {
    return this.fb.group({
      officeId: id,
      locked: disabled,
      isSelected: new FormControl({ value: selected, disabled: disabled })
    })
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  setClient(client) {

    const ctrl = this.rForm.get('billingDate');
    if (client.clientId > 0) {
      this.newClient.masterAccountId = client.clientId;

      this.clientsContactsService.getClients(client.clientId, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
        } else {
          this.rForm.get("billingDate").setValue(moment(resp.clientDetails.clients[0].billingDate).toDate());
        }

      })
    } else {
      //ctrl.enable();
      this.rForm.get("billingDate").setValue(ctrl.value);
    }
  }
  ngOnInit() {

    this.listOfQuarterMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3}
    ]
    this.listOfMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
      {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6},
      {id: 7, value: 7}, {id: 8, value: 8}, {id: 9, value: 9},
      {id: 10, value: 10}, {id: 11, value: 11}, {id: 12, value: 12}
      
    ]
    
    this.officeId = this.data.officeSelected.officeId;
    this.newClient = new Client();

    this.listOfSourceTypes = this.data.sourceData;

    this.listOfOffices = this.data.officeData;

    this.listOfOffices2 = this.data.officeData.officeDetails.offices;

    this.officeSelected = this.data.officeSelected;

    this.paymentTypes = this.data.paymentTypes;
    this.taxCodes = this.data.taxCode;
    this.getBillingFrequency();
    this.getTariffs(this.officeSelected.officeId);

    this.listOfOffices.officeDetails.offices.forEach((office) => {
      if (office.officeId === this.officeSelected.officeId) {
        this.addOffice(true, office.officeId, true);
      } else {
        this.addOffice(false, office.officeId, false);
      }

    });
  }

  setDates(billingId){
    console.log(billingId);
        if(billingId === 3){
          this.listOfMonths = [
            {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
            {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6}
            
          ]
        }else{
          this.listOfMonths = [
            {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
            {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6},
            {id: 7, value: 7}, {id: 8, value: 8}, {id: 9, value: 9},
            {id: 10, value: 10}, {id: 11, value: 11}, {id: 12, value: 12}
            
          ]
        }
      }

  getBillingFrequency() {
    this.clientsContactsService.getBillingFrequency().subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfBillingTypes = resp.deliveryTypes;
      }
    });
  }
  private getTariffs(officeId: number) {

    this.invoicingService.getInvoiceProduct(0, 0, 0, 1, 0, 0, 1, 0, 1, 'ASC', 1).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfTariffs = resp.invoiceProducts.products;
      }
    })
  }

  billingFilter = (d: Date): boolean => {
    console.log(d);
    d = moment(d).toDate();
    const day = d.getDate();
    // Prevent Saturday and Sunday from being selected.
    return day !== 29 && day !== 30 && day !== 31;
  }

  updateClientAcronym($event) {

    console.log($event);

  }

  updateClientAcronymDate(event) {

    let tempClientRef = this.newClient.clientAcronym;

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addClient(post) {
    this.selectedOffices = '';
    post.offices.forEach((office, i) => {
      if (!office.hasOwnProperty("isSelected")) {
        this.selectedOffices += office.officeId + ",";
      } else {
        if (office.isSelected) {
          this.selectedOffices += office.officeId + ",";
        }
      }
    });
    this.selectedOffices = this.selectedOffices.slice(0, -1);

    if (moment(post.closeDate).isValid()) {
      this.newClient.closeDate = post.closeDate;
    }
    if (moment(post.trialClosedDate).isValid()) {
      this.newClient.trialClosedDate = post.trialClosedDate;
    }
    if (moment(post.serviceStartDate).isValid()) {
      this.newClient.serviceStartDate = post.serviceStartDate;
    }
    if (moment(post.billingDate).isValid()) {
      this.newClient.billingDate = post.billingDate;
    }


    this.newClient.clientCode = this.newClient.clientCode.toUpperCase();

    this.newClient.clientAcronym = this.newClient.clientCode + "-" + this.officeSelected.referenceNo + "-" + moment(this.newClient.billingDate).format("DDMM");

    this.clientsContactsService.addClient(this.selectedOffices, this.newClient).subscribe(resp => {

      let clientDetailsTemp= resp;

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
       this.router.navigate(['/clients/' + clientDetailsTemp.clientId + '/tab/0']);
        this.openSnackBar("Client Added");
        this.dialogRef.close();

      }
    });
  }
}
