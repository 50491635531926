import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';

@Component({
  selector: 'app-end-of-day-dialog',
  templateUrl: './end-of-day-dialog.component.html',
  styleUrls: ['./end-of-day-dialog.component.css'],
  providers: [OfficesUsersRolesService]
})
export class EndOfDayDialogComponent implements OnInit {

  officeId: number;
  loading: boolean = false;

  constructor(public snackBar: MatSnackBar, public officesUsersRolesService: OfficesUsersRolesService, public dialogRef: MatDialogRef<EndOfDayDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

    this.officeId = this.data.officeId;


  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  sendEndOfDay(){
    this.loading = true;
    this.officesUsersRolesService.endOfDelivery(this.officeId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.loading = false;
        this.openSnackBar("End of day sent");
        this.dialogRef.close();
      }
    });

    
  }
}
