import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData } from '../../models/invoiceData';


@Component({
  selector: 'app-change-invoice-reference-dialog',
  templateUrl: './change-invoice-reference-dialog.component.html',
  styleUrls: ['./change-invoice-reference-dialog.component.css'],
  providers: [InvoicingService]
})
export class ChangeInvoiceReferenceDialogComponent implements OnInit {
  invoiceData: Invoice;
  rForm: FormGroup;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ChangeInvoiceReferenceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'reference': ['', Validators.required]
    });
  }

  ngOnInit() {

    this.invoiceData = this.data.invoice;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  changeReference(post) {

    this.invoicingService.updateInvoice(this.invoiceData.invoiceId, this.invoiceData).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Invoice Reference Updated");

        this.dialogRef.close();
      }
    })

  }
}
