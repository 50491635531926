import { NgModule }              from '@angular/core';
import { RouterModule, Routes }  from '@angular/router';

import { AuthGuard } from './auth.guard.service';
 
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ClientsComponent } from './components/clients/clients.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { InvoicingComponent } from './components/invoicing/invoicing.component';
import { OfficesComponent } from './components/offices/offices.component';
import { UsersComponent } from './components/users/users.component';
import { OfficeDetailComponent } from './components/office-detail/office-detail.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { ClientDetailComponent } from './components/client-detail/client-detail.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { OutboxComponent } from './components/outbox/outbox.component';
import { ContactsDetailComponent } from './components/contacts-detail/contacts-detail.component';
import { AdminComponent } from './components/admin/admin.component';
import { MessageStatsComponent } from './components/message-stats/message-stats.component';
import { JournalsComponent } from './components/journals/journals.component';
import { ResetComponent } from './components/reset/reset.component';
import { PrintMovementComponent } from './components/print-movement/print-movement.component'; 
import { InvoicingDetailComponent } from './components/invoicing-detail/invoicing-detail.component'; 
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component'; 

const appRoutes: Routes = [
  { 
    path: '',  
    data: {
      reuse: true
    },
    children: [
      { path: 'messages', component: MessagesComponent, canActivate: [AuthGuard] },
      { path: 'messages/outbox', component: OutboxComponent, canActivate: [AuthGuard] },
      { path: 'messages/stats', component: MessageStatsComponent, canActivate: [AuthGuard] },
      { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },
      { path: 'clients/:clientId/tab/:tabId', component: ClientDetailComponent, canActivate: [AuthGuard] },
      { path: 'clients/contacts/:id', component: ContactsDetailComponent, canActivate: [AuthGuard] },
      { path: 'invoicing', component: InvoicingComponent, canActivate: [AuthGuard] },
      { path: 'invoicing/invoice/:id', component: InvoicingDetailComponent, canActivate: [AuthGuard] },
      { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard] },
      { path: 'offices', component: OfficesComponent, canActivate: [AuthGuard] },
      { path: 'offices/:officeId/tab/:tabId', component: OfficeDetailComponent, canActivate: [AuthGuard] },
      { path: 'offices/users/:userId', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'reporting', component: ReportingComponent, canActivate: [AuthGuard] },
      { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
      { path: 'journals', component: JournalsComponent, canActivate: [AuthGuard] },
      
     ],
    component: HomeComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
  },
  { path: 'print-invoice/:invoiceId', component: PrintInvoiceComponent, canActivate: [AuthGuard] },
  { path: 'print-movements', component: PrintMovementComponent, canActivate: [AuthGuard] },
  //{ path: '', redirectTo: 'home/messages', pathMatch: 'full'  },
  { path: 'login', component: LoginComponent,  
  data: { shouldDetach: true}  },
  { path: 'reset', component: ResetComponent },
  { path: '**', component: PageNotFoundComponent }
  
];
 
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {onSameUrlNavigation: 'reload'})
    
  ],
  exports: [
    RouterModule
  ],
  providers: [AuthGuard]
})
export class AppRoutingModule {}