import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';


import { OfficeData, OfficeDetails, Offices, Office } from '../models/officeData';
import { UserData, UserDetails, Users, User } from '../models/userData';
import { RoleData, Roles, Role } from '../models/roleData';
import { UserRolesData, UserRoles } from '../models/userRolesData';
import { NoticeData, NoticeDetails } from '../models/noticeData';

@Injectable()
export class OfficesUsersRolesService {

  constructor(public http: HttpClient) { }


  getOffices(officeId: number, name:any, isActive:number, pageNo:number): Observable<OfficeData> {
    return this.http.get<OfficeData>(AppSettings.API_ENDPOINT + "/office/" + officeId + "/" + name + "/" + isActive + "/" + pageNo);
  }
  updateOffice(officeId: number, updateData: any): Observable<OfficeData> {
    return this.http.post<OfficeData>(AppSettings.API_ENDPOINT + "/office/update/" + officeId, updateData);
  }
  addOffice(addData: any): Observable<OfficeData> {
    return this.http.post<OfficeData>(AppSettings.API_ENDPOINT + "/office/add", addData);
  }
  getUsers(userId: number, userName:any, officeId:number, isActive:number, sortId: number, sortDir: string,  pageNo:number): Observable<UserData> {
    return this.http.get<UserData>(AppSettings.API_ENDPOINT + "/user/" + userId + "/" + userName + "/" + officeId + "/" + isActive + "/" + sortId + '/' + sortDir + '/' + pageNo);
  }
  addUser(roleIds: string, addData: any): Observable<UserData> {
    return this.http.post<UserData>(AppSettings.API_ENDPOINT + "/user/add/" + roleIds, addData);
  }
  getRoles(roleId: number, name: any, isActive:number): Observable<RoleData> {
    return this.http.get<RoleData>(AppSettings.API_ENDPOINT + "/role/" + roleId + '/' + name + '/' + isActive);
  }
  updateUser(userId: number, roleIds: string, updateData: any): Observable<UserData> {
    return this.http.post<UserData>(AppSettings.API_ENDPOINT + "/user/update/" + userId + '/' + roleIds, updateData);
  }
  getUserRoles(userId: number): Observable<UserRolesData> {
    return this.http.get<UserRolesData>(AppSettings.API_ENDPOINT + "/userRoles/" + userId);
  }
  passwordReset(useremailaddress: number): Observable<UserRolesData> {
    return this.http.get<UserRolesData>(AppSettings.API_ENDPOINT + "/password/forgottenPassword/" + useremailaddress);
  }
  getNotices(noticeId: number, officeId: number, isActive: number): Observable<NoticeData> {
    return this.http.get<NoticeData>(AppSettings.API_ENDPOINT + "/notice/" + noticeId + '/' + officeId + '/' + isActive);
  }
  addNotices(noticeData: any): Observable<NoticeData> {
    return this.http.post<NoticeData>(AppSettings.API_ENDPOINT + "/notice/add", noticeData);
  }
  updateNotices(noticeData: any, noticeId: number): Observable<NoticeData> {
    return this.http.post<NoticeData>(AppSettings.API_ENDPOINT + "/notice/update/" + noticeId, noticeData);
  }  
  endOfDelivery(officeId: number): Observable<NoticeData> {
    return this.http.get<NoticeData>(AppSettings.API_ENDPOINT + "/endOfDelivery/" + officeId);
  }

}
