export class UserData{
    errors: any[] | null;
    userDetails: UserDetails | null;
}
export class UserDetails{
    totalCount: number;
    users:  Users[];
}
export class Users{
    createdBy: string;
    createdById: number;
    createdDate: string;
    emailAddress: string;
    firstName: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    officeId: number;
    officeName: string;
    surname: string;
    totalCount: number;
    userId: number;
    userName: string;
    isEODSummary: boolean = false;
}
export class User{
    createdBy: string;
    createdById: number;
    createdDate: string;
    emailAddress: string;
    firstName: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    officeId: number;
    officeName: string;
    surname: string;
    totalCount: number;
    userId: number;
    userName: string;
    isEODSummary: boolean = false;
}

export class UserUpdate{
    firstName: string;
    surname: string;
    emailAddress: string;
    password: string; 
    isEODSummary: boolean = false;
}
export class UserAccount{
    createdBy: string;
    createdById: number;
    createdDate: string;
    emailAddress: string;
    firstName: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    officeId: number;
    officeName: string;
    surname: string;
    totalCount: number;
    userId: number;
    userName: string;
    password: string | null = '';
    confirmPassword: string | null = '';
    changePassword: boolean = false;
    isEODSummary: boolean = false;
}
