import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { MovementsService } from '../../services/movements.service';
import { MovementDetails, MovementData, Movements, Movement } from '../../models/movementData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';

@Component({
  selector: 'app-view-movement-dialog',
  templateUrl: './view-movement-dialog.component.html',
  styleUrls: ['./view-movement-dialog.component.css'],
  providers: [MovementsService, ClientsContactsService]
})
export class ViewMovementDialogComponent implements OnInit {
  rForm: FormGroup;
  newMovement: Movement;
  myControlContacts: FormControl = new FormControl();
  myControlContacts2: FormControl = new FormControl();
  filteredContacts: ContactAutocomplete[] = [];
  filteredContacts2: ContactAutocomplete[] = [];
  selectedClientId: number;
  movementData: Movement;

  constructor(public dialogRef: MatDialogRef<ViewMovementDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar, public movementsService: MovementsService, public clientsContactsService: ClientsContactsService) {
   

    this.rForm = fb.group({

      'clientId': [null],
      'notes': [null],
      'startDate': [moment().toDate(), Validators.required],
      'endDate': [moment().toDate(), Validators.required],
      'outOfOfficeId': [null],
      'forwardToId': [null],
      'isActive':[null]
    });

    this.myControlContacts.valueChanges
      .subscribe(name => {
        console.log(this.myControlContacts.value);
        if (this.myControlContacts.value === '' || typeof this.myControlContacts.value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts.value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts = res.contacts
            })
        }

      });



    this.myControlContacts2.valueChanges
      .subscribe(name => {
        console.log(this.myControlContacts2.value);
        if (this.myControlContacts2.value === '' || typeof this.myControlContacts2.value != 'string') {
          return this.filteredContacts2 = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts2.value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts2 = res.contacts
            })
        }

      });
  }

  ngOnInit() {

    this.movementData = this.data.movementData;
    this.myControlContacts.setValue({contactId: this.movementData.outOfOfficeId, contactName: this.movementData.outOfOffice });
    this.myControlContacts2.setValue({contactId: this.movementData.forwardToId, contactName: this.movementData.forwardTo });
    this.selectedClientId = this.data.movementData.clientId;
    this.rForm.controls['startDate'].setValue(moment(this.data.movementData.startDate).toDate());
    this.rForm.controls['endDate'].setValue(moment(this.data.movementData.endDate).toDate());

  }
  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }

  setOutofOffice(contact) {
    if (contact.contactId > 0) {
      this.movementData.outOfOfficeId = contact.contactId;
    }


  }
  setForwardTo(contact) {

    if (contact.contactId > 0) {
      this.movementData.forwardToId = contact.contactId;
    }

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  updateMovement(post) {
    if (moment(post.startDate).isValid()) {
      this.movementData.startDate = post.startDate;
    }
    if (moment(post.endDate).isValid()) {
      this.movementData.endDate = post.endDate;
    }
if( this.myControlContacts.value.contactId >0){

  this.movementData.outOfOfficeId = this.myControlContacts.value.contactId;
}
if( this.myControlContacts2.value.contactId >0){
  
    this.movementData.forwardToId = this.myControlContacts2.value.contactId;
  }

    this.movementsService.updateMovements(this.movementData.movementId, this.movementData).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Movement Updated");
        this.dialogRef.close();

      }
    });


  }
}
