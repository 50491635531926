import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable ,  BehaviorSubject } from 'rxjs';





import { AppSettings } from '../app-settings';

import { JournalData, JournalMessages, JournalMessage } from '../models/journalData';

@Injectable()
export class JournalsService {

  private noOfJournals = new BehaviorSubject<number>(0);

  currentNoOfJournals = this.noOfJournals.asObservable();

  updateJournal(data: JournalData){
    console.log(data);
    this.noOfJournals.next(data.messageId.totalCount);
    //return this.noOfJournals.asObservable();
  }

  getNoJournals(): Observable<number> {
      return this.noOfJournals.asObservable();
  }

  constructor(public http: HttpClient) { }

  getJournals(userId: number, deliveryType: number, phone: number, status: number, journal: number, pageNo: number): Observable<JournalData> {
    if(userId === 0){
      return this.http.get<JournalData>(AppSettings.API_ENDPOINT + "/journal/" + userId + '/' + deliveryType + '/' + phone + '/'  + status + '/'  + journal + '/' + pageNo);
    }
    else{
      return this.http.get<JournalData>(AppSettings.API_ENDPOINT + "/journal/" + userId + '/' + deliveryType + '/' + phone + '/'  + status + '/'  + journal + '/' + pageNo).do(data => this.updateJournal(data));
    }
  }
  addJournals(messageIds: number): Observable<JournalData> {
    return this.http.get<JournalData>(AppSettings.API_ENDPOINT + "/journal/add/" + messageIds);
  }
}
