import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';


import { LoginData, ResetData, ChangeData } from '../models/loginData';

import { PasswordData } from '../models/passwordData';

@Injectable()
export class LoginService {
  public token: string;
  constructor(public http: HttpClient) { }


  login(loginData:any): Observable<LoginData> {
    return this.http.post<LoginData>(AppSettings.API_ENDPOINT + "/me/login", loginData);
  }
  logout(): Observable<LoginData> {
    return this.http.get<LoginData>(AppSettings.API_ENDPOINT + "/logout");
  }
  forgottenPassword(useremailaddress: string): Observable<ResetData> {
    return this.http.get<ResetData>(AppSettings.API_ENDPOINT + "/password/forgottenPassword/" + useremailaddress);
  }
  changePasswordWithEncryptedUserId(encryptedUserId: any, passwordData: any): Observable<PasswordData> {
    return this.http.post<PasswordData>(AppSettings.API_ENDPOINT + "/user/changePasswordWithEncryptedUserId/" + encryptedUserId, passwordData);
  }

}
