import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';
import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { UserSessionService } from '../../services/user-session.service';

import { UserData, Users, User } from '../../models/userData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, InvoiceSettings } from '../../models/invoiceData';

@Component({
  selector: 'app-invoice-settings',
  templateUrl: './invoice-settings.component.html',
  styleUrls: ['./invoice-settings.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService, ClientsContactsService, OfficesUsersRolesService, UserSessionService]
})
export class InvoiceSettingsComponent implements OnInit {
  invoiceSettings: InvoiceSettings;
  rForm: FormGroup;
  paymentTypes: PaymentTypes[];
  myControlOwners: FormControl = new FormControl();
  filteredOwners: User[] = [];
  officeId: number = 0;
  newInvoiceSettings: InvoiceSettings;
  office: Office;

  constructor(private fb: FormBuilder, public invoicingService: InvoicingService, public userSessionService: UserSessionService, public officesUsersRolesService: OfficesUsersRolesService, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar) {
    this.rForm = fb.group({

      'creditNotePrefix': [null, Validators.required],
      'invoiceFooter': [null, Validators.required],
      'invoiceMessage': [null, Validators.required],
      'invoiceEmailTemplate': [null, Validators.required],
      'invoicePrefix': [null, Validators.required],
      'invoiceTerms': [null],
      'isActive': [null],
      'name': [null, Validators.required],
      'officeId': [null],
      'ownerId': [null],
      'paymentTypeId': [null, Validators.required],
      'vatRegNumber': [null, Validators.required],
      'fromAddress': [null, Validators.required],
      'replyAddress': [null, Validators.required],
      'server': [null, Validators.required],
      'userName': [null, Validators.required],
      'port': [null, Validators.required],
      'password': [null, Validators.required],
    });


    this.myControlOwners.valueChanges
      .subscribe(name => {
        console.log(this.myControlOwners.value);
        if (this.myControlOwners.value === '' || typeof this.myControlOwners.value != 'string') {
          return this.filteredOwners = null;
        } else {
          this.officesUsersRolesService.getUsers(0, this.myControlOwners.value, this.officeId, 1, 1, 'ASC', 1)
            .subscribe(res => {

              if (res.errors.length > 0) {
                this.openSnackBar(res.errors[0].error);
                this.userSessionService.checkToken().subscribe();
              } else {
      
      
                return this.filteredOwners = res.userDetails.users
              }
              
            })
        }

      });

  }

  ngOnInit() {

    this.invoiceSettings = new InvoiceSettings();
    this.getPaymentTypes();

    this.getInvoiceSettings(this.officeId);

  }


  getInvoiceSettings(officeId: number) {

    this.invoicingService.getInvoiceSettings(0, officeId, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {


        this.invoiceSettings = resp.invoiceSettings[0];
        console.log(this.invoiceSettings);
        if(!this.invoiceSettings){

        }else{
          if (this.invoiceSettings.ownerId === null) {
 
          }else{
            this.getOwner(this.invoiceSettings.ownerId);
          }
        }

      }


    })
  }

  displayFn(user: User): string {
    //this.selectedOption = user;

    return user ? user.userName : '';

  }

  generateSettings() {
    this.newInvoiceSettings = new InvoiceSettings();
    this.office = new Office();

    this.officesUsersRolesService.getOffices(this.officeId, 0, 0, 1).subscribe(resp => {

      this.office = resp.officeDetails.offices[0];


      this.newInvoiceSettings.officeId = this.officeId;
      this.newInvoiceSettings.name = "Default Settings Template for ";
      //this.newInvoiceSettings.ownerId = null;
      this.newInvoiceSettings.vatRegNumber = "DEMO " + this.office.name;
      this.newInvoiceSettings.invoicePrefix = "DEMO " + this.office.name;
      this.newInvoiceSettings.invoiceTerms = "DEMO " + this.office.name;
      this.newInvoiceSettings.invoiceMessage = "DEMO " + this.office.name;
      this.newInvoiceSettings.invoiceFooter = "DEMO " + this.office.name;
      this.newInvoiceSettings.creditNotePrefix = "DEMO " + this.office.name;
      this.newInvoiceSettings.paymentTypeId = 1;
      this.newInvoiceSettings.server = this.office.serverName;
      this.newInvoiceSettings.port = String(this.office.port);
      this.newInvoiceSettings.password = String(this.office.password);
      this.newInvoiceSettings.userName = this.office.userName;
      this.newInvoiceSettings.fromAddress = "demo@demo.com";
      this.newInvoiceSettings.replyAddress = "demo@demo.com";
      this.newInvoiceSettings.password = this.office.smsAccountPassword;


      this.invoicingService.addInvoiceSettings(this.newInvoiceSettings).subscribe(resp => {

        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
          this.userSessionService.checkToken().subscribe();
        } else {


          this.openSnackBar("Settings generated");
          this.ngOnInit();
        }


      })

    })
  }

  getOwner(userId: number) {

    this.officesUsersRolesService.getUsers(userId, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {

        this.myControlOwners.setValue({ ownerId: resp.userDetails.users[0].userId, userName: resp.userDetails.users[0].userName })
      }
    })

  }

  setOwner(event) {
console.log(event);
    this.invoiceSettings.ownerId = event.userId;

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getPaymentTypes() {

    this.clientsContactsService.getPaymentType(0, 0, 0).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.paymentTypes = resp.paymentTypes;

      }

    })

  }

  updateInvoiceSettings(post) {

    this.invoicingService.updateInvoiceSettings(this.invoiceSettings.invoiceSettingId, this.invoiceSettings).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Settings updated");

      }
    })
  }
}
