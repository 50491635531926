import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData } from '../../models/invoiceData';


@Component({
  selector: 'app-change-invoice-date-dialog',
  templateUrl: './change-invoice-date-dialog.component.html',
  styleUrls: ['./change-invoice-date-dialog.component.css'],
  providers: [InvoicingService]
})
export class ChangeInvoiceDateDialogComponent implements OnInit {
  rForm: FormGroup;
  invoiceData: Invoice;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ChangeInvoiceDateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'startPeriod': ['', Validators.required],
      'endPeriod': ['', Validators.required]
    });
   }

  ngOnInit() {
    this.invoiceData = this.data.invoice;

    this.rForm.controls['startPeriod'].setValue(moment(this.invoiceData.startPeriod).toDate());
    this.rForm.controls['endPeriod'].setValue(moment(this.invoiceData.endPeriod).toDate());


  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  changeDate(post){
console.log(post)
if (moment(post.startPeriod).isValid()) {
  this.invoiceData.startPeriod = moment.utc(post.startPeriod).format();
}
if (moment(post.endPeriod).isValid()) {
  this.invoiceData.endPeriod = moment.utc(post.endPeriod).format();
}
    console.log(this.invoiceData);
  
    this.invoicingService.changeInvoiceDate(this.invoiceData.invoiceId, this.invoiceData).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Invoice dates updated");

        this.dialogRef.close();

      }
    })

  }
}
