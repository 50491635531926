import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';

import { MessageSend, NewMessage, MessageData, MessageDetails, Messages } from '../models/messageData';
import { MessageTypeData, MessageType } from '../models/messageTypeData';
import { CallerData, Callers } from '../models/callerData';


@Injectable()
export class MessagesService {

  constructor(public http: HttpClient) { }

  sendMessage(messageData:any): Observable<MessageSend> {
    return this.http.post<MessageSend>(AppSettings.API_ENDPOINT + "/message/send", messageData);
  }
  getMessages(messageData:any): Observable<MessageData> {
    return this.http.post<MessageData>(AppSettings.API_ENDPOINT + "/message", messageData);
  }
  getMessagesTypes(messageTypeId: number, name: string, isActive: number): Observable<MessageTypeData> {
    return this.http.get<MessageTypeData>(AppSettings.API_ENDPOINT + "/messageType/" + messageTypeId + "/" + name + "/" + isActive);
  }

  getCaller(callerName: string, clientId: number): Observable<CallerData> {
    return this.http.get<CallerData>(AppSettings.API_ENDPOINT + "/caller/" + callerName + "/" + clientId);
  }

  getMessagesCSV(messageData: any){
    return this.http.post(AppSettings.API_ENDPOINT + "/csvForMessage", messageData, { responseType: 'blob'});
  }
  resendMessage(messageId: number, messageData:any): Observable<MessageSend> {
    return this.http.post<MessageSend>(AppSettings.API_ENDPOINT + "/message/resend/" + messageId, messageData);
  }
  updateMessage(messageId: number, messageData:any): Observable<MessageSend> {
    return this.http.post<MessageSend>(AppSettings.API_ENDPOINT + "/updateMessage/" + messageId, messageData);
  }

  endOfDeliveryForClient(messageData:any): Observable<MessageSend> {
    return this.http.post<MessageSend>(AppSettings.API_ENDPOINT + "/endOfDeliveryForClient", messageData);
  }

}
