import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';




import { ClientsContactsService } from '../../services/clients-contacts.service';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';

import { InvoicingService } from '../../services/invoicing.service';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';
import { ProductTypesData, ProductTypes, ProductType } from '../../models/productTypesData';
import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-add-invoice-product-dialog',
  templateUrl: './add-invoice-product-dialog.component.html',
  styleUrls: ['./add-invoice-product-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService, ClientsContactsService, UserSessionService]
})
export class AddInvoiceProductDialogComponent implements OnInit {

  listOfTaxCodes: TaxCode[] = [];
  newProduct: Product;
  rForm: FormGroup;
  listOfProductTypes: ProductType[]  = [];
  me: any;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  disabled: boolean = true;

  constructor(public clientsContactsService:ClientsContactsService, public dialogRef: MatDialogRef<AddInvoiceProductDialogComponent>, public userSessionService: UserSessionService, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) { 

    this.rForm = fb.group({
      'productName': [null, Validators.required],
      'productCode': [null, Validators.required],
      'description': [null, Validators.required],
      'unitCost': [null, Validators.required],
      'includedMessages': [null, Validators.required],
      'messageCost': [null, Validators.required],
      'isGlobal': [false],
      'taxId': [null, Validators.required],
      'productTypeId': [null, Validators.required],
      'officeId': [null],
      'isDisplayInvoice': [null],
    });
  }


  checkValue(value: number) {
    const ctrl = this.rForm.get('messageCost');
    const ctrl1 = this.rForm.get('includedMessages');
  console.log("123")
    if (value === 1) {
      ctrl.enable();
      ctrl1.enable();
    } else {
      ctrl.disable();
      ctrl1.disable();
    }
  }

  ngOnInit() {
    this.getMe();
    this.newProduct = new Product();

    this.newProduct.officeId = this.data.office.officeId;

    this.getTaxRates();
    this.getProductTypes();
  }

  getMe() {
    //console.log("header calling");
    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        //this.me = new Me();


        this.me = resp.user[0];
        let arrayOfRoles = new Array();
        this.me.roles.forEach((role) => {
          arrayOfRoles.push(role.roleId);
        });
        const global = this.rForm.get('isGlobal');
        global.disable();
        if (arrayOfRoles.includes(3)) {
          this.navRoleOffice = true;
          global.disable();
        }
        if (arrayOfRoles.includes(2)) {
          this.navRoleInvoicing = true;
          global.disable();
        }
        if (arrayOfRoles.includes(5)) {
          this.navRoleJournal = true;
          global.disable();
        }
        if (arrayOfRoles.includes(6)) {
          this.navRoleHeadOffice = true;
         
          global.enable();

        }
      }
      //this.userSessionService.updateUser(resp);
    });
  }

  getProductTypes(){
    
        this.invoicingService.getProductTypes(0, 0, 0).subscribe(resp => {
    
          this.listOfProductTypes = resp.productTypes;
    
        })
    
      }

  getTaxRates(){
    
        this.clientsContactsService.getTaxCode(0, 0, 0).subscribe(resp => {
    
          this.listOfTaxCodes = resp.taxCodes;
    
        })
    
      }

      openSnackBar(message: string) {
        this.snackBar.open(message, "close", {
          duration: 2500,
        });
      }

      addInvoiceProduct(post){

this.invoicingService.addInvoiceProduct(this.newProduct).subscribe(resp => {

  if (resp.errors.length > 0) {
    this.openSnackBar(resp.errors[0].error);
  } else {

    this.openSnackBar("Invoice product added");
    this.dialogRef.close();

  }

})
        
      }
}
