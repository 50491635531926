import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'hideVal'})
export class HidePipe implements PipeTransform {
  transform(value: any, exponent: string): string {
    let val = '';
    for(let i = 0; i < value.length; i++){

        val += '*';
    }
    
    return val;
  }
}