import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
//import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
//import { EmailValidator } from '@angular/forms';




import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { UserSessionService } from '../../services/user-session.service';

import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { UserData, UserDetails, Users, User } from '../../models/userData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { RoleData, Roles, Role } from '../../models/roleData';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.css'],
  providers: [OfficesUsersRolesService, UserSessionService]
})
export class AddUserDialogComponent implements OnInit {
  rForm: FormGroup;
  selectedRoles: string;
  newUser: User;
  listOfRoles: RoleData;
  listOfRolesFinal: Roles[] = [];
  isHeadOffice: boolean = false;
  me: any;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  officeIsHeadOffice: boolean = false;

  get roles(): FormArray {
    return <FormArray>this.rForm.get('roles');
  }

  constructor(public dialogRef: MatDialogRef<AddUserDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public userSessionService: UserSessionService, public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar) {
    this.rForm = fb.group({

      'firstName': [Validators.required],
      'surname': [Validators.required],
      'emailAddress': [Validators.required, Validators.email],
      'isEODSummary': [false],
      'officeId': [],
      roles: this.fb.array([])
    });
  }



  ngOnInit() {
    
    this.newUser = new User();
    this.rForm.controls['officeId'].setValue(this.data.office.officeId);
    this.newUser.officeId = this.data.office.officeId;
    this.isHeadOffice = this.data.office.isHeadOffice;
    this.listOfRoles = this.data.roleDataPassed;

    this.getMe();

    if(this.data.office.isHeadOffice){
      this.officeIsHeadOffice = true;
    }else{
      this.officeIsHeadOffice = false;
    }
  }

  //do you belong to the head office

  private getMe() {

    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.me = resp.user[0];
        let arrayOfRoles = new Array();
        this.me.roles.forEach((role) => {
          arrayOfRoles.push(role.roleId);
        });
        if (arrayOfRoles.includes(3)) {
          this.navRoleOffice = true;
        }
        if (arrayOfRoles.includes(2)) {
          this.navRoleInvoicing = true;
        }
        if (arrayOfRoles.includes(5)) {
          this.navRoleJournal = true;
        }
        if (arrayOfRoles.includes(6)) {
          this.navRoleHeadOffice = true;
        }

        if (this.listOfRoles) {
          this.listOfRoles.roles.forEach((role) => {
            if (role.roleId === 1) {
              this.addRole(true, role.roleId, true);
            }
            else if (role.roleId === 6 && !this.navRoleInvoicing && !this.officeIsHeadOffice || role.roleId === 6 && this.navRoleInvoicing && !this.officeIsHeadOffice) {
              this.addRole(false, role.roleId, true);
            } else {
              this.addRole(false, role.roleId, false);
            }
          })
    
        }

        this.listOfRolesFinal = this.listOfRoles.roles;
      }
      //this.userSessionService.updateUser(resp);
    });
  }
  addRole(selected, id, disabled): void {

    this.roles.push(this.buildRoles(selected, id, disabled));
  }

  buildRoles(selected, id, disabled): FormGroup {
    return this.fb.group({
      roleId: id,
      isSelected: new FormControl({ value: selected, disabled: disabled })
    })
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  addUser(post) {

    this.selectedRoles = '';
    post.roles.forEach((role, i) => {
      if (!role.hasOwnProperty("isSelected") && role.roleId != 6) {
        this.selectedRoles += role.roleId + ",";
      } else {
        if (role.isSelected) {
          this.selectedRoles += role.roleId + ",";
        }
      }
    });
    this.selectedRoles = this.selectedRoles.slice(0, -1);
console.log(this.selectedRoles);
    this.officesUsersRolesService.addUser(this.selectedRoles, this.newUser).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {

        this.openSnackBar("User Added");
        this.dialogRef.close();
      }
    });
  }
}
