export class ClientData{
    clientDetails: ClientDetails | null;
    errors: any[] | null;   
    clientId?: number;
}
export class ClientDetails{
    totalCount: number;
    clients:  Clients[];
}
export class AddClientData{
    clientId: number;
    errors: any[] | null;   
}
export class Clients{
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    answerScript: string | null;
    billingDate: string | null;
    billingFrequency: string;
    billingFrequencyId: number;
    clientAcronym: string;
    clientId: number;
    clientName: string;
    closeDate: string | null;
    clientCode: string;
    country: string;
    county: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    emergencyNo: string | null;
    faxNo: string | null;
    hangUpScript: string | null;
    instruction: string | null;
    internalDDI: string | null;
    isActive: boolean;
    isMasterAccount: boolean;
    isOutOfHours: boolean;
    isTrial:boolean;
    isInvoiceScheduled:boolean;
    isVatExempt: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: number | null;
    lastUpdatedDate: string | null;
    mainContactId: number | null;
    mainContactName: string | null;
    masterAccountId: number | null;
    masterAccountName: string;
    notes: string;
    offices: ClientOfficeRef[];
    paymentTypeId: number;
    paymentTypeName: string | null;
    phoneNo: string | null;
    postCode: string | null;
    serviceStartDate: string | null;
    privateUrl: string | null;
    webUrl: string;
    formUrl: string;
    sourceTypeId: number | null;
    sourceTypeName: number | null;
    tariffId: number | null;
    tarrifName: number | null;
    taxCode: number | null;
    taxId: number;
    totalCount: number;
    townCity: string;
    trialClosedDate: number | null;
    invoiceToEmails: string;
    financeContact: string;
    monthInQuarter: number;
    monthNumber: number;
}
export class Client{
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    answerScript: string | null;
    billingDate: string | null;
    billingFrequency: string;
    billingFrequencyId: number;
    clientAcronym: string;
    clientId: number;
    clientName: string;
    closeDate: string | null;
    clientCode: string;
    country: string;
    county: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    emergencyNo: string | null;
    faxNo: string | null;
    hangUpScript: string | null;
    instruction: string | null;
    internalDDI: string | null;
    isActive: boolean;
    isMasterAccount: boolean;
    isOutOfHours: boolean;
    isTrial:boolean;
    isInvoiceScheduled:boolean;
    isVatExempt: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: number | null;
    lastUpdatedDate: string | null;
    mainContactId: number | null;
    mainContactName: string | null;
    masterAccountId: number | null;
    masterAccountName: string;
    notes: string;
    offices: ClientOfficeRef[];
    paymentTypeId: number;
    paymentTypeName: string | null;
    phoneNo: string | null;
    postCode: string | null;
    serviceStartDate: string | null;
    privateUrl: string | null;
    webUrl: string;
    formUrl: string;
    sourceTypeId: number | null;
    sourceTypeName: number | null;
    tariffId: number | null;
    tarrifName: number | null;
    taxCode: number | null;
    taxId: number;
    totalCount: number;
    townCity: string;
    trialClosedDate: number | null;
    invoiceToEmails: string;
    financeContact: string;
    monthInQuarter: number;
    monthNumber: number;
}
export class ClientOfficeRef{
    officeId: number;
    officeName: string;
    isPrimaryOwner: boolean;
}
export class ClientsAutocomplete{
    clients: ClientAutocomplete[] | null;
    errors: any[] | null;
}
export class ClientAutocomplete{
    clientId:number;
    clientName: string;

}
export class SubAccountData{
    clients: SubAccount[]| null; 
    errors: any[] | null; 

}
export class SubAccount{
    clientId: number;
    clientName: string;

}

