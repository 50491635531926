import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';




import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { UserData, UserDetails, Users, User } from '../../models/userData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';

@Component({
  selector: 'app-add-office-dialog',
  templateUrl: './add-office-dialog.component.html',
  styleUrls: ['./add-office-dialog.component.css'],
  providers: [OfficesUsersRolesService]
})
export class AddOfficeDialogComponent implements OnInit {
  listOfSourceTypes: SourceTypes[];
  newOffice: Office;
  rForm: FormGroup;
  filteredUsers: Users[];
  selectedOption: User;
  addInfo: any;


  constructor(public dialogRef: MatDialogRef<AddOfficeDialogComponent>, private fb: FormBuilder, public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar, ) {
    this.rForm = fb.group({

      'name': [null, Validators.required],
      'isActive': [true, Validators.required],
      'addressLine1': [null, Validators.required],
      'addressLine2': [null, Validators.required],
      'addressLine3': [null],
      'townCity': [null, Validators.required],
      'postCode': [null, Validators.required],
      'country': [null, Validators.required],
      'ownerId': [null],
      'phoneNo': [null, Validators.required],
      'emailAddress': [null],
      'isOutOfHours': [false, Validators.required],
      'isHeadOffice': [false, Validators.required],
      'referenceNo': [null, Validators.required],
      'serverName': [null],
      'userName': [null],
      'password': [null],
      'isUseSSL': [false],
      'port': [null],
      'smsAccountPassword': [null],
      'smsAccountUserName': [null],
      'smsSubAccountName': [null]
    });

  }
  options = [];
  myControl: FormControl = new FormControl();

  filteredOptions: Observable<User[]>;

  filter(userName: string): User[] {

    this.officesUsersRolesService.getUsers(0, userName, 1, 1, 1, 'ASC', 1).subscribe(resp => {
      this.options = [];
      //this.filteredUsers = resp.userDetails.users;

      resp.userDetails.users.forEach((users) => {

        let user = new User();
        user = users;
        this.options.push(user);
      });

      console.log("here " + userName);
    });

    return this.options.filter(option =>
      option.userName.toLowerCase().indexOf(userName.toLowerCase()) === 0);
  }

  displayFn(user: User): string {
    this.selectedOption = user;
    console.log(this.selectedOption);
    return user ? user.userName : '';

  }

  ngOnInit() {
    this.newOffice = new Office();
    this.filteredOptions = this.myControl.valueChanges
      .startWith(null)
      .map(user => user && typeof user === 'object' ? user.userName : user)
      .map(userName => userName ? this.filter(userName) : this.options.slice());

  }

  public getUsers(userName: string, officeId: number) {
    this.officesUsersRolesService.getUsers(0, userName, officeId, 1, 1, 'ASC', 1).subscribe(resp => {

      //this.filteredUsers = resp.userDetails.users;

      resp.userDetails.users.forEach((users) => {
        console.log(users);
        let user = new User();
        user = users;
        this.filteredUsers.push(user);
      });

      return this.filteredUsers;
      //console.log(this.listOfOffices);
    });

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  addOffice(post) {
    this.officesUsersRolesService.addOffice(this.newOffice).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Office added");
        this.dialogRef.close();
      }
    });

  }
}
