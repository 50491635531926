import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject , Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';


import { UserSessionService } from '../../services/user-session.service';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { ClientData } from '../../models/clientData';
import { ContactData, ContactDetails, Contacts, Contact } from '../../models/contactData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';

@Component({
  selector: 'app-contacts-detail',
  templateUrl: './contacts-detail.component.html',
  styleUrls: ['./contacts-detail.component.css'],
  providers:[ClientsContactsService, UserSessionService]
})
export class ContactsDetailComponent implements OnInit {

  selectedContact: Contact;
  rForm: FormGroup;
  listOfDeliveryTypes: any;
  officeIdSubject: Observable<string>;
  officeId: number;
  primaryOwnerId: number;
  clientData: ClientData;

  constructor(public clientsContactsService: ClientsContactsService, public userSessionService: UserSessionService, private router: Router,  private route: ActivatedRoute, private fb:FormBuilder, public snackBar: MatSnackBar, public dialog: MatDialog) { 

    this.officeIdSubject = userSessionService.getOfficeId();
    
    this.rForm = fb.group({
            'title': [null],
            'firstName': [null, Validators.required],
            'surname': [null],
            'deliveryTypeId': [null, Validators.required],
            'emailAddress1': [''],
            'emailAddress2': [''],
            'faxNo': [null],
            'isActive': [true],
            'isDefaultContact': [false],
            'isEmailAddress1Eod': [false],
            'isEmailAddress1Mod': [false],
            'isEmailAddress1Private': [false],
            'isEmailAddress2Eod': [false],
            'isEmailAddress2Mod': [false],
            'isEmailAddress2Private': [false],
            'isFaxNoPrivate': [false],
            'position': [null],
            'isMobileNoPrivate': [false],
            'isPrinciple': [false],
            'isPhoneNoPrivate': [false],
            'mobileNo': [null],
            'phoneNo': [null],
          });

  }

  ngOnInit() {
    this.clientData = new ClientData();
    this.selectedContact = new Contact();

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    let id = +this.route.snapshot.paramMap.get('id');
    this.selectedContact = new Contact();
        this.getContacts(id);

        this.getDeliveryTypes();
  }

  getClient(){

    this.clientsContactsService.getClients(this.selectedContact.clientId, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', 1 ).subscribe(resp => {


      resp.clientDetails.clients[0].offices.forEach((office) => {
  
        if(office.isPrimaryOwner){
          this.primaryOwnerId = office.officeId;
        }

      });



    })

  }


  private getContacts(contactId: number) {
    this.clientsContactsService.getContacts(contactId, '0', 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {


      resp.contactDetails.contacts.forEach((contact) => {
        //console.log(offices);
     
        this.selectedContact = contact;

       this.getClient();
        
      });
    });
  }

  private getDeliveryTypes() {
    this.clientsContactsService.getDeliveryType(0, 0, 0).subscribe(resp => {


      this.listOfDeliveryTypes = resp.deliveryTypes;
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 25000,
    });
  }

  updateContact(post) { 
    //console.log(post); 

    this.clientsContactsService.updateContact(this.selectedContact.contactId, this.selectedContact).subscribe(resp => {
  
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Contact updated");
        this.ngOnInit();
      }

      
      //console.log(this.updateInfo);

  });

  }
}
