import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



import * as moment from 'moment';

import { MovementsService } from '../../services/movements.service';

import { MovementData, MovementDetails, Movements, Movement } from '../../models/movementData';

@Component({
  selector: 'app-print-movement',
  templateUrl: './print-movement.component.html',
  styleUrls: ['./print-movement.component.css'],
  providers: [MovementsService]
})
export class PrintMovementComponent implements OnInit {
  listOfMovements: Movements[];
  isActiveFilter: number = 1;
  sessionCheck: any;
  active: number = 0;
  from: string = '0';
  to: string = '0';
  client: number = 0;

  constructor(public movementsService:MovementsService, private router: Router, private route: ActivatedRoute,public snackBar: MatSnackBar) { 

    this.sessionCheck = setTimeout(() => {
     
      window.print();

    }, 500 * 10 * 1);
  }

  ngOnInit() {
  
    

    this.route.queryParams.subscribe((params: Params) => {
      this.active = params["active"] || 0;
      this.from = params["from"] || '0';
      this.to = params["to"] || '0';
      this.client = params["client"] || 0;
    });

    this.getClientMovements(this.client, this.active, this.from, this.to);
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {

    });
  }
  getClientMovements(clientId: number, isActiveFilter: number, startDate: string, endDate: string){
    this.movementsService.clientMovements(clientId, isActiveFilter , startDate, endDate).subscribe(resp => {
      //this.listOfMovements= new MovementDetails();
      this.listOfMovements= resp.clients;
       
    });
  }

}
