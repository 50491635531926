import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';




import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice } from '../../models/invoiceData';
import { InvoiceProductsData, InvoiceProducts, Products, Product, InvoiceRecurringProductsData, RecurringProducts } from '../../models/invoicingProductsData';

@Component({
  selector: 'app-client-recurring-products',
  templateUrl: './client-recurring-products.component.html',
  styleUrls: ['./client-recurring-products.component.css'],
  providers: [InvoicingService]
})
export class ClientRecurringProductsComponent implements OnInit {
  clientId:number = 0;
  listOfRecurringProducts: RecurringProducts[] = [];

  constructor(public invoicingService: InvoicingService, public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) { 


  }

  ngOnInit() {
    /*
    this.route.params.forEach(params => {
      this.clientId = params["clientId"];
      //call your function, like getUserInfo()
      this.getRecurringProducts(this.clientId);
    })
    */
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  remove(product: RecurringProducts){

    this.invoicingService.deleteInvoiceRecurringProduct(product.productId, this.clientId).subscribe(resp => {

      if(resp.errors.length > 0){
        this.openSnackBar(resp.errors[0].error);
       
      }else{
        this.openSnackBar("Recurring Product removed");
        this.getRecurringProducts(this.clientId);
      }
    })


  }

  qty(val, id){
console.log("qty" + val)
    if(val > 0){

      this.invoicingService.updateInvoiceRecurringProduct(id, val).subscribe(resp => {

        if(resp.errors.length > 0){
          this.openSnackBar(resp.errors[0].error);
         
        }else{
         
          this.openSnackBar("Quantity updated");
    
        }
      })
    }else{
      this.openSnackBar("Quantity format error");
    }

  }

  getRecurringProducts(clientId: number){

    this.invoicingService.getInvoiceRecurringProduct(0, clientId).subscribe(resp => {

      if(resp.errors.length > 0){
        this.openSnackBar(resp.errors[0].error);
       
      }else{
        this.listOfRecurringProducts = resp.recurringProducts;
  
      }
    })

  }

}
