import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserSessionService } from '../../services/user-session.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { Observable } from 'rxjs';


import { AppSettings } from '../../app-settings';
import { NavigationEnd } from '@angular/router';
import { MeData, Me } from '../../models/meData';
//import { Me } from '../../models/me';
import { Check } from '../../models/check';

import { LoginService } from '../../services/login.service';
import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { UserData, UserDetails, Users, User } from '../../models/userData';

import { JournalData, JournalMessages, JournalMessagesArray, JournalMessage } from '../../models/journalData';

import { JournalsService } from '../../services/journals.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [OfficesUsersRolesService, LoginService]
})
export class HeaderComponent implements OnInit, OnDestroy {
  item: number;
  email: any;
  userName: string;
  sessionCheck: any;
  me: any;
  sub: any;
  id: number;
  post: any;
  test: any;
  updateInfo: any;
  officeTest: any;
  filteredUsers: Users[];
  selectedOption: User;
  message: string;
  officeId: number;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  myJournals: JournalMessagesArray[] = [];
  myJournalsNo: number = 0;
  noOfJournals$: Observable<number>;
  journalTotal: number = 0;
  userName$: Observable<string>;
  env: string;

  constructor(public officesUsersRolesService: OfficesUsersRolesService, public journalsService: JournalsService, public loginService: LoginService, private router: Router, public userSessionService: UserSessionService, public snackBar: MatSnackBar) {
    this.noOfJournals$ = journalsService.getNoJournals();

    
    /*this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseInvites();
      }
    });
*/
    this.sessionCheck = setInterval(() => {
      console.log("Session check");
      this.checkSession();

    this.userSessionService.checkToken().subscribe(resp => {

      if (resp.isValid) {
        
      } else {
        this.openSnackBar(resp.errors);
      }
    });

    }, 3600000);

  }

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    console.log("nav fix");

      let link = ['/office', this.officeId, 'tab', 1];
      this.router.navigate(link);
    
  }

  ngOnInit() {

    if(AppSettings.API_ENDPOINT === 'https://dev.kbperfecta.com/api/Kendcomm'){
      this.env = "DEV";
    }else if(AppSettings.API_ENDPOINT === 'https://uat.kbperfecta.com/api/Kendcomm'){
      this.env = "UAT";
    }else if(AppSettings.API_ENDPOINT === 'https://kbperfecta.com/api/Kendcomm'){
      this.env = "";
    }

    this.getMe();

    if(this.navRoleJournal){
      this.noOfJournals$ = this.journalsService.getNoJournals();
      console.log("JOURNAL" + this.noOfJournals$ );
      this.getJournals(0, 1);
    }
    this.userName$ = this.userSessionService.updateUserName();
    this.checkSession();
    this.me = new MeData();
  }


  gotoOffice(officeId: number){
    let link = ['/offices', officeId, 'tab', 0];
    this.router.navigate(link);
  }

  getJournals(userId, pageNo) {

    this.journalsService.getJournals(userId, 0, 0, 0, 0, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.myJournals = resp.messageId.messages;
        this.myJournalsNo = resp.messageId.totalCount;
      }
    });

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 25000,
    });
  }

  private checkSession() {
    this.userSessionService.checkToken().subscribe(resp => {

      if (resp.isValid) {

      } else {
        this.email = null;
        //clearInterval(this.sessionCheck);
        //this.router.navigate(['login;timeout']);
        this.router.navigate(['/login'], { queryParams: { timeout: 1 } });
      }
    });
  }

  private getMe() {
    //console.log("header calling");
    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
             //this.me = new Me();
      //if( resp.user !== null){
      this.userName = resp.user[0].userName;
      this.email = resp.user[0].emailAddress;
      this.officeId = resp.user[0].officeId;
      this.me = resp.user[0];
      let arrayOfRoles = new Array();
      this.me.roles.forEach((role) => {
        arrayOfRoles.push(role.roleId);
      });
      if (arrayOfRoles.includes(3)) {
        this.navRoleOffice = true;
      }
      if (arrayOfRoles.includes(2)) {
        this.navRoleInvoicing = true;
      }
      if (arrayOfRoles.includes(5)) {
        this.navRoleJournal = true;
      }
      if (arrayOfRoles.includes(6)) {
        this.navRoleHeadOffice = true;
      }
      }
      //this.userSessionService.updateUser(resp);
    });
  }
  logout() {
    this.loginService.logout().subscribe(resp => {
      this.router.navigate(['/login'], { queryParams: { timeout: 2 } });

    });
  }
  ngOnDestroy() {
    clearInterval(this.sessionCheck);
  }

}
