import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatAutocompleteModule} from '@angular/material/autocomplete';




import { ClientsContactsService } from '../../services/clients-contacts.service';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';

@Component({
  selector: 'app-office-clients',
  templateUrl: './office-clients.component.html',
  styleUrls: ['./office-clients.component.css'],
  providers:[ClientsContactsService]
})
export class OfficeClientsComponent implements OnInit {
  sourceTypes: SourceTypes[];
  paymentTypes: PaymentTypes[];
  taxCodes: TaxCodes[];
  param: any;
  officeId: number;


  isActiveFilter:number = 1;
  isOutOfHoursFilter:number = 0;
  isTrialFilter:number = 0;
  isMasterFilter:number = 0;
  isVatFilter:number = 0;
  paymentFilter:number = 0;
  sourceFilter:number = 0;
taxFilter:number = 0;
  activeStates = [
    {value: 0, viewValue: 'Any'},
    {value: 1, viewValue: 'Active'},
    {value: 2, viewValue: 'Inactive'}
  ];
  hourStates = [
    {value: 0, viewValue: 'Any'},
    {value: 1, viewValue: 'Yes'},
    {value: 2, viewValue: 'No'}
  ];
  trialStates = [
    {value: 0, viewValue: 'Any'},
    {value: 1, viewValue: 'Yes'},
    {value: 2, viewValue: 'No'}
  ];
  masterStates = [
    {value: 0, viewValue: 'Any'},
    {value: 1, viewValue: 'Yes'},
    {value: 2, viewValue: 'No'}
  ];
vatStates = [
    {value: 0, viewValue: 'Any'},
    {value: 1, viewValue: 'Yes'},
    {value: 2, viewValue: 'No'}
  ];
  paymentStates = [
    {value: 0, viewValue: 'Any'}
  ];
  sourceStates = [
    {value: 0, viewValue: 'Any'}
  ];
  taxStates= [
    {value: 0, viewValue: 'Any'}
  ];

 


  @Input()officeFilterId:Number = 0;
  listOfClients: Clients[];
  totalNoOfClients: any;
  constructor(public clientsContactsService: ClientsContactsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, public snackBar: MatSnackBar, public dialog: MatDialog) { 


  }

  ngOnInit() {

    this.route.params.forEach(params => {
      this.officeId = params["id"];
      //call your function, like getUserInfo()
      this.getClients(this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter);
})

   
    this.getSourceTypes();
    this.getTaxCodes();
    this.getPaymentTypes();
  }
  private getSourceTypes() {
    this.clientsContactsService.getSourceType(0, 0, 1).subscribe(resp => {
      this.sourceTypes = resp.sourceTypes;

      this.sourceTypes.forEach((type, i) =>{
        
                this.sourceStates.push({value: i+1, viewValue: type.name});
        
              });
    });
  }
  private getPaymentTypes() {
    this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {
      this.paymentTypes = resp.paymentTypes;
      this.paymentTypes.forEach((type, i) =>{

        this.paymentStates.push({value: i+1, viewValue: type.name});

      });
    });

  }
  private getTaxCodes() {
    this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {
      this.taxCodes = resp.taxCodes;
      this.taxCodes.forEach((type, i) =>{
        
                this.taxStates.push({value: i+1, viewValue: type.code});
        
              });
    });
  }


  getClients(isActiveFilter: number, isTrialFilter: number, isMasterFilter:number, isOutOfHoursFilter: number, isVatFilter:number, paymentFilter:number, sourceFilter:number, taxFilter:number) {
    this.clientsContactsService.getClients(0, '0', 0, 0, isActiveFilter, isTrialFilter, isMasterFilter, isOutOfHoursFilter, isVatFilter, paymentFilter, sourceFilter, taxFilter, 1, 'ASC', 1).subscribe(resp => {
  
      this.totalNoOfClients = resp.clientDetails.totalCount;
      
      this.listOfClients = resp.clientDetails.clients;

      console.log(this.listOfClients);
    });
  }

  reset(){
    this.isActiveFilter = 0;
    this.isOutOfHoursFilter = 0;
    this.isTrialFilter = 0;
    this.isVatFilter = 0;
    this.paymentFilter = 0;
    this.sourceFilter = 0;
    this.taxFilter = 0;
    this.getClients(this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter);

  }

}