import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-confirm-patch-dialog',
  templateUrl: './confirm-patch-dialog.component.html',
  styleUrls: ['./confirm-patch-dialog.component.css']
})
export class ConfirmPatchDialogComponent implements OnInit {

  

  constructor(public dialogRef: MatDialogRef<ConfirmPatchDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar) { }

  ngOnInit() {
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  onNoClick(){

    this.dialogRef.close();
  }

  approvePatch() {


    this.dialogRef.close();


  }
}
