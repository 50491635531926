import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';


import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';
import { ProductTypesData, ProductTypes, ProductType } from '../../models/productTypesData';

@Component({
  selector: 'app-view-line-item-dialog',
  templateUrl: './view-line-item-dialog.component.html',
  styleUrls: ['./view-line-item-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService, ClientsContactsService]
})
export class ViewLineItemDialogComponent implements OnInit {
  rForm: FormGroup;
  lineItem: LineItems;
  productTypes: ProductType[] = [];
  listOfProducts: Product[] = [];
  officeId: number = 1;
  listOfTaxCodes: TaxCode[] = [];
  productTypeId: number;
  loading: boolean = false;
  currentProductTypeId: number;
 

  constructor(public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<ViewLineItemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public invoicingService: InvoicingService) {

    this.rForm = fb.group({

      'quantity': [null, Validators.required],
      'productTypeId': [null, Validators.required],
      'productId': [null, Validators.required],
      'description': [null, Validators.required],
      'unitPrice': [null, Validators.required],
      'isActive': true,
      'taxId': [null, Validators.required],
    });

  }

  ngOnInit() {
    
    this.getProductTypes();
    this.getTaxCode();
console.log(this.data)
    //look up product type ID
    this.getProductType(this.data.lineItem.productId, this.data.lineItem.productTypeId);
    this.getProducts(this.data.lineItem.productTypeId);
    //this.getProducts(this.data.lineItem.productTypeId);
    this.lineItem = this.data.lineItem;

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getProductType(id, productTypeId) {

    if(productTypeId > 0){
    this.invoicingService.getInvoiceProduct(0, 0, 0, 0, this.officeId, 0, 1, 0,  1, 'ASC', 0).subscribe(resp => {
      console.log(resp);
      this.currentProductTypeId = resp.invoiceProducts.products[0].productTypeId;

      //this.lineItem.productTypeId = this.currentProductTypeId;
      
    })
  }
  }

  getProducts(productTypeId: any) {
    console.log(productTypeId);

    if (productTypeId > 0) {
      this.invoicingService.getInvoiceProduct(0, 0, 0, productTypeId, this.officeId, 0, 1, 1,  1, 'ASC', 0).subscribe(resp => {

        this.listOfProducts = resp.invoiceProducts.products;

      })
    }

  }

  updateValues(product: Product) {
    console.log(product);
    this.lineItem.unitPrice = product.unitCost;
    this.lineItem.description = product.description;
    this.lineItem.taxId = product.taxId;

  }


  getTaxCode() {

    this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);

      } else {

        this.listOfTaxCodes = resp.taxCodes;

      }
    })
  }

  getProductTypes() {

    this.invoicingService.getProductTypes(0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);

      } else {
        this.productTypes = resp.productTypes;

      }
    })
  }


  updateLineItem(post) {
    this.loading = true;
    this.invoicingService.updateLineItem(this.lineItem.lineItemId, this.lineItem).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.openSnackBar("Line Item deleted");
        this.loading = false;
        this.dialogRef.close();

      }


    })

  }

}
