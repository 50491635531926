import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { EmailValidator } from '@angular/forms';

import { UserData, UserDetails, Users, User, UserUpdate, UserAccount } from '../../models/userData';
import { MeData, Me } from '../../models/meData';

import { UserSessionService } from '../../services/user-session.service';
import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css'],
  providers: [ OfficesUsersRolesService]
})
export class MyAccountComponent implements OnInit {
  rForm: FormGroup;
  me: Me;
  meSelected: any;
  passwordChange: boolean = false;

  constructor(public snackBar: MatSnackBar, public userSessionService: UserSessionService, public officesUsersRolesService: OfficesUsersRolesService, private fb: FormBuilder) {


    this.rForm = fb.group({

      'firstName': [null, Validators.required],
      'surname': [null, Validators.required],
      'emailAddress': [null, Validators.required],
      'changePassword': [false, Validators.required],
      'password': [{ value: '', disabled: true }, Validators.required],
      'confirmPassword': [{ value: '', disabled: true }, Validators.required]
    }
      , { validator: this.checkIfMatchingPasswords('password', 'confirmPassword', 'changePassword') });

  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string, changePassword: string) {

    return (group: FormGroup) => {
      
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey],
        changeToggle = group.controls[changePassword];


        console.log(passwordInput.value + ' ' + passwordConfirmationInput.value);
        console.log(changeToggle.value);
      if (changeToggle.value) {
        if (passwordInput.value === passwordConfirmationInput.value) {
          return passwordConfirmationInput.setErrors(null);
        }
        else {
          return passwordConfirmationInput.setErrors({ notEquivalent: true })
          
        }
      } 
    }
  }
  checkPassword(state) {
    this.passwordChange = state;
    if (state) {
      this.rForm.controls['password'].enable();
      this.rForm.controls['confirmPassword'].enable();
    } else {
      this.rForm.controls['password'].disable();
      this.rForm.controls['confirmPassword'].disable();
    }
  }
  ngOnInit() {
    this.getOwnerUser();
    this.meSelected = new UserAccount();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 4000,
    });
  }

  private getOwnerUser() {
    this.userSessionService.getMe().subscribe(resp => {
      this.me = resp.user[0];
      this.getMe(this.me.userId);
    });
  }
  private getMe(userId: number) {
    this.officesUsersRolesService.getUsers(userId, '0', 0, 0, 1, 'ASC', 1).subscribe(resp => {
      this.meSelected = resp.userDetails.users[0];
      this.meSelected.password = '';
      this.meSelected.confirmPassword = '';
      this.meSelected.changePassword = false;
    });
  }

  updateMe(post) {


    this.userSessionService.updateBasicDetails(this.meSelected.userId, this.meSelected).subscribe(resp => {
      //this.listOfMovements= new MovementDetails();
      // this.listOfMovements= resp.movementDetails;

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.getOwnerUser();
        this.openSnackBar("Account updated");
      }

    });
  }
}
