export class InvoiceData {
  errors: any[] | null;
  invoices: Invoices;
}
export class Invoices {
  invoices: Invoice[] | null;
  totalCount: number;
}
export class Invoice {
  accountNo: string;
  clientId: number;
  clientName: string;
  createdBy: string;
  createdById: number;
  createdDate: any;
  endPeriod: any;
  footerText: string;
  invoiceId: number;
  invoiceNo: string;
  invoiceType: string;
  invoiceTypeId: number;
  isActive: boolean;
  lastUpdatedBy: string;
  lastUpdatedById: number;
  lastUpdatedDate: any;
  lineItems: LineItems[] | null;
  officeId: number;
  officeName: string;
  startPeriod: any;
  status: string;
  statusId: number;
  taxDate: any;
  totalAmount: number;
  totalCount: number;
  totalInvoiceAmount: number;
  totalVat: number;
  invoicePdfPath: string;
  reference: string;
  paymentTypeId: number;
  paymentTypeName: string;
  isTariff: boolean;
  startDate?: string;
  endDate?: string;
}
export class LineItems {
  amount: number;
  invoiceId: number;
  isActive: boolean;
  lineItemId: number;
  productCode: string;
  productId: number;
  productName: string;
  quantity: number;
  taxId: number;
  unitPrice: number;
  vatAmount: number;
  vatRate: number;
  description: string;
  productTypeId: number;
}
export class InvoiceSettingsData {
  errors: any[] | null;
  invoiceSettings: InvoiceSettings[] | null;

}
export class InvoiceSettings {
  creditNotePrefix: string;
  invoiceFooter: string;
  invoiceMessage: string;
  invoicePrefix: string;
  invoiceSettingId: number;
  invoiceTerms: string;
  isActive: boolean;
  name: string;
  officeId: number;
  officeName: string;
  ownerId: number;
  paymentTypeId: number;
  paymentTypeName: string;
  vatRegNumber: string;
  fromAddress: string;
  server:string;
  replyAddress: string;
  userName: string;
  port: string;
  password: string;
  invoiceEmailTemplate: string;
}
export class InvoiceSetting {
  creditNotePrefix: string;
  invoiceFooter: string;
  invoiceMessage: string;
  invoicePrefix: string;
  invoiceSettingId: number;
  invoiceTerms: string;
  isActive: boolean;
  name: string;
  officeId: number;
  officeName: string;
  ownerId: number;
  paymentTypeId: number;
  paymentTypeName: string;
  vatRegNumber: string;
  fromAddress: string;
  server:string;
  replyAddress: string;
  userName: string;
  port: string;
  password: string;
  invoiceEmailTemplate: string;
}
export class AddInvoiceData {
  clientId: number;
  officeId: number;
  invoiceTypeId: number;
}
export class AddInvoicePOST {
  errors: any[] | null
  invoiceId: number;
}
export class AddProductPOST {
  errors: any[] | null
  productId: number;
}
export class AddLinePOST {
  errors: any[] | null
  lineItemId: number;
}
export class AddLineItem {
  invoiceId: number;
  productId: number; 
  quantity: number;
  unitPrice: number;
  taxId: number;
  isActive: boolean;
  description: string;
  productTypeId: number;
}

export class VoidInvoice {
  errors: any[] | null
  invoiceId: number;
}


export class AutocompleteInvoice {
  errors: any[] | null
  invoices: Invoice[] | null;
}

export class EmailInvoiceData {
  toEmailAddress: string;
  bccEmailAddress: string;
  ccEmailAddress: string;
  emailMessage: string
}
export class PostEmailInvoice {
  errors: any[] | null
  isSuccess: boolean;
}
export class SentInvoiceData {
  errors: any[] | null
  sentInvoices: InvoiceSentItems;
}
export class InvoiceSentItems {
  invoiceSentItems: InvoiceSentItem[] | null;
  totalCount: number;
}
export class InvoiceSentItem {
  clientName: string;
  emailTo: string;
  invoiceNo: string;
  sentDate: string;
  totalCount: number
}