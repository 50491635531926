import { Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import 'rxjs/Rx';


import { AppSettings } from '../app-settings';
import { UserUpdate } from '../models/userData';
import { MeData, Me } from '../models/meData';

export class Check {
  errors: string | null;
  isValid: boolean;
}
export const TEMP: MeData = {
  errors: null,
  user: null
}

@Injectable()
export class UserSessionService {

  userIdSubject = new BehaviorSubject<string>('');
  officeIdSubject = new BehaviorSubject<string>('');
  rolesSubject = new BehaviorSubject<string>('');
  userNameSubject = new BehaviorSubject<string>('');

  getUserId(): Observable<string> {
    if (localStorage.getItem('userId')) {
      this.userIdSubject.next(localStorage.getItem('userId'));
      return this.userIdSubject.asObservable();
    } else {
      this.getMe();
      return this.userIdSubject.asObservable();
    }
  }

  getOfficeId(): Observable<string> {
    if (localStorage.getItem('officeId')) {
      this.officeIdSubject.next(localStorage.getItem('officeId'));
      return this.officeIdSubject.asObservable();
    } else {
      this.getMe();
      return this.officeIdSubject.asObservable();
    }
  }

  getRoles(): Observable<string> {
    
    if (localStorage.getItem('roles')) {
      this.rolesSubject.next(localStorage.getItem('roles'));
      return this.rolesSubject.asObservable();
    } else {
      this.getMe();
      return this.rolesSubject.asObservable();
    }
  }
  constructor(public http: HttpClient, public snackBar: MatSnackBar, private router: Router) {

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  checkLogin(data: Check){

    if (data.isValid) {
    
    } else {
      this.router.navigate(['/login'], { queryParams: { timeout: 2 }});
    }


  }

  updateUser(meData: MeData) {


    if (meData.errors.length > 0) {
      this.openSnackBar(meData.errors[0].error);
    } else {

 
      this.userNameSubject.next(meData.user[0].userName);
      console.log(meData.user[0].userName);
      localStorage.setItem('userId', meData.user[0].userId.toString());
      localStorage.setItem('officeId', meData.user[0].officeId.toString());
      let userRoles = '';
      meData.user[0].roles.forEach((role) => {
        userRoles += role.roleId + ',';
      });
      userRoles = userRoles.slice(0, -1);
  
      localStorage.setItem('roles', userRoles);


    }


  }
  updateUserName(): Observable<string> {
    return this.userNameSubject.asObservable();
  }

  getMe(): Observable<MeData> {

    return this.http.get<MeData>(AppSettings.API_ENDPOINT + "/me").do(data => this.updateUser(data));
  }

  checkToken(): Observable<Check> {
    return this.http.get<Check>(AppSettings.API_ENDPOINT + "/checkAuthentication").do(data => this.checkLogin(data));
  }

  updateBasicDetails(userId: number, updateData: any): Observable<MeData> {
    return this.http.post<MeData>(AppSettings.API_ENDPOINT + "/user/updateBasicDetails/" + userId, updateData).do(data => this.getMe());
  }

}
