import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';

@Component({
  selector: 'app-delete-line-item-dialog',
  templateUrl: './delete-line-item-dialog.component.html',
  styleUrls: ['./delete-line-item-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService]
})
export class DeleteLineItemDialogComponent implements OnInit {
  lineItem: LineItems;

  constructor(public dialogRef: MatDialogRef<DeleteLineItemDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.lineItem = this.data.lineItem;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  deleteLineItem() {

    this.lineItem.isActive = false;

    this.invoicingService.updateLineItem(this.lineItem.lineItemId, this.lineItem).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Line Item deleted");

        this.dialogRef.close();

      }


    })

  }

}
