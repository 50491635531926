export class ContactData{
    contactDetails: ContactDetails | null;
    errors: any[] | null;   
}
export class ContactDetails{
    contacts:  Contacts[];
    totalCount: number;
}
export class Contacts{
    clientId: number;
    clientName: string;
    contactId: number;
    createdBy: string;
    createdById: number;
    createdDate: string;
    deliveryTypeId: number;
    deliveryTypeName: string;
    emailAddress1: string;
    emailAddress2: string;
    faxNo: string;
    firstName: string;
    fullName: string;
    isActive: boolean;
    isDefaultContact: boolean;
    isEmailAddress1Eod: boolean;
    isEmailAddress1Mod: boolean;
    isEmailAddress1Private: boolean;
    isEmailAddress2Eod: boolean;
    isEmailAddress2Mod: boolean;
    isEmailAddress2Private: boolean;
    isFaxNoPrivate: boolean;
    isMobileNoPrivate: boolean;
    isPhoneNoPrivate: boolean;
    isPrinciple: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: number | null;
    lastUpdatedDate: string | null;
    mobileNo: string;
    phoneNo: string;
    position: string;
    surname: string;
    title: string;
    totalCount: number;
}
export class Contact{
    clientId: number;
    clientName: string;
    contactId: number;
    createdBy: string;
    createdById: number;
    createdDate: string;
    deliveryTypeId: number;
    deliveryTypeName: string;
    emailAddress1: string;
    emailAddress2: string;
    faxNo: string;
    firstName: string;
    fullName: string;
    isActive: boolean;
    isDefaultContact: boolean;
    isEmailAddress1Eod: boolean;
    isEmailAddress1Mod: boolean;
    isEmailAddress1Private: boolean;
    isEmailAddress2Eod: boolean;
    isEmailAddress2Mod: boolean;
    isEmailAddress2Private: boolean;
    isFaxNoPrivate: boolean;
    isMobileNoPrivate: boolean;
    isPhoneNoPrivate: boolean;
    isPrinciple: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: number | null;
    lastUpdatedDate: string | null;
    mobileNo: string;
    phoneNo: string;
    position: string;
    surname: string;
    title: string;
    totalCount: number;
    showPhone?: boolean = false;
    showMobile?: boolean = false;
    showEmail1?: boolean = false;
    showEmail2?: boolean = false;
    showFax?: boolean = false;
    isMovement?: boolean = false;
}
export class ContactsAutocomplete{
    contacts: ContactAutocomplete[] | null;
    errors: any[] | null;
}
export class ContactAutocomplete{
    contactId:number;
    contactName: string;

}