import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { ClientsContactsService } from '../../services/clients-contacts.service';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';

import { InvoicingService } from '../../services/invoicing.service';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';
import { ProductTypesData, ProductTypes, ProductType } from '../../models/productTypesData';
import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-view-invoice-product-dialog',
  templateUrl: './view-invoice-product-dialog.component.html',
  styleUrls: ['./view-invoice-product-dialog.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [InvoicingService, ClientsContactsService, UserSessionService]
})
export class ViewInvoiceProductDialogComponent implements OnInit {
  product: Product;
  rForm: FormGroup;
  listOfTaxCodes: TaxCode[] = [];
  listOfProductTypes: ProductType[] = [];
  officeId: number = 0;
  me: any;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  disabled: boolean = true;

  constructor(public clientsContactsService: ClientsContactsService, public userSessionService:UserSessionService,  public dialogRef: MatDialogRef<ViewInvoiceProductDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {

    this.rForm = fb.group({
      'productName': [null, Validators.required],
      'productCode': [null, Validators.required],
      'description': [null, Validators.required],
      'unitCost': [null, Validators.required],
      'includedMessages': [null, Validators.required],
      'messageCost': [null, Validators.required],
      'isGlobal': [false],
      'taxId': [null, Validators.required],
      'productTypeId': [null, Validators.required],
      'isActive': [null, Validators.required],
      'isDisplayInvoice': [null, Validators.required],
    });
  }

  ngOnInit() {
    this.getMe();
    this.product = new Product();

    this.product.officeId = this.data.product.officeId;
    this.product = this.data.product;

    this.checkValue(this.product.productTypeId)

    this.getTaxRates();
    this.getProductTypes();
  }
  checkValue(value: number) {

    setTimeout(() => {
      const ctrl = this.rForm.get('messageCost');
      const ctrl1 = this.rForm.get('includedMessages');

      if (value === 1) {
        ctrl.enable();
        ctrl1.enable();
      } else {
        ctrl.disable();
        ctrl1.disable();
      }
  });



  }


  getMe() {
    //console.log("header calling");
    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        //this.me = new Me();


        this.me = resp.user[0];
        let arrayOfRoles = new Array();
        this.me.roles.forEach((role) => {
          arrayOfRoles.push(role.roleId);
        });
        const global = this.rForm.get('isGlobal');
        global.disable();
        if (arrayOfRoles.includes(3)) {
          this.navRoleOffice = true;
          global.disable();
        }
        if (arrayOfRoles.includes(2)) {
          this.navRoleInvoicing = true;
          global.disable();
        }
        if (arrayOfRoles.includes(5)) {
          this.navRoleJournal = true;
          global.disable();
        }
        if (arrayOfRoles.includes(6)) {
          this.navRoleHeadOffice = true;
         
          global.enable();

        }
      }
      //this.userSessionService.updateUser(resp);
    });
  }


  getTaxRates() {

    this.clientsContactsService.getTaxCode(0, 0, 0).subscribe(resp => {

      this.listOfTaxCodes = resp.taxCodes;

    })

  }

  getProductTypes() {

    this.invoicingService.getProductTypes(0, 0, 0).subscribe(resp => {

      this.listOfProductTypes = resp.productTypes;

    })

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  updateInvoiceProduct(post) {

    this.invoicingService.updateInvoiceProduct(this.product.productId, this.product).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Product updated");
        this.dialogRef.close();

      }


    })


  }
}
