import { Injectable }     from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs/RX"

@Injectable()
export class AuthGuard implements CanActivate {
  public token: string | null;

  constructor(private router: Router){}

  canActivate(router:ActivatedRouteSnapshot, state:RouterStateSnapshot) {

    this.token = localStorage.getItem('Token');
    console.log('AuthGuard#canActivate called token ' + this.token);
    if(this.token){
      
      return true;

    }else{
      this.router.navigate(['/login'], { queryParams: { timeout: 2 }});
      return false;
    }
    
  }
}