import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { UserSessionService } from '../../services/user-session.service';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';


import { AddClientDialogComponent } from '../../components/add-client-dialog/add-client-dialog.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [ClientsContactsService, OfficesUsersRolesService, UserSessionService]
})
export class ClientsComponent implements OnInit {
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  filteredUsers: ClientsAutocomplete[];
  selectedOption: ClientAutocomplete;
  filteredClients: ClientAutocomplete[] = [];
  options = [];
  selectedClient: Client;
  sourceTypes: SourceTypes[];
  paymentTypes: PaymentTypes[];
  taxCodes: TaxCodes[];
  listOfSourceTypes: SourceTypes[];
  listOfOffices: OfficeData;
  isActiveFilter: number = 1;
  isOutOfHoursFilter: number = 0;
  isTrialFilter: number = 0;
  isMasterFilter: number = 0;
  isVatFilter: number = 0;
  paymentFilter: number = 0;
  sourceFilter: number = 0;
  taxFilter: number = 0;
  officeSelected: any;
  paymentTypes2: PaymentTypes[];
  taxCodes2: TaxCodes[];
  officeIdSubject: Observable<string>;
  officeId: number;
  sortId: number = 1;
  sortDir: string = 'ASC';
  pageNo: number = 0;
  rForm: FormGroup;
  loadingClients: boolean = true;
  clientName: string = '';
  clientNameFinal: any = 0;

  activeStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];
  hourStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  trialStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  masterStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  vatStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  paymentStates = [
    { value: 0, viewValue: 'Any' }
  ];
  sourceStates = [
    { value: 0, viewValue: 'Any' }
  ];
  taxStates = [
    { value: 0, viewValue: 'Any' }
  ];

  // MdPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  @Input() officeFilterId: Number = 0;
  listOfClients: Clients[];
  totalNoOfClients: any;
  constructor(public clientsContactsService: ClientsContactsService, public userSessionService: UserSessionService, public router: Router, public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {

    this.rForm = fb.group({
      'clientAutocomplete': [null, Validators.required],
    });


    this.rForm.controls['clientAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientAutocomplete'].value);
        if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 0, 1)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }
      });

    this.officeIdSubject = userSessionService.getOfficeId();
  } 

  myControl: FormControl = new FormControl();

  contactControl: FormControl = new FormControl();

  filteredOptions: Observable<ClientAutocomplete[]>;

  filter(clientName: string): ClientAutocomplete[] {

    this.clientsContactsService.getClientsAutocomplete(clientName, 0, this.officeId, 0, 0).subscribe(resp => {
      this.options = [];
      //this.filteredUsers = resp.userDetails.users;

      resp.clients.forEach((clients) => {

        let client = new ClientAutocomplete();
        client = clients;
        this.options.push(client);
      });

      console.log("here " + clientName);
    });

    return this.options.filter(option =>
      option.clientName.toLowerCase().indexOf(clientName.toLowerCase()) === 0);

  }

  displayFn(user: ClientAutocomplete): string {
    this.selectedOption = user;
    console.log(this.selectedOption);
    return user ? user.clientName : '';
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }

  changeDir(sortId) {
    if (this.sortDir === 'ASC') {
      this.sortDir = 'DESC';
      this.sortId = sortId;
      this.getClients(this.clientName, this.officeId, this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter, this.sortId, this.sortDir, this.pageNo);
    } else {
      this.sortDir = 'ASC';
      this.sortId = sortId;
      this.getClients(this.clientName, this.officeId, this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter, this.sortId, this.sortDir, this.pageNo);
    }

  }
  ngOnInit() {


    this.officeIdSubject.subscribe(id => this.officeId = Number(id));
    this.getOffice(this.officeId);
    this.paymentTypes = new Array();
    this.getSourceTypes();
    this.getTaxCodes();
    this.getPaymentTypes();

    this.getClients(this.clientName, this.officeId, this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter, this.sortId, this.sortDir, this.pageNo);

    this.filteredOptions = this.myControl.valueChanges
      .startWith(null)
      .map(user => user && typeof user === 'object' ? user.userName : user)
      .map(userName => userName ? this.filter(userName) : this.options.slice());
  }

  getSourceTypes() {
    this.clientsContactsService.getSourceType(0, 0, 1).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.sourceTypes = resp.sourceTypes;

        this.sourceTypes.forEach((type, i) => {

          this.sourceStates.push({ value: type.sourceTypeId , viewValue: type.name });

        });
      }

    });
  }
  getPaymentTypes() {
    this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.paymentTypes = resp.paymentTypes;
        this.paymentTypes.forEach((type, i) => {

          this.paymentStates.push({ value: i + 1, viewValue: type.name });

        });
      }

    });

  }
  getOffice(officeId: number) {
    this.officesUsersRolesService.getOffices(officeId, '0', 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.officeSelected = resp.officeDetails.offices[0];
      }
    });
  }
  getTaxCodes() {
    this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.taxCodes = resp.taxCodes;
        this.taxCodes.forEach((type, i) => {

          this.taxStates.push({ value: i + 1, viewValue: type.code });

        });
      }

    });
  }


  setPage(event) {
    this.pageNo = event.pageIndex;
    console.log(event);
    this.getClients(this.clientName, this.officeId, this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter, this.sortId, this.sortDir, event.pageIndex + 1);
  }

  getClients(clientName: any, officeId: number, isActiveFilter: number, isTrialFilter: number, isMasterFilter: number, isOutOfHoursFilter: number, isVatFilter: number, paymentFilter: number, sourceFilter: number, taxFilter: number, sortId: number, sortDir: string, pageNo: number) {
    this.loadingClients = true;

    if(clientName === ''){
      this.clientNameFinal = 0;
    }else{
      this.clientNameFinal = clientName;

    }
    this.clientsContactsService.getClients(0, this.clientNameFinal, 0, officeId, isActiveFilter, isTrialFilter, isMasterFilter, isOutOfHoursFilter, isVatFilter, paymentFilter, sourceFilter, taxFilter, sortId, sortDir, pageNo).subscribe(resp => {
      this.loadingClients = false;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.totalNoOfClients = resp.clientDetails.totalCount;

        this.listOfClients = resp.clientDetails.clients;
        }
     
    });
  }

  reset() {
    this.isActiveFilter = 0;
    this.isOutOfHoursFilter = 0;
    this.isTrialFilter = 0;
    this.isVatFilter = 0;
    this.paymentFilter = 0;
    this.sourceFilter = 0;
    this.taxFilter = 0;
    this.isMasterFilter = 0;
    this.clientName = '';
    this.clientNameFinal = 0;
    this.getClients(this.clientName, this.officeId, this.isActiveFilter, this.isTrialFilter, this.isMasterFilter, this.isOutOfHoursFilter, this.isVatFilter, this.paymentFilter, this.sourceFilter, this.taxFilter, this.sortId, this.sortDir, this.pageNo);

  }

  goToClient(client) {
    if (client.clientId > 0) {
      this.router.navigate(['/clients', client.clientId, 'tab', 0]);
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
  addClientDialog(): void {

    this.clientsContactsService.getSourceType(0, '0', 1).subscribe(resp => {

      this.listOfSourceTypes = resp.sourceTypes;
      this.officesUsersRolesService.getOffices(0, '0', 1, 1).subscribe(resp => {
        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
          this.userSessionService.checkToken().subscribe();
        } else {
          this.listOfOffices = resp;
          this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {
            this.paymentTypes2 = resp.paymentTypes;


            this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {
              this.taxCodes2 = resp.taxCodes;


              let dialogRef = this.dialog.open(AddClientDialogComponent, {
                width: '90%',
                data: {
                  sourceData: this.listOfSourceTypes,
                  officeData: this.listOfOffices,
                  officeSelected: this.officeSelected,
                  paymentTypes: this.paymentTypes,
                  taxCode: this.taxCodes
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                console.log('The dialog was closed');
                this.reset();
              });

            });

          });

        }
      });
    });
  }
}
