import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



import * as moment from 'moment';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { TotalMessagesByUserAndDateData, UsersReporting } from '../../models/totalMessagesByUserAndDateData';
import { TotalMessagesByClientAndDateData, ClientsReporting } from '../../models/totalMessagesByClientAndDateData';
import { ViewMovementDialogComponent } from '../../components/view-movement-dialog/view-movement-dialog.component';

import { MovementsService } from '../../services/movements.service';

import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { ClientsContactsService } from '../../services/clients-contacts.service';

import { ReportingService } from '../../services/reporting.service';

import { InvoicingService } from '../../services/invoicing.service';

import { StatusTypesData, StatusTypes, StatusType } from '../../models/invoiceStatusData';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettings, InvoiceSetting } from '../../models/invoiceData';

import { MovementData, MovementDetails, Movements, Movement } from '../../models/movementData';
import { UserData, UserDetails, Users, User } from '../../models/userData';

import { UserSessionService } from '../../services/user-session.service';
import { OfficeDetails, Office } from '../../models/officeData';
import { MeData, Me } from '../../models/meData';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.css'],
  providers: [ClientsContactsService, ReportingService, MovementsService, OfficesUsersRolesService, UserSessionService, InvoicingService]
})
export class ReportingComponent implements OnInit {
  rForm: FormGroup;
  rForm2: FormGroup;
  rForm3: FormGroup;
  rForm4: FormGroup;
  filteredClients: ClientAutocomplete[] = [];
  filteredClients2: ClientAutocomplete[] = [];
  filteredClients3: ClientAutocomplete[] = [];
  filteredClients4: ClientAutocomplete[] = [];
  filteredOptionsClients: Observable<ClientAutocomplete[]>;
  clientFromDate: any;
  clientFromDateFinal: any;
  clientToDate: any;
  clientToDateFinal: any;
  userReportingStats: UsersReporting[] = [];
  clientsReportingStats: ClientsReporting[] = [];
  filterByDate: boolean = true;
  filterByDate2: boolean = true;
  filterByDate3: boolean = false;
  filterByDate4: boolean = false;
  selectedClient: number = 0;
  selectedClient2: number = 0;
  selectedClient3: number = 0;
  selectedClient4: number = 0;
  userFromDateFinal: any;
  userToDateFinal: any;
  userFromDate: any;
  userToDate: any;
  loadingCSV: boolean = false;
  loadingCSV2: boolean = false;
  listOfMovements: Movements[];
  noOfMovements: number;
  loadingUserStats: boolean = true;
  loadingClientStats: boolean = true;
  loadingMovements: boolean = true;
  isActiveFilter: number = 1;
  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];
  movementFromDate: any;
  movementToDate: any;
  movementFromDateFinal: any;
  movementToDateFinal: any;
  invoiceFromDate: any;
  invoiceToDate: any;
  invoiceFromDateFinal: any;
  invoiceToDateFinal: any;
  selectedUser: number = 0;
  filteredUsers: Users[];
  officeIdSubject: Observable<string>;
  officeId: number;
  messageFromDate: any;
  messageToDate: any;
  listOfOffices: Office[] = [];
  listOfInvoiceStatus: StatusType[] = [];
  statusTypeId: number = 0;
  lineItems: boolean = false;
  selectedUser1: MeData;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  navRoleClientAdmin: boolean = false;
  me: any;
  userOffice: number = 0;
  pageNo: number = 0;
  days = [];
  dayFrom: number = 1;
  dayTo: number = 28;

  constructor(private router: Router, private route: ActivatedRoute, public invoicingService: InvoicingService, public dialog: MatDialog, public userSessionService: UserSessionService, public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar, private fb: FormBuilder, public movementsService: MovementsService, public clientsContactsService: ClientsContactsService, public reportingService: ReportingService) {

    this.officeIdSubject = userSessionService.getOfficeId();

    this.rForm = fb.group({
      'clientIda': new FormControl(),
      'userId': new FormControl(),
    });
    this.rForm2 = fb.group({
      'clientId': new FormControl(),

    });

    this.rForm3 = fb.group({
      'clientId': new FormControl(),
      'isActiveFilter': new FormControl(),
    });

    this.rForm4 = fb.group({
      'officeId': new FormControl(),
      'clientId': new FormControl(),
      'lineItems': new FormControl(),
      'statusTypeId': new FormControl()
    });

    this.rForm.controls['clientIda'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientIda'].value);
        if (this.rForm.controls['clientIda'].value === '' || typeof this.rForm.controls['clientIda'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientIda'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }

      });

    this.rForm.controls['userId'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['userId'].value);
        if (this.rForm.controls['userId'].value === '' || typeof this.rForm.controls['userId'].value != 'string') {
          return this.filteredUsers = null;
        } else {
          this.officesUsersRolesService.getUsers(0, this.rForm.controls['userId'].value, this.officeId, 0, 1, 'ASC', 1)
            .subscribe(res => {
              return this.filteredUsers = res.userDetails.users
            })
        }

      });


    this.rForm2.controls['clientId'].valueChanges
      .subscribe(name => {
        console.log(this.rForm2.controls['clientId'].value);
        if (this.rForm2.controls['clientId'].value === '' || typeof this.rForm2.controls['clientId'].value != 'string') {
          return this.filteredClients2 = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm2.controls['clientId'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients2 = res.clients
            })
        }

      });

    this.rForm3.controls['clientId'].valueChanges
      .subscribe(name => {
        console.log(this.rForm3.controls['clientId'].value);
        if (this.rForm3.controls['clientId'].value === '' || typeof this.rForm3.controls['clientId'].value != 'string') {
          return this.filteredClients3 = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm3.controls['clientId'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients3 = res.clients
            })
        }

      });

    this.rForm4.controls['clientId'].valueChanges
      .subscribe(name => {
        console.log(this.rForm4.controls['clientId'].value);
        if (this.rForm4.controls['clientId'].value === '' || typeof this.rForm4.controls['clientId'].value != 'string') {
          return this.filteredClients4 = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm4.controls['clientId'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients4 = res.clients
            })
        }

      });
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  displayFnUser(user: User): any {
    return user ? user.userName : user;
  }

  private getMe() {
    this.userSessionService.getMe().subscribe(resp => {

      this.selectedUser1 = resp;
      this.userOffice = resp.user[0].officeId;
      this.getOffices();
      this.me = resp.user[0];
      let arrayOfRoles = new Array();
      this.me.roles.forEach((role) => {
        arrayOfRoles.push(role.roleId);
      });
      if (arrayOfRoles.includes(2)) {
        this.navRoleClientAdmin = true;
        this.navRoleInvoicing = true;
      }
      if (arrayOfRoles.includes(4)) {
        this.navRoleInvoicing = true;
      }
      if (arrayOfRoles.includes(5)) {
        this.navRoleJournal = true;
      }
      if (arrayOfRoles.includes(6)) {

        this.navRoleHeadOffice = true;
      }


    });
  }

  ngOnInit() {

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.clientFromDate = moment().subtract(7, 'days').toDate();
    this.clientToDate = moment().toDate();

    this.userFromDate = moment().subtract(7, 'days').toDate();
    this.userToDate = moment().toDate();

    this.movementFromDate = moment().subtract(7, 'days').toDate();
    this.movementToDate = moment().toDate();

    this.messageFromDate = moment().subtract(7, 'days').toDate();
    this.messageToDate = moment().toDate();

    this.invoiceFromDate = moment().subtract(7, 'days').toDate();
    this.invoiceToDate = moment().toDate();

    this.getUserStats();
    this.getClientStats();
    this.getMovements(this.selectedClient3, this.isActiveFilter, 1);

    this.getOffices();
    this.getInvoiceStatus();

    this.getMe();

    let i: number = 0;

    for (i = 1; i < 29; i++) {
      if (i < 10) {
        this.days.push({ id: i, viewValue: '0' + i });
      } else {
        this.days.push({ id: i, viewValue: String(i) });
      }
    }


  }

  setRange(val) {



  }

  setPage(event) {
    this.pageNo = event.pageIndex;
    this.getMovements(this.selectedClient3, this.isActiveFilter, this.pageNo);
  }


  printMovement(selectedClient: number, isActiveFilter: number) {

    if (this.filterByDate3) {
      this.movementFromDateFinal = moment(this.movementFromDate).format('YYYY-MM-DD');
      this.movementToDateFinal = moment(this.movementToDate).format('YYYY-MM-DD');
    } else {
      this.movementFromDateFinal = '0';
      this.movementToDateFinal = '0';
    }
    //client, dates, active
    //this.router.navigate(['/print-movements'], { queryParams: { active: 2, from: 3 }});
    window.open("/print-movements?active=" + isActiveFilter + "&from=" + this.movementFromDateFinal + "&to=" + this.movementToDateFinal + "&client=" + selectedClient, "_blank");
  }

  downloadFile(data: any) {

    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'data.csv');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
    this.loadingCSV = false;
    this.loadingCSV2 = false;
  }
  resetUserStats() {
    this.filterByDate = true;
    this.clientFromDate = moment().toDate();
    this.clientToDate = moment().toDate();
    this.selectedClient = 0;
    this.selectedUser = 0;
    this.getUserStats();
  }

  resetClientsStats() {
    this.userFromDate = moment().toDate();
    this.userToDate = moment().toDate();
    this.getClientStats();
  }

  setClient(client) {

    if (client.clientId > 0) {
      this.selectedClient = client.clientId;
    } else {
      this.selectedClient = 0
    }

  }
  setUser(user) {

    if (user.userId > 0) {
      this.selectedUser = user.userId;
    } else {
      this.selectedUser = 0
    }
  }

  getUserStats() {
    this.loadingUserStats = true;
    if (this.filterByDate) {
      this.clientFromDateFinal = moment(this.clientFromDate).format('YYYY-MM-DD');
      this.clientToDateFinal = moment(this.clientToDate).format('YYYY-MM-DD');
    } else {
      this.clientFromDateFinal = '0';
      this.clientToDateFinal = '0';
    }

    this.reportingService.getTotalMessagesByUserAndDate(this.selectedUser, this.selectedClient, this.clientFromDateFinal, this.clientToDateFinal).subscribe(resp => {

      this.userReportingStats = resp.users;
      this.loadingUserStats = false;
    })

  }
  getUserStatsCSV() {
    this.loadingCSV = true;
    if (this.filterByDate) {
      this.clientFromDateFinal = moment(this.clientFromDate).format('YYYY-MM-DD');
      this.clientToDateFinal = moment(this.clientToDate).format('YYYY-MM-DD');
    } else {
      this.clientFromDateFinal = '0';
      this.clientToDateFinal = '0';
    }

    this.reportingService.getCSVTotalMessagesByUserAndDate(this.selectedUser, this.selectedClient, this.clientFromDateFinal, this.clientToDateFinal).subscribe(resp => {
      this.downloadFile(resp);
      //this.userReportingStats = resp.users;

    })

  }
  setClient2(client) {

    if (client.clientId > 0) {
      this.selectedClient2 = client.clientId;
    } else {
      this.selectedClient2 = 0
    }
  }

  setClient3(client) {

    if (client.clientId > 0) {
      this.selectedClient3 = client.clientId;
    } else {
      this.selectedClient3 = 0
    }
  }
  getClientStats() {
    this.loadingClientStats = true;
    if (this.filterByDate2) {
      this.userFromDateFinal = moment(this.userFromDate).format('YYYY-MM-DD');
      this.userToDateFinal = moment(this.userToDate).format('YYYY-MM-DD');
    } else {
      this.userFromDateFinal = '0';
      this.userToDateFinal = '0';
    }

    this.reportingService.getTotalMessagesByClientAndDate(this.selectedClient2, this.userFromDateFinal, this.userToDateFinal, 0).subscribe(resp => {

      this.clientsReportingStats = resp.clients;
      this.loadingClientStats = false;
    })

  }
  getClientStatsCSV() {
    this.loadingCSV2 = true;
    if (this.filterByDate2) {
      this.userFromDateFinal = moment(this.userFromDate).format('YYYY-MM-DD');
      this.userToDateFinal = moment(this.userToDate).format('YYYY-MM-DD');
    } else {
      this.userFromDateFinal = '0';
      this.userToDateFinal = '0';
    }

    this.reportingService.getCSVTotalMessagesByClientAndDate(this.selectedClient2, this.userFromDateFinal, this.userToDateFinal).subscribe(resp => {

      this.downloadFile(resp);

    })

  }

  setClient4(client) {

    if (client.clientId > 0) {
      this.selectedClient4 = client.clientId;
    } else {
      this.selectedClient4 = 0
    }

  }

  getMovements(selectedClient: number, isActiveFilter: number, pageNo: number) {
    this.loadingMovements = true;
    if (this.filterByDate3) {
      this.movementFromDateFinal = moment(this.movementFromDate).format('YYYY-MM-DD');
      this.movementToDateFinal = moment(this.movementToDate).format('YYYY-MM-DD');
    } else {
      this.movementFromDateFinal = '0';
      this.movementToDateFinal = '0';
    }

    this.movementsService.getMovements(0, selectedClient, isActiveFilter, this.movementFromDateFinal, this.movementToDateFinal, 1, 'ASC', pageNo).subscribe(resp => {
      this.noOfMovements = resp.movementDetails.totalCount;
      this.listOfMovements = resp.movementDetails.movements;
      this.loadingMovements = false;
    });
  }

  getMovementCSV(selectedClient: number, isActiveFilter: number) {

    this.movementsService.csvMovements(0, selectedClient, isActiveFilter, this.movementFromDateFinal, this.movementToDateFinal, 1, 'ASC').subscribe(resp => {
      //this.noOfMovements = resp.movementDetails.totalCount;
      //this.listOfMovements = resp.movementDetails.movements;
      this.downloadFile(resp);
      this.loadingMovements = false;
    });


  }

  resetMovements() {

    this.movementFromDateFinal = '0';
    this.movementToDateFinal = '0';
    this.isActiveFilter = 0;
    this.selectedClient3 = 0;
    this.pageNo = 1;
    this.getMovements(this.selectedClient3, this.isActiveFilter, 1);


  }


  getMessageCount(messageFromDate: string, messageToDate: string) {

    this.reportingService.getCSVForMessagesCount(moment(messageFromDate).format('YYYY-MM-DD'), moment(messageToDate).format('YYYY-MM-DD')).subscribe(resp => {

      this.downloadFile(resp);

    })

  }

  resetMessageCount() {

  }

  //get offices
  getOffices() {

    this.officesUsersRolesService.getOffices(0, 0, 1, 1).subscribe(resp => {
      this.listOfOffices = resp.officeDetails.offices;
    })

  }

  getInvoiceStatus() {

    this.invoicingService.getInvoiceStatusType(0, 0, 1).subscribe(resp => {

      this.listOfInvoiceStatus = resp.statusTypes;
      this.listOfInvoiceStatus.unshift({ statusTypeId: 0, name: "Any", isActive: true });
    })
  }

  getInvoicesReport(selectedClient4, isActiveFilter) {

    if (this.filterByDate4) {
      this.invoiceFromDateFinal = moment(this.invoiceFromDate).format('YYYY-MM-DD');
      this.invoiceToDateFinal = moment(this.invoiceToDate).format('YYYY-MM-DD');
    } else {
      this.invoiceFromDateFinal = '0';
      this.invoiceToDateFinal = '0';
    }

    if (this.lineItems) {
      this.reportingService.getCSVForInvoiceWithLineItems(this.officeId, selectedClient4, this.statusTypeId, this.invoiceFromDateFinal, this.invoiceToDateFinal).subscribe(resp => {

        this.downloadFile(resp);

      })

    } else {
      this.reportingService.getCSVForInvoice(this.officeId, selectedClient4, this.statusTypeId, this.invoiceFromDateFinal, this.invoiceToDateFinal).subscribe(resp => {

        this.downloadFile(resp);

      })
    }
  }

  viewMovementDialog(movement): void {

    let dialogRef = this.dialog.open(ViewMovementDialogComponent, {
      width: '1000px',
      data: { movementData: movement }
    });
    dialogRef.afterClosed().subscribe(result => {


    });


  }
  resetInvoices() {


  }

  getClients() {

    this.reportingService.getCSVForClients(this.dayFrom, this.dayTo).subscribe(resp => {
      this.downloadFile(resp);
    })
  }

}
