import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { MovementsService } from '../../services/movements.service';
import { MovementData, MovementDetails, Movements, Movement } from '../../models/movementData';
import { ViewMovementDialogComponent } from '../../components/view-movement-dialog/view-movement-dialog.component';

@Component({
  selector: 'app-client-movements',
  templateUrl: './client-movements.component.html',
  styleUrls: ['./client-movements.component.css'],
  providers:[MovementsService]
})
export class ClientMovementsComponent implements OnInit {
  listOfMovements: Movements[];
  noOfMovements: number;
  clientId: number = 0;

  isActiveFilter:number = 1;
  activeStates = [
    {value: 0, viewValue: 'All'},
    {value: 1, viewValue: 'Active'},
    {value: 2, viewValue: 'Inactive'}
  ];
 

  constructor(public movementsService:MovementsService,  public snackBar: MatSnackBar, public dialog: MatDialog,) { }

  ngOnInit() {
    //this.getMovements(this.clientId, this.isActiveFilter, 1);
  }

  reset(){
    this.isActiveFilter = 1;
    this.getMovements(this.clientId, this.isActiveFilter, 1);
  }

  setPage(event) {
    console.log(event);
    this.getMovements(this.clientId, this.isActiveFilter, event.pageIndex + 1);

  }
  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {

    });
  }
  getMovements(clientId: number, isActiveFilter: number, pageNo: number){
    this.movementsService.getMovements(0, clientId ,isActiveFilter , 0, 0, 1, 'DESC', pageNo).subscribe(resp => {
      console.log("movement called")
      //this.listOfMovements= new MovementDetails();
      this.listOfMovements= resp.movementDetails.movements;
      this.noOfMovements = resp.movementDetails.totalCount;
    });

  }
  viewMovementDialog(movement): void {
    
          let dialogRef = this.dialog.open(ViewMovementDialogComponent, {
           width: '1000px',
           data: { movementData: movement  }
         });
         dialogRef.afterClosed().subscribe(result => {

          this.getMovements(this.clientId, this.isActiveFilter, 1);
         });
    
                 
     }
}
