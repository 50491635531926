
import {throwError as observableThrowError,  Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';


import 'rxjs/Observable';



@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    public token: string;
    constructor(){
    this.token = localStorage.getItem('Token');

   
    }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (localStorage.getItem("Token") === null) {
      const authReq = req.clone({
        
       });
       return next.handle(authReq);
    }else{
      this.token = localStorage.getItem('Token');
      const authReq = req.clone({
          headers: req.headers.set('Token', this.token)
        });
        return next.handle(authReq)
        .catch((err: any, caught) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 403) {
              console.info('err.error =', err.error, ';');
            }
            return observableThrowError(err);
          }
        });;
    }
    
      //if (req.method === 'GET') {
        //console.log("intercept called " + req.method );
        
        
     // }
      //console.log("intercept called " + req.method );
      //return next.handle(authReq);
  }
}