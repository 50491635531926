export class OfficeData{
  errors: any[] | null;
  officeDetails: OfficeDetails | null;
}
export class OfficeDetails{
  offices:  Offices[];
  totalCount: number;
}
export class Offices{
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  country: string;
  createdBy: string;
  createdById: number;
  createdDate: string;
  emailAddress: string;
  isActive: boolean;
  isHeadOffice: boolean = false;
  isOutOfHours: boolean = false;
  lastUpdatedBy: string | null;
  lastUpdatedById: number | null;
  lastUpdatedDate: string | null;
  name: string;
  officeId: number;
  ownerId: number;
  phoneNo: string;
  postCode: string;
  referenceNo: string;
  totalCount: number;
  townCity: string;
  isSelected?: boolean = false;
  isUseSSL: boolean;
  port: string | null;
  serverName: string | null;
  userName: string;
  password: string;
  smsAccountPassword: string;
  smsAccountUserName: string;
  smsSubAccountName: string;
  ownerUser:any;
}
export class Office{
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  country: string;
  createdBy: string;
  createdById: number;
  createdDate: string; 
  emailAddress: string;
  isActive: boolean;
  isHeadOffice: boolean = false;
  isOutOfHours: boolean = false;
  lastUpdatedBy: string | null;
  lastUpdatedById: number | null;
  lastUpdatedDate: string | null;
  name: string;
  officeId: number;
  ownerId: number;
  phoneNo: string;
  postCode: string;
  referenceNo: string;
  totalCount: number;
  townCity: string;
  isSelected?: boolean = false;
  isUseSSL: boolean;
  port: string | null;
  serverName: string | null;
  userName: string;
  password: string;
  smsAccountPassword: string;
  smsAccountUserName: string;
  smsSubAccountName: string;
  ownerUser:any;
}