import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserSessionService } from '../../services/user-session.service';
import { LoginService } from '../../services/login.service';
import { EmailValidator } from '@angular/forms';
import { AppSettings } from '../../app-settings';

import { MeData, Me, LoginDetails } from '../../models/meData';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, UserSessionService]
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  error = '';
  token: string;
  email: string;
  password: string;
  isTimeout: number = 0;
  sub: any;
  showLogin: boolean = true;
  newLogin: LoginDetails;
  rForm: FormGroup;
  rForm2: FormGroup;
  env: string;


  constructor(private router: Router, private route: ActivatedRoute,
    private loginService: LoginService, public snackBar: MatSnackBar, private fb: FormBuilder, public userSessionService: UserSessionService) {


    this.token = localStorage.getItem('Token');
    console.log('AuthGuard#canActivate called token ' + this.token);


    this.rForm = fb.group({

      'email': [null, Validators.required],
      'password': [null, Validators.required]

    });

    this.rForm2 = fb.group({

      'email': [null, Validators.required]

    });

  }

  ngOnInit() {
    if(AppSettings.API_ENDPOINT === 'https://dev.kbperfecta.com/api/Kendcomm'){
      this.env = "DEV";
    }else if(AppSettings.API_ENDPOINT === 'https://uat.kbperfecta.com/api/Kendcomm'){
      this.env = "UAT";
    }else if(AppSettings.API_ENDPOINT === 'https://kbperfecta.com/api/Kendcomm'){
      this.env = "";
    }


    this.route.queryParams.subscribe((params: Params) => {
      this.isTimeout = params["timeout"] || 0;
      console.log(this.isTimeout);
      if (this.isTimeout === 1) {
        this.openSnackBar("Your session has timeout.");
      }
      if (this.isTimeout === 2) {
        this.openSnackBar("You are not logged in.");

      }
    })



    this.newLogin = new LoginDetails();

    localStorage.removeItem('Token');
    localStorage.removeItem('userId');
    localStorage.removeItem('roles');
    localStorage.removeItem('officeId');
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 3500,
    });
  }

  login(post) {
    this.loading = true;

    this.loginService.login(this.newLogin).subscribe(resp => {
     

      if (resp.errors != null) {
        this.openSnackBar(resp.errors);
        this.showLogin = true;
        this.loading = false;
      } else {

        localStorage.setItem('Token', resp.token);
        this.getMe();
      }

    });

  }
  private getMe() {

    this.userSessionService.getMe().subscribe(resp => {
      
      this.router.navigate(['/messages']);
      this.loading = false;
    });
  }

  ngOnDestroy() {
    //this.sub.unsubscribe();
  }

  switchLogin() {
    if (this.showLogin) {
      this.showLogin = false;
    } else {
      this.showLogin = true;
    }

  }

  reset(post) {
    console.log(post);
    this.loginService.forgottenPassword(post.email).subscribe(resp => {

      if (!resp.success) {
        //this.showLogin = true;
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.showLogin = true;
        this.openSnackBar("Password reset requested, please check your email");
        this.rForm2.reset();
      }

    });
  }


}
