import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';

import { ViewNoticeDialogComponent } from '../../components/view-notice-dialog/view-notice-dialog.component';

import { EndOfDayDialogComponent } from '../../components/end-of-day-dialog/end-of-day-dialog.component';

import { NoticeData, NoticeDetails, NoticeDetail } from '../../models/noticeData';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css'],
  providers: [OfficesUsersRolesService]
})
export class NoticesComponent implements OnInit {
  listOfNotice: NoticeDetail[] = [];
  officeId: number = 0;

  isActiveFilter:number = 1;
  activeStates = [
    {value: 0, viewValue: 'All'},
    {value: 1, viewValue: 'Active'},
    {value: 2, viewValue: 'Inactive'}
  ];

  constructor(public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {

    //this.getNotices(this.officeId, this.isActiveFilter);
  }


  sendEndOfDay(officeId): void {

    let dialogRef = this.dialog.open(EndOfDayDialogComponent, {
      width: '500px',
      data: { officeId: officeId}
    });
    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
    });

  }



  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
  getNotices(officeId: number, active: number) {
    this.officesUsersRolesService.getNotices(0, officeId, active).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.listOfNotice = resp.notices;
      }
    });
  }

  reset(){
    this.isActiveFilter = 1;
    this.getNotices(this.officeId, this.isActiveFilter);

  }
  viewNoticeDialog(notice): void {
    
          let dialogRef = this.dialog.open(ViewNoticeDialogComponent, {
           width: '1000px',
           data: { noticeData: notice }
         });
         dialogRef.afterClosed().subscribe(result => {
         });
                 
     }
}
