import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';

import { MovementData, MovementDetails, Movements, Movement } from '../models/movementData';


@Injectable()
export class MovementsService {

  constructor(public http: HttpClient) { }

  getMovements(movementId:number, clientId: number, isActive:number, startDate: any, endDate: any, sortId: number, sortDir: string, pageNo:number ): Observable<MovementData> {
    return this.http.get<MovementData>(AppSettings.API_ENDPOINT + "/movement/" + movementId + '/' + clientId + '/' + isActive + '/' + startDate + '/' + endDate + '/' + sortId + '/' + sortDir + '/' + pageNo );
  }
  csvMovements(movementId:number, clientId: number, isActive:number, startDate: any, endDate: any, sortId: number, sortDir: string ) {
    return this.http.get(AppSettings.API_ENDPOINT + "/csvFormovement/" + movementId + '/' + clientId + '/' + isActive + '/' + startDate + '/' + endDate + '/' + sortId + '/' + sortDir, {headers: new HttpHeaders().set('Content-Type', 'application/pdf'), responseType: 'blob'});
  }
  clientMovements(clientId: number, isActive:number, startDate: any, endDate: any): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT + "/clientMovements/" + clientId + '/' + isActive + '/' + startDate + '/' + endDate );
  }
  addMovements(movementData: any ): Observable<MovementData> {
    return this.http.post<MovementData>(AppSettings.API_ENDPOINT + "/movement/add", movementData );
  }
  updateMovements(movementId: number, movementData: any ): Observable<MovementData> {
    return this.http.post<MovementData>(AppSettings.API_ENDPOINT + "/movement/update/" + movementId, movementData );
  }

}


