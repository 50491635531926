import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';



import * as moment from 'moment';

import { MessageSend, NewMessage, MessageData, MessageDetails, Messages, MessageSearch, MessageSearchCSV, MessageEmailReport } from '../../models/messageData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';
import { Users, User } from '../../models/userData';

import { MessagesService } from '../../services/messages.service';
import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { ViewMessageDialogComponent } from '../../components/view-message-dialog/view-message-dialog.component';
import { EmailMessageReportDialogComponent } from '../../components/email-message-report-dialog/email-message-report-dialog.component';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';
import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-outbox',
  templateUrl: './outbox.component.html',
  styleUrls: ['./outbox.component.css'],
  providers: [MessagesService, ClientsContactsService, OfficesUsersRolesService, UserSessionService]
})
export class OutboxComponent implements OnInit {
  listOfMessages: MessageData;
  csvMessages: any;
  noOfMessages: number;
  filterByDate: boolean = true;
  movementFromDateFinal: any;
  movementToDateFinal: any;
  fromDate: any = '0';
  toDate: any = '0';
  deliveryTypeFilter: number = 0;
  deliveryTypes: DeliveryTypes[];
  deliveryStates = [{ value: 0, viewValue: 'Any' }];
  isActiveFilter: number = 1;
  messageSearchData: MessageSearch;
  messageSearchCSV: MessageSearchCSV;
  MessageEmailReportData: MessageEmailReport;

  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];

  statusStates = [
    { value: 0, viewValue: 'All' },
    { value: 200, viewValue: 'Success' },
    { value: 400, viewValue: 'Failed' }
  ];
  pageEvent: PageEvent;
  sortId: number = 1;
  sortDir: string = 'DESC';
  filteredClients: ClientAutocomplete[] = [];
  filteredContact: ContactAutocomplete[] = [];
  filteredUsers: User[] = [];
  statusId: number = 0;
  userId: number = 0;
  clientId: number = 0;
  contactId: number = 0;
  messageId: number = 0;
  selectedUser: number = 0;
  selectedClient: number = 0;
  selectedContact: number = 0;
  rForm: FormGroup;
  loading: boolean = true;
  pageNo: number = 0;
  messageContent: any = null;
  messageContentFinal: any = null;
  officeIdSubject: Observable<string>;
  officeId: number;
  callerName: any = null;
  callerNameFinal: any = null;
  callerPhone: any = null;
  callerPhoneFinal: any = null;
  email: any = null;
  emailFinal: any = null;
  reference: any = null;
  referenceFinal: any = null;
  company: any = null;
  companyFinal: any = null;
  listOfContacts: Contact[] = [];
  anyContact: Contact;


  constructor(public messagesService: MessagesService, public officesUsersRolesService: OfficesUsersRolesService, public userSessionService: UserSessionService, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {


    this.officeIdSubject = userSessionService.getOfficeId();

    
    this.rForm = fb.group({
      'clientAutocomplete': [null],
      'contactAutocomplete': [null],
      'userAutocomplete': [null],
      'deliveryTypeFilter': [null],
      'statusId': [null],
      'messageContent': [null],
      'fromDate': [null],
      'toDate': [null],
      'callerName': [null],
      'callerPhone': [null],
      'email': [null],
      'reference': [null],
      'company': [null],
      'filterByDate': [null],
      'contactId': new FormControl({ value: 0, disabled: false }, Validators.required),
    });


    this.rForm.controls['clientAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientAutocomplete'].value);
        if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }

      });

    this.rForm.controls['contactAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['contactAutocomplete'].value);
        if (this.rForm.controls['contactAutocomplete'].value === '' || typeof this.rForm.controls['contactAutocomplete'].value != 'string') {
          return this.filteredContact = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.rForm.controls['contactAutocomplete'].value, 0, 0, 0)
            .subscribe(res => {
              return this.filteredContact = res.contacts
            })
        }

      });

    this.rForm.controls['userAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['userAutocomplete'].value);
        if (this.rForm.controls['userAutocomplete'].value === '' || typeof this.rForm.controls['userAutocomplete'].value != 'string') {
          return this.filteredUsers = null;
        } else {
          this.officesUsersRolesService.getUsers(0, this.rForm.controls['userAutocomplete'].value, this.officeId, 0, 1, 'ASC', 1)
            .subscribe(res => {
              return this.filteredUsers = res.userDetails.users
            })
        }

      });
  }

  ngOnInit() {

    this.anyContact = new Contact();



    this.messageSearchCSV = new MessageSearchCSV();
    this.messageSearchData = new MessageSearch();
    this.MessageEmailReportData = new MessageEmailReport();
    

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));


    this.fromDate = moment().subtract(1, 'week').toDate();
    this.toDate = moment().toDate();

    this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.callerPhone, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, 1);
    this.getDeliveryTypes();
  }

  setPage(event) {
    this.pageNo = event.pageIndex;
    this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.callerPhone, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, event.pageIndex + 1);
  }

  public setRange(dateRangeChecked) {
    console.log(dateRangeChecked);

  }
  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {

    });
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }
  displayFnUsers(user: User): any {
    return user ? user.userName : user;
  }

  changeDir(sortId) {
    if (this.sortDir === 'ASC') {
      this.sortDir = 'DESC';
      this.sortId = sortId;
      this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.callerPhone, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, this.pageNo)
    } else {
      this.sortDir = 'ASC';
      this.sortId = sortId;
      this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.callerPhone, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, this.pageNo)
    }

  }

  private getDeliveryTypes() {
    this.clientsContactsService.getDeliveryType(0, 0, 1).subscribe(resp => {
      this.deliveryTypes = resp.deliveryTypes;
      this.deliveryTypes.forEach((type, i) => {

        this.deliveryStates.push({ value: i + 1, viewValue: type.name });

      });
    });

  }
  reset() {
    this.rForm.reset();
    this.statusId = 0;
    this.userId = 0;
    this.clientId = 0;
    this.contactId = 0;
    this.messageId = 0;
    this.selectedUser = 0;
    this.selectedClient = 0;
    this.selectedContact = 0;
    this.filterByDate = false;
    this.messageContent, this.messageContentFinal = '';
    this.fromDate = moment().toDate();
    this.toDate = moment().toDate();
    this.deliveryTypeFilter = 0;
    this.rForm.controls['deliveryTypeFilter'].setValue(0);
    this.rForm.controls['statusId'].setValue(0);
    this.callerName, this.callerNameFinal = '';
    this.callerPhone, this.callerPhoneFinal = '';
    this.email, this.emailFinal = '';
    this.reference, this.referenceFinal = '';
    this.company, this.companyFinal = '';


    this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.callerPhone, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, 1);
  }
  public getMessages(messageId: number, userId: number, contactId: number, clientId: number, callerName: any, callerPhone: any, email:any, reference:any, company:any, statusId: number, deliveryTypeId: number, messageContent: any, fromDate: string, toDate: string, sortId: number, sortDir: string, pageNo: number) {
    this.loading = true;
    if (this.filterByDate) {

      this.movementFromDateFinal = moment(this.fromDate).format('YYYY-MM-DD');
      this.movementToDateFinal = moment(this.toDate).format('YYYY-MM-DD');
    } else {
      this.movementFromDateFinal = '0';
      this.movementToDateFinal = '0';
    }
    console.log(messageContent);
    if (messageContent === '' || messageContent === null) {

      this.messageContentFinal = 0;
    } else {

      this.messageContentFinal = messageContent;
    }
    if (callerName === '' || callerName === null) {

      this.callerNameFinal = 0;
    } else {

      this.callerNameFinal = callerName;
    }
    if (callerPhone === '' || callerPhone === null) {

      this.callerPhoneFinal = 0;
    } else {

      this.callerPhoneFinal = callerPhone;
    }
    if (email === '' || email === null) {

      this.emailFinal = 0;
    } else {

      this.emailFinal = email;
    }
    if (reference === '' || reference === null) {

      this.referenceFinal = 0;
    } else {

      this.referenceFinal = reference;
    }
    if (company === '' || company === null) {

      this.companyFinal = 0;
    } else {

      this.companyFinal = company;
    }

    this.messageSearchData.callerName = this.callerNameFinal;
    this.messageSearchData.clientId = this.selectedClient;
    this.messageSearchData.company = this.companyFinal;
    this.messageSearchData.contactId = this.selectedContact;
    this.messageSearchData.deliveryTypeId = this.deliveryTypeFilter;
    this.messageSearchData.email = this.emailFinal;
    this.messageSearchData.fromCreatedDate =   this.movementFromDateFinal;
    this.messageSearchData.messageId = this.messageId;
    this.messageSearchData.messageSearch = this.messageContentFinal;
    this.messageSearchData.pageNo = pageNo;
    this.messageSearchData.phone = this.callerPhoneFinal;
    this.messageSearchData.reference = this.referenceFinal;
    this.messageSearchData.sortDir = this.sortDir;
    this.messageSearchData.sortId = this.sortId;
    this.messageSearchData.statusId = this.statusId;
    this.messageSearchData.toCreatedDate = this.movementToDateFinal;
    this.messageSearchData.userId = this.selectedUser;

    this.messagesService.getMessages(this.messageSearchData).subscribe(resp => {
      this.loading = false;
      if (resp.errors.length == 0) {
        this.listOfMessages = resp;
        this.noOfMessages = resp.messageDetails.totalCount;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }
    });

  }
  loadingCSV: boolean = false;
  public getMessagesCSV(messageContent: any, callerName: any, callerPhone:any, email:any, reference: any, company:any, messageId: number, userId:any, contact:any, clientId:any, statusId: number, deliveryTypeId: number, isActive: number, sortId: number, sortDir: string) {
    this.loadingCSV = true;

    if (this.filterByDate) {

      this.movementFromDateFinal = moment(this.fromDate).format('YYYY-MM-DD');
      this.movementToDateFinal = moment(this.toDate).format('YYYY-MM-DD');
    } else {
      this.movementFromDateFinal = '0';
      this.movementToDateFinal = '0';
    }
    console.log(this.movementToDateFinal);
    if (messageContent === '' || messageContent === null) {

      this.messageContentFinal = 0;
    } else {

      this.messageContentFinal = messageContent;
    }
    if (callerName === '' || callerName === null) {

      this.callerNameFinal = 0;
    } else {

      this.callerNameFinal = callerName;
    }
    if (callerPhone === '' || callerPhone === null) {

      this.callerPhoneFinal = 0;
    } else {

      this.callerPhoneFinal = callerPhone;
    }
    if (email === '' || email === null) {

      this.emailFinal = 0;
    } else {

      this.emailFinal = email;
    }
    if (reference === '' || reference === null) {

      this.referenceFinal = 0;
    } else {

      this.referenceFinal = reference;
    }
    if (company === '' || company === null) {

      this.companyFinal = 0;
    } else {

      this.companyFinal = company;
    }

    this.messageSearchCSV.callerName = this.callerNameFinal;
    this.messageSearchCSV.clientId = this.selectedClient;
    this.messageSearchCSV.company = this.companyFinal;
    this.messageSearchCSV.contactId = this.selectedContact;
    this.messageSearchCSV.deliveryTypeId = this.deliveryTypeFilter;
    this.messageSearchCSV.email = this.emailFinal;
    this.messageSearchCSV.fromCreatedDate =   this.movementFromDateFinal;
    this.messageSearchCSV.messageId = this.messageId;
    this.messageSearchCSV.messageSearch = this.messageContentFinal;
    this.messageSearchCSV.phone = this.callerPhoneFinal;
    this.messageSearchCSV.reference = this.referenceFinal;
    this.messageSearchCSV.sortDir = this.sortDir;
    this.messageSearchCSV.sortId = this.sortId;
    this.messageSearchCSV.statusId = this.statusId;
    this.messageSearchCSV.toCreatedDate = this.movementToDateFinal;
    this.messageSearchCSV.userId = this.selectedUser;

    this.messagesService.getMessagesCSV(this.messageSearchCSV).subscribe(resp => {

      this.downloadFile(resp);

    });

  }
  setUser(user) {
    if (user) {
      if (user.userId > 0) {
        this.selectedUser = user.userId;
        this.MessageEmailReportData.userId = user.userId;
      } else {
        this.selectedUser = 0
        this.MessageEmailReportData.userId = 0
      }
    }else{
      this.selectedUser = 0;
      this.MessageEmailReportData.userId = 0
    }
  }
  setContact(contact) {
    console.log(contact.value.contactId);
    if (contact) {
      if (contact.value.contactId > 0) {
        this.selectedContact = contact.value.contactId;
        this.MessageEmailReportData.contactId = contact.value.contactId;
      } else {
        this.selectedContact = 0
        this.MessageEmailReportData.contactId = 0
      }
    }else{
      this.selectedContact = 0;
      this.MessageEmailReportData.contactId = 0
    }
  }
  setClient(client) {

    const contactField = this.rForm.get('contactId');
    console.log(client);
    if (client) {
      if (client.clientId > 0) {
        this.selectedClient = client.clientId;
        this.MessageEmailReportData.clientId = client.clientId;
        
        this.clientsContactsService.getContacts(0, 0, client.clientId, 1, 0, 0, 1, 'ASC', 0).subscribe(resp => {

          this.listOfContacts = [];

          this.anyContact.clientId = 0;
          this.anyContact.contactId = 0;
          this.anyContact.fullName = 'Any';
        
        
          this.listOfContacts.push( this.anyContact );
          
          
          this.listOfContacts = this.listOfContacts.concat(resp.contactDetails.contacts);
          console.log(this.listOfContacts[2])
          this.rForm.controls['contactId'].setValue(this.listOfContacts[0]);
          this.selectedContact = 0;
          this.MessageEmailReportData.contactId = 0;
         //this.setContact({value: { contactId: 0}})
          //contactField.patchValue({contactId: 0})
          contactField.enable();
          
        })

      } else {
        this.selectedClient = 0
        this.MessageEmailReportData.clientId = 0
      }
    }else{
      contactField.disable();
      this.selectedClient = 0
      this.MessageEmailReportData.clientId = 0
    }
  }
  downloadFile(data: any) {

    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'messages.csv');
      this.loadingCSV = false;
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'messages.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
    this.loadingCSV = false;
  }

  viewMessagetDialog(message): void {

    let dialogRef = this.dialog.open(ViewMessageDialogComponent, {
      width: '80%',
      data: { messageData: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if(result === "reload"){
        this.getMessages(this.messageId, this.userId, this.contactId, this.clientId, this.callerName, this.callerPhone, this.email, this.reference, this.company, this.statusId, this.deliveryTypeFilter, this.messageContent, this.fromDate, this.toDate, this.sortId, this.sortDir, 1);
      }
    });

  }

  emailReportDialog(messageContent: any, callerName: any, callerPhone:any, email:any, reference: any, company:any, statusId: number, deliveryTypeId: number, fromCreatedDate: any, toCreatedDate: any, filterByDate: boolean): void {

    this.MessageEmailReportData.callerName = callerName;
    this.MessageEmailReportData.messageSearch = messageContent;
    this.MessageEmailReportData.phone = callerPhone;
    this.MessageEmailReportData.email = email;
    this.MessageEmailReportData.reference = reference;
    this.MessageEmailReportData.company = company;
    this.MessageEmailReportData.statusId = statusId;
    this.MessageEmailReportData.deliveryTypeId = deliveryTypeId;
if(filterByDate){
    this.MessageEmailReportData.fromCreatedDate = fromCreatedDate;
    this.MessageEmailReportData.toCreatedDate = toCreatedDate;
}else{
  this.MessageEmailReportData.fromCreatedDate = 0;
    this.MessageEmailReportData.toCreatedDate = 0;
}
    let dialogRef = this.dialog.open(EmailMessageReportDialogComponent, {
      width: '50%',
      data: { reportData: this.MessageEmailReportData, noOfMessages: this.noOfMessages }
    });
    dialogRef.afterClosed().subscribe(result => {

     
    });

  }
}
