import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



import * as moment from 'moment';

import { MessageSend, NewMessage, MessageData, MessageDetails, Messages, MessageSearch } from '../../models/messageData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';

import { MessagesService } from '../../services/messages.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { ViewMessageDialogComponent } from '../../components/view-message-dialog/view-message-dialog.component';

@Component({
  selector: 'app-client-messages',
  templateUrl: './client-messages.component.html',
  styleUrls: ['./client-messages.component.css'],
  providers: [MessagesService, ClientsContactsService]
})
export class ClientMessagesComponent implements OnInit {
  listOfMessages: MessageData;
  noOfMessages: number;
  filterByDate: boolean = false;
  messageFromDateFinal: any;
  messageToDateFinal: any;
  fromDate: any = '0';
  toDate: any = '0';
  deliveryTypeFilter: number = 0;
  deliveryTypes: DeliveryTypes[];
  deliveryStates = [{ value: 0, viewValue: 'Any' }];
  isActiveFilter: number = 1;
  clientId: number = 0;
  pageNo: number = 1;
  messageContent: any = 0;
  messageSearchData: MessageSearch;

  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];

  constructor(public messagesService: MessagesService, private router: Router, private route: ActivatedRoute, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    //this.getMessages(this.clientId, 0, this.deliveryTypeFilter, 1);
    //this.getDeliveryTypes();

    this.messageSearchData = new MessageSearch;

    this.fromDate = moment().subtract(7, 'days').toDate();
    this.toDate = moment().toDate();
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {

    });
  }
  getDeliveryTypes() {
    this.clientsContactsService.getDeliveryType(0, 0, 1).subscribe(resp => {
      this.deliveryTypes = resp.deliveryTypes;
      this.deliveryTypes.forEach((type, i) => {

        this.deliveryStates.push({ value: i + 1, viewValue: type.name });

      });
    });

  }

  setRange(filterByDate){



  }

  setPage(event) {
    console.log(event);
    this.getMessages(this.clientId, 0, this.deliveryTypeFilter,  event.pageIndex + 1);
  }
  reset() {
    this.messageFromDateFinal = '0';
    this.messageToDateFinal = '0';
    this.isActiveFilter = 1;
    this.deliveryTypeFilter = 0;
    this.pageNo = 0;
    this.getMessages(this.clientId, 0, this.deliveryTypeFilter, 1);

  }
  public getMessages(clientId: number, statusId: number, deliveryTypeId: number, pageNo: number) {

    if (this.filterByDate) {

      this.messageFromDateFinal = moment(this.fromDate).format('YYYY-MM-DD');
      this.messageToDateFinal = moment(this.toDate).format('YYYY-MM-DD');
    } else {
      this.messageFromDateFinal = '0';
      this.messageToDateFinal = '0';
    }
    this.messageSearchData.fromCreatedDate = this.messageFromDateFinal;
    this.messageSearchData.toCreatedDate = this.messageToDateFinal;
    this.messageSearchData.clientId = clientId;
    this.messageSearchData.deliveryTypeId = this.deliveryTypeFilter;
    this.messageSearchData.pageNo = pageNo;


    this.messagesService.getMessages(this.messageSearchData).subscribe(resp => {

      if (resp.errors.length == 0) {
        this.listOfMessages = resp;
        this.noOfMessages = resp.messageDetails.totalCount;
      } else {
        this.openSnackBarError(resp.errors[0].error);
      }
    });

  }

  viewMessagetDialog(message): void {

    let dialogRef = this.dialog.open(ViewMessageDialogComponent, {
      width: '80%',
      data: { messageData: message }
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }
}
