import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';




import { TemplatesService } from '../../services/templates.service';
import { UserSessionService } from '../../services/user-session.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';

import { TemplateData, Templates, Template } from '../../models/templateData';
import { ViewTemplateDialogComponent } from '../../components/view-template-dialog/view-template-dialog.component';
import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.css'],
  providers: [TemplatesService, UserSessionService, ClientsContactsService]
})
export class TemplatesComponent implements OnInit {
  selectedClient: number = 0;
  officeIdSubject: Observable<string>;
  officeId: number;
  listOfTemplates: Templates[];
  noOfTemplates: number = 0;
  clientId: number = 0;
  filteredClients: ClientAutocomplete[] = [];
  isActiveFilter: number = 1;
  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];
  isGlobalFilter: number = 0;
  globalStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  rForm: FormGroup;
  

  constructor(public templatesService: TemplatesService, public snackBar: MatSnackBar, public dialog: MatDialog, public clientsContactsService: ClientsContactsService, public userSessionService: UserSessionService, private fb: FormBuilder) {

    this.rForm = fb.group({
      'clientAutocomplete': [null]
    });

    this.officeIdSubject = userSessionService.getOfficeId();

    this.rForm.controls['clientAutocomplete'].valueChanges
    .subscribe(name => {
      console.log(this.rForm.controls['clientAutocomplete'].value);
      if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
        return this.filteredClients = null;
      } else {
        this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 0, 0)
          .subscribe(res => {
            return this.filteredClients = res.clients
          })
      }

    });

   }

  ngOnInit() {
   
    this.officeIdSubject.subscribe(id => this.officeId = Number(id) );

    this.setClient(this.clientId)

    this.getTemplates(0, this.officeId, this.selectedClient, this.isGlobalFilter, this.isActiveFilter);
  }

  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {

    });
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  setClient(client) {
    
        if (client.clientId > 0) {
          this.selectedClient = client.clientId;
        } else {
          this.selectedClient = 0
        }
      }

  reset(){
    this.selectedClient = 0;
    this.isActiveFilter = 0;
    this.isGlobalFilter = 0;
    this.getTemplates(0, this.officeId, this.selectedClient, this.isGlobalFilter, this.isActiveFilter);

  }

  public getTemplates(templateId: number, officeId: number, clientId: number, global: number, isActive: number) {

    this.templatesService.getTemplates(templateId,'0', officeId, clientId, global, isActive).subscribe(resp => {
      
      if(resp.errors.length > 0){
        this.openSnackBarError(resp.errors[0].error);
       
      }else{
        this.listOfTemplates= resp.templates;
  
      }
    });

  }

  viewTemplatetDialog(template): void {
    
          let dialogRef = this.dialog.open(ViewTemplateDialogComponent, {
           width: '1000px',
           data: { templateData: template }
         });
         dialogRef.afterClosed().subscribe(result => {

          this.getTemplates(0, this.officeId, this.selectedClient, this.isGlobalFilter, this.isActiveFilter);
         });
        
     }
}
