import { Component, OnInit } from '@angular/core';
import { DataSource } from '@angular/cdk/collections';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { UserData, UserDetails, Users } from '../../models/userData';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  noOfUsers: any;
  officeId: number = 0;
  listOfUsers: Users[];


  constructor(public officesUsersRolesService: OfficesUsersRolesService, private router: Router, private route: ActivatedRoute, public snackBar: MatSnackBar) { }

  isActiveFilter: number = 1;
  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];



  triggerUpdate() {

    this.getUsers(this.officeId, this.isActiveFilter, 1);
  }

  ngOnInit() {
    
    console.log(this.officeId);
    //this.getUsers(this.officeId, this.isActiveFilter, 1);
  }
  //getUsers
  ////userId: number, userName:string, officeId:number, isActive:number, pageNo:number

  public getUsers(officeId: number, isActiveFilter: number, pageNo: number) {
    this.officesUsersRolesService.getUsers(0, '0', officeId, isActiveFilter, 1, 'ASC', pageNo).subscribe(resp => {
      this.noOfUsers = resp.userDetails.totalCount;
      if (resp.errors.length == 0) {
        this.listOfUsers = resp.userDetails.users;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        console.log(resp.errors[0].error);

      }
    });

  }
  setPage(event) {
    console.log(event);
    this.getUsers(this.officeId, this.isActiveFilter, event.pageIndex + 1);
  }
  reset() {
    this.isActiveFilter = 0;
    this.getUsers(this.officeId, this.isActiveFilter, 1);
  }
  resetUserPassword(user) {

    this.officesUsersRolesService.passwordReset(user.emailAddress).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.openSnackBarError("Password reset request email sent to " + user.userName);
      } else {
        this.openSnackBarError(resp.errors[0].error);
        console.log(resp.errors[0].error);

      }


    })

  }
  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {

    });
  }

}
