import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { UserSessionService } from '../../services/user-session.service';
import { InvoicingService } from '../../services/invoicing.service';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { InvoiceData, Invoices, Invoice, AutocompleteInvoice } from '../../models/invoiceData';

@Component({
  selector: 'app-invoicing',
  templateUrl: './invoicing.component.html',
  styleUrls: ['./invoicing.component.css'],
  providers: [ClientsContactsService, OfficesUsersRolesService, UserSessionService, InvoicingService]
})

export class InvoicingComponent implements OnInit {
  length = 100;
  pageSize = 10;
  pageSizeOptions = [5, 10, 25, 100];
  filteredUsers: ClientsAutocomplete[];
  selectedOption: ClientAutocomplete;
  filteredInvoices: Invoice[] = [];
  listOfInvoicesDraft: Invoice[] = [];
  listOfInvoicesEmail: Invoice[] = [];
  listOfInvoicesSage: Invoice[] = [];
  listOfInvoicesCompleted: Invoice[] = [];
  noOfInvoicesDraft: number = 0;
  noOfInvoicesEmail: number = 0;
  noOfInvoicesSage: number = 0;
  noOfInvoicesCompleted: number = 0;
  options = [];
  selectedClient: Client;
  sourceTypes: SourceTypes[];
  paymentTypes: PaymentTypes[];
  taxCodes: TaxCodes[];
  listOfSourceTypes: SourceTypes[];
  listOfOffices: OfficeData;
  isActiveFilter: number = 1;
  isOutOfHoursFilter: number = 0;
  isTrialFilter: number = 0;
  isMasterFilter: number = 0;
  isVatFilter: number = 0;
  paymentFilter: number = 0;
  sourceFilter: number = 0;
  taxFilter: number = 0;
  officeSelected: any;
  paymentTypes2: PaymentTypes[];
  taxCodes2: TaxCodes[];
  officeIdSubject: Observable<string>;
  officeId: number;
  sortIdDraft: number = 3;
  sortDirDraft: string = 'DESC';
  sortIdEmail: number = 3;
  sortDirEmail: string = 'DESC';
  sortIdSage: number = 3;
  sortDirSage: string = 'DESC';
  sortIdCompleted: number = 3;
  sortDirCompleted: string = 'DESC';
  pageNoDraft: number = 0;
  pageNoEmail: number = 0;
  pageNoSage: number = 0;
  pageNoCompleted: number = 0;
  rForm: FormGroup;
  invoiceId: number = 0;
  loadingCompletedInvoices: boolean = true;


  activeStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];
  hourStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  trialStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  masterStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  vatStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Yes' },
    { value: 2, viewValue: 'No' }
  ];
  paymentStates = [
    { value: 0, viewValue: 'Any' }
  ];
  sourceStates = [
    { value: 0, viewValue: 'Any' }
  ];
  taxStates = [
    { value: 0, viewValue: 'Any' }
  ];

  // MdPaginator Output
  pageEvent: PageEvent;

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  @Input() officeFilterId: Number = 0;
  listOfClients: Clients[];
  totalNoOfClients: any;
  constructor(private route: ActivatedRoute, public clientsContactsService: ClientsContactsService, public invoicingService: InvoicingService, public userSessionService: UserSessionService, public router: Router, public officesUsersRolesService: OfficesUsersRolesService, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {

    this.rForm = fb.group({
      'invoiceAutocomplete': [null],
    });

    this.rForm.controls['invoiceAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['invoiceAutocomplete'].value);
        if (this.rForm.controls['invoiceAutocomplete'].value === '' || typeof this.rForm.controls['invoiceAutocomplete'].value != 'string') {
          return this.filteredInvoices = null;
        } else {
          this.invoicingService.searchInvoice(this.rForm.controls['invoiceAutocomplete'].value)
            .subscribe(res => {
              return this.filteredInvoices = res.invoices
            })
        }
      });
    this.officeIdSubject = userSessionService.getOfficeId();
  }

  myControl: FormControl = new FormControl();
  contactControl: FormControl = new FormControl();
  filteredOptions: Observable<ClientAutocomplete[]>;

  filter(clientName: string): ClientAutocomplete[] {

    this.clientsContactsService.getClientsAutocomplete(clientName, 0, this.officeId, 0, 0).subscribe(resp => {
      this.options = [];
      //this.filteredUsers = resp.userDetails.users;

      resp.clients.forEach((clients) => {

        let client = new ClientAutocomplete();
        client = clients;
        this.options.push(client);
      });

      console.log("here " + clientName);
    });

    return this.options.filter(option =>
      option.clientName.toLowerCase().indexOf(clientName.toLowerCase()) === 0);

  }

  displayFn(user: ClientAutocomplete): string {
    this.selectedOption = user;
    console.log(this.selectedOption);
    return user ? user.clientName : '';
  }
  displayFnInvoices(invoice: Invoice): any {
    return invoice ? invoice.invoiceNo : invoice;
  }

  changeDirDraft(sortIdDraft) {
    if (this.sortDirDraft === 'ASC') {
      this.sortDirDraft = 'DESC';
      this.sortIdDraft = sortIdDraft;
      this.getDraftInvoices(this.sortIdDraft, this.sortDirDraft, this.pageNoDraft);
    } else {
      this.sortDirDraft = 'ASC';
      this.sortIdDraft = sortIdDraft;
      this.getDraftInvoices(this.sortIdDraft, this.sortDirDraft, this.pageNoDraft);
    }
  }

  changeDirEmail(sortIdEmail) {
    if (this.sortDirEmail === 'ASC') {
      this.sortDirEmail = 'DESC';
      this.sortIdEmail = sortIdEmail;
      this.getToBeEmailedInvoices(this.sortIdEmail, this.sortDirEmail, this.pageNoEmail);
    } else {
      this.sortDirEmail = 'ASC';
      this.sortIdEmail = sortIdEmail;
      this.getToBeEmailedInvoices(this.sortIdEmail, this.sortDirEmail, this.pageNoEmail);
    }
  }
  changeDirSage(sortIdSage) {
    if (this.sortDirSage === 'ASC') {
      this.sortDirSage = 'DESC';
      this.sortIdSage = sortIdSage;
      this.getSageInvoices(this.sortIdSage, this.sortDirSage, this.pageNoSage);
    } else {
      this.sortDirSage = 'ASC';
      this.sortIdSage = sortIdSage;
      this.getSageInvoices(this.sortIdSage, this.sortDirSage, this.pageNoSage);
    }
  }
  changeDirCompleted(sortIdCompleted) {
    if (this.sortDirCompleted === 'ASC') {
      this.sortDirCompleted = 'DESC';
      this.sortIdCompleted = sortIdCompleted;
      this.getCompletedInvoices(this.sortIdCompleted, this.sortDirCompleted, this.pageNoCompleted);
    } else {
      this.sortDirCompleted = 'ASC';
      this.sortIdCompleted = sortIdCompleted;
      this.getCompletedInvoices(this.sortIdCompleted, this.sortDirCompleted, this.pageNoCompleted);
    }
  }
  ngOnInit() {
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));
    this.getOffice(this.officeId);
    this.paymentTypes = new Array();
    this.getDraftInvoices(this.sortIdDraft, this.sortDirDraft, this.pageNoDraft);
    this.getToBeEmailedInvoices(this.sortIdEmail, this.sortDirEmail, this.pageNoEmail);
    this.getSageInvoices(this.sortIdSage, this.sortDirSage, this.pageNoSage);
    this.getCompletedInvoices(this.sortIdSage, this.sortDirSage, this.pageNoCompleted);

    this.filteredOptions = this.myControl.valueChanges
      .startWith(null)
      .map(user => user && typeof user === 'object' ? user.userName : user)
      .map(userName => userName ? this.filter(userName) : this.options.slice());

  }

  setPageDraft(event) {
    this.pageNoDraft = event.pageIndex;
    this.getDraftInvoices(this.sortIdDraft, this.sortDirDraft, event.pageIndex + 1);
  }

  setPageEmail(event) {
    this.pageNoEmail = event.pageIndex;
    this.getToBeEmailedInvoices(this.sortIdDraft, this.sortDirDraft, event.pageIndex + 1);
  }
  
  setPageSage(event) {
    this.pageNoSage = event.pageIndex;
    this.getSageInvoices(this.sortIdDraft, this.sortDirDraft, event.pageIndex + 1);
  }
  setPageCompleted(event) {
    this.pageNoCompleted = event.pageIndex;
    this.getCompletedInvoices(this.sortIdDraft, this.sortDirDraft, event.pageIndex + 1);
  }

  downloadAll(){

    this.invoicingService.downloadAllInvoices().subscribe(resp => {

      this.downloadPDFFile(resp);

    });

  }

  getDraftInvoices(sortById: number, SortDir: any, PageNo: number) {

    this.invoicingService.getInvoices(0, 0, this.officeId, 0, 1, 0, sortById, SortDir, PageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfInvoicesDraft = resp.invoices.invoices;
        this.noOfInvoicesDraft = resp.invoices.totalCount;
      }

    });
  }

  getToBeEmailedInvoices(sortById: number, SortDir: any, pageNo: number) {

    this.invoicingService.getInvoices(0, 0, this.officeId, 0, 2, 0, sortById, SortDir, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfInvoicesEmail = resp.invoices.invoices;
        this.noOfInvoicesEmail = resp.invoices.totalCount;
      }

    });
  }
  getSageInvoices(sortById: number, SortDir: any, pageNo: number) {

    this.invoicingService.getInvoices(0, 0, this.officeId, 0, 3, 0, sortById, SortDir, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfInvoicesSage = resp.invoices.invoices;
        this.noOfInvoicesSage = resp.invoices.totalCount;
      }
    });
  }

  getCompletedInvoices(sortById: number, SortDir: any, pageNo: number) {
    this.loadingCompletedInvoices = true;
    this.invoicingService.getInvoices(0, 0, this.officeId, 0, 4, 0, sortById, SortDir, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
        this.loadingCompletedInvoices = false;
      } else {
        this.listOfInvoicesCompleted = resp.invoices.invoices;
        this.noOfInvoicesCompleted = resp.invoices.totalCount;
        this.loadingCompletedInvoices = false;
      }
    });
  }


  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {

    if (tabChangeEvent.index === 3) {
      this.getCompletedInvoices(this.sortIdSage, this.sortDirSage, 1);
    }

  }


  getOffice(officeId: number) {
    this.officesUsersRolesService.getOffices(officeId, '0', 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.officeSelected = resp.officeDetails.offices[0];
      }
    
    });

  }

  goToInvoice(invoice) {
    if (invoice.invoiceId > 0) {
      this.router.navigate(['/invoicing/invoice/' + invoice.invoiceId]);
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  sageExport() {

    this.invoicingService.exportToSage(0).subscribe(resp => {

      this.downloadFile(resp);

    })
  }

  completeAll(){

    this.invoicingService.completeInvoice(0).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Invoices completed");
        this.getSageInvoices(this.sortIdSage, this.sortDirSage, 1);
      }

    })

  }

  downloadFile(data: any) {

    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'data.csv');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'data.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);

  }

  downloadPDFFile(data: any) {
    
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'data.pdf');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'data.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);

  }
}
