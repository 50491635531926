import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';


import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, SubAccountData } from '../models/clientData';

import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../models/contactData';
import { SourceTypeData, SourceTypes, SourceType } from '../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../models/taxData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../models/deliveryData';
import { BillingData, Billings } from '../models/billingData';
import { eodData } from '../models/endOfDayClient';

@Injectable()
export class ClientsContactsService {

  constructor(public http: HttpClient) { }
  getClients(clientId: number, clientName: any, clientAcroynm:any, officeId: number, isActive:number, isTrial: number, isMasterAccount: number, isOutOfHours: number, isVatExempt: number, paymentTypeId: number, sourceId: number, taxCodeId: number, sortId: number, sortDir: string, pageNo:number ): Observable<ClientData> {
    return this.http.get<ClientData>(AppSettings.API_ENDPOINT + "/client/" + clientId + '/' + clientName + '/' + clientAcroynm + '/' + officeId + '/' + isActive + '/' + isTrial + '/' + isMasterAccount + '/' + isOutOfHours + '/' + isVatExempt + '/' + paymentTypeId + '/' + sourceId + '/' + taxCodeId  + '/' + sortId + '/' + sortDir + '/' +  pageNo);
  }
  getContacts(contactId: number, contactName: any, clientId:number, isActive:number, isPrinciple: number, isDefaultContact: number, sortId:number, sortDir: string, pageNo:number ): Observable<ContactData> {
    return this.http.get<ContactData>(AppSettings.API_ENDPOINT + "/contact/" + contactId + '/' + contactName + '/' + clientId + '/' + isActive + '/' + isPrinciple + '/' + isDefaultContact + '/' + sortId + '/' + sortDir + '/' + pageNo);
  }
  getClientsAutocomplete(clientName: any, clientId: number, officeId: number, isMasterAccount: number,  isActive: number): Observable<ClientsAutocomplete> {
    return this.http.get<ClientsAutocomplete>(AppSettings.API_ENDPOINT + "/client/" + clientName + '/' + clientId + '/' + officeId + '/' + isMasterAccount + '/' + isActive);
  }
  getContactsAutocomplete(contactName: any, contactId: number, clientId: number, isActive:number): Observable<ContactsAutocomplete> {
    return this.http.get<ContactsAutocomplete>(AppSettings.API_ENDPOINT + "/contact/" + contactName + '/' + contactId + '/' + clientId + '/' + isActive);
  }
  updateClient(clientId: number, officeIds:string, clientData: any): Observable<ClientData> {
    return this.http.post<ClientData>(AppSettings.API_ENDPOINT + "/client/update/" + clientId  + '/' + officeIds, clientData);
  }
  addClient(officeIds:string, clientData: any): Observable<ClientData> {
    return this.http.post<ClientData>(AppSettings.API_ENDPOINT + "/client/add/" + officeIds, clientData);
  }
  updateContact(contactId: number, contactData: any): Observable<ContactData> {
    return this.http.post<ContactData>(AppSettings.API_ENDPOINT + "/contact/update/" + contactId, contactData);
  }
  addContact(contactData: any): Observable<ContactData> {
    return this.http.post<ContactData>(AppSettings.API_ENDPOINT + "/contact/add", contactData);
  }
  getSourceType(sourceTypeId: number, name: any, isActive:number): Observable<SourceTypeData> {
    return this.http.get<SourceTypeData>(AppSettings.API_ENDPOINT + "/sourceType/" + sourceTypeId + '/' + name + '/' + isActive);
  }
  getPaymentType(paymentTypeId: number, name: any, isActive:number): Observable<PaymentTypesData> {
    return this.http.get<PaymentTypesData>(AppSettings.API_ENDPOINT + "/paymentType/" + paymentTypeId + '/' + name + '/' + isActive);
  }
  getTaxCode(taxId: number, code: any, isActive:number): Observable<TaxData> {
    return this.http.get<TaxData>(AppSettings.API_ENDPOINT + "/taxCode/" + taxId + '/' + code + '/' + isActive);
  }
  getDeliveryType(deliveryTypeId: number, name: any, isActive:number): Observable<DeliveryData> {
    return this.http.get<DeliveryData>(AppSettings.API_ENDPOINT + "/deliveryType/" + deliveryTypeId + '/' + name + '/' + isActive);
  }
  getBillingFrequency(): Observable<BillingData> {
    return this.http.get<BillingData>(AppSettings.API_ENDPOINT + "/billingFrequency");
  }
  getClientSubAccounts(clientId: number): Observable<SubAccountData> {
    return this.http.get<SubAccountData>(AppSettings.API_ENDPOINT + "/clientSubAccounts/" + clientId);
  }

  endOfDelivery(eodData: any): Observable<eodData> {
    return this.http.post<eodData>(AppSettings.API_ENDPOINT + "/endOfDeliveryForClient", eodData);
  }




}
