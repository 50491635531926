import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';




import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { OfficeData, OfficeDetails, Offices } from '../../models/officeData';
import { NoticeData, NoticeDetails, NoticeDetail } from '../../models/noticeData';

import { AddOfficeDialogComponent } from '../../components/add-office-dialog/add-office-dialog.component';




@Component({
  selector: 'app-offices',
  templateUrl: './offices.component.html',
  styleUrls: ['./offices.component.css'],
  providers: [OfficesUsersRolesService]
})
export class OfficesComponent implements OnInit {
  listOfOffices: Offices[];
  noOfOffices: any;
  listOfNotice: NoticeDetail[] = [];

  isActiveFilter: number = 1;
  activeStates = [
    { value: 0, viewValue: 'All' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];

  constructor(public officesUsersRolesService: OfficesUsersRolesService, public dialog: MatDialog, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getOffices(this.isActiveFilter);


  }
  displayedColumns = [
    'name',
    'isOutOfHours',
    'referenceNo',
    'phoneNo'
  ];

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getOffices(isActive: number) {
    this.officesUsersRolesService.getOffices(0, '0', isActive, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.noOfOffices = resp.officeDetails.totalCount;
        this.listOfOffices = resp.officeDetails.offices;
      }
    });
  }

 

  reset() {
    this.isActiveFilter = 1;
    this.getOffices(this.isActiveFilter);
  }

  addOfficeDialog(): void {

    let dialogRef = this.dialog.open(AddOfficeDialogComponent, {
      width: '1000px'
    });
    dialogRef.afterClosed().subscribe(result => {

      this.getOffices(this.isActiveFilter);
    });
      
  }

}

