import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';

import { TemplateData, Templates, Template } from '../models/templateData';

@Injectable()
export class TemplatesService {

  constructor(public http: HttpClient) { }


  getTemplates(templateId:number, messageName: string, officeId: number, clientId: number, isGlobal:number,  isActive:number): Observable<TemplateData> {
    return this.http.get<TemplateData>(AppSettings.API_ENDPOINT + "/messageTemplate/" + templateId + '/' + messageName + '/' + officeId + '/' + clientId + '/' + isGlobal + '/' + isActive );
  }
  addTemplates(templateData: any): Observable<TemplateData> {
    return this.http.post<TemplateData>(AppSettings.API_ENDPOINT + "/messageTemplate/add", templateData);
  }
  updateTemplates(templateId: number, templateData: any): Observable<TemplateData> {
    return this.http.post<TemplateData>(AppSettings.API_ENDPOINT + "/messageTemplate/update/" + templateId, templateData);
  }
}
