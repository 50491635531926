import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { LoginService } from '../../services/login.service';

import { PasswordData } from '../../models/passwordData';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css']
})
export class ResetComponent implements OnInit {
  rForm: FormGroup;
  userId: number;
  passwordData: PasswordData;
  loading: boolean = false;


  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, public snackBar: MatSnackBar, public loginService: LoginService) {

    this.rForm = fb.group({

      'password': [{ value: '' }, Validators.required],
      'confirmPassword': [{ value: '' }, Validators.required],
    }
      , { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') });

  }

  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {

    return (group: FormGroup) => {

      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];

      if (passwordInput.value === passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors(null);
      }
      else {
        return passwordConfirmationInput.setErrors({ notEquivalent: true })

      }

    }
  }

  ngOnInit() {

    this.passwordData = new PasswordData();

    this.route.queryParams.subscribe((params: Params) => {
      this.userId = params["id"] || 0;

    })

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 25000,
    });
  }
  resetPassword(post) {

    this.loginService.changePasswordWithEncryptedUserId(this.userId, this.passwordData).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.router.navigate(['/login']);
        this.openSnackBar("Password updated");
      }

    });

  }

}
