export class MovementData{
    errors: any[] | null;
    movementDetails: MovementDetails | null;
  }
  export class MovementDetails{
    movements:  Movements[];
    totalCount: number;
  }
  export class Movements{
    clientId: number;
    clientName: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    endDate: string;
    forwardTo: string;
    forwardToId: number;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    movementId: number;
    notes: string;
    outOfOffice: string;
    outOfOfficeId: number;
    startDate: string;
    totalCount: number;
  }
  export class Movement{
    clientId: number;
    clientName: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    endDate: string;
    forwardTo: string;
    forwardToId: number;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    movementId: number;
    notes: string;
    outOfOffice: string;
    outOfOfficeId: number;
    startDate: string;
    totalCount: number;
  }