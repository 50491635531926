export class UserRolesData{
    errors: any[] | null;
    userRoles: UserRoles[] | null;
}
export class UserRoles{
    roles:  RolesActive[] | null;
    userId: number;
    userName: string;
}
export class RolesActive{
    name: string; 
    roleId: number;
}