import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { ClientsContactsService } from '../../services/clients-contacts.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData } from '../../models/invoiceData';

import { PaymentTypesData, PaymentTypes, PaymentType} from '../../models/paymentData';


@Component({
  selector: 'app-change-invoice-payment-type-dialog',
  templateUrl: './change-invoice-payment-type-dialog.component.html',
  styleUrls: ['./change-invoice-payment-type-dialog.component.css'],
  providers: [InvoicingService, ClientsContactsService]
})
export class ChangeInvoicePaymentTypeDialogComponent implements OnInit {
  rForm: FormGroup;
  invoiceData: Invoice;
  paymentTypeId: number = 0;
  listOfPaymentTypes: PaymentType[] = []; 
  loading: boolean = false;
  permanent: boolean = false;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ChangeInvoicePaymentTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar) {
    this.rForm = fb.group({
      'paymentTypeId': ['', Validators.required],
      'permanent': [true]
    });
   }

  ngOnInit() {

    this.invoiceData = this.data.invoice;
    this.rForm.controls['paymentTypeId'].setValue(this.invoiceData.paymentTypeId);

    this.paymentTypeId = this.invoiceData.paymentTypeId;
    
    this.getPaymentTypes();
  }

  getPaymentTypes(){

    this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        
        this.listOfPaymentTypes = resp.paymentTypes;
      }
    })


  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  changePayment(post){
this.loading = true;

let perm = 0;

if(this.permanent){
  perm = 1;
}else{
  perm =0;
}

    this.invoicingService.changeInvoicePaymentType(this.invoiceData.invoiceId, this.paymentTypeId, perm).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.openSnackBar("Invoice payment type updated");
        this.loading = false;
        this.dialogRef.close();

      }
    })

  }

}
