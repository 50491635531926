export class MessageSend {
    errors: any[] | null;
    messageId: number | null;

}
export class NewMessage {
    dateTaken: any;
    contactId: number;
    clientId: number;
    message: string = '';
    messageFor: string;
    messageTypeId: number;
    callerName: string = '';
    phoneNo: string = '';
    email: string = '';
    reference: string = '';
    company: string = '';
    isEod: boolean;
    deliveryTypeId: number = 0;

}

export class MessageData {
    errors: any[] | null;
    messageDetails: MessageDetails | null;

}
export class MessageDetails {
    messages: Messages[] | null;
    totalCount: number;

}
export class Messages {
    callerId: number;
    callerName: string;
    clientId: number;
    clientName: string;
    company: string;
    contactId: number;
    contactName: string;
    createdDate: any;
    dateTaken: any;
    deliveryDate: string;
    deliveryTypeId: number;
    deliveryTypeName: string;
    email: string;
    isActive: boolean;
    isEod: boolean;
    journalDate: string;
    journalUserId: number;
    message: string;
    messageFor: string;
    messageId: number;
    messageTypeId: number;
    messageTypeName: string;
    phoneNo: string;
    reference: string;
    statusId: number;
    statusName: string;
    totalCount: number;
    userId: number;
    userName: string;
    officeName: string;
    individualMessageStatus: IndividualMessageStatus[];
    responseMessage: string;
}

export class IndividualMessageStatus {
    deliveryTypeName: string;
    statusId: number;
    statusName: string;
}

export class MessageSearch {
    messageId: number = 0;
	userId: number = 0;
	contactId: number = 0;
	clientId: number = 0;
	callerName: any = 0;
	phone: any = 0;
	email: any = 0;
	reference: any = 0;
	company: any = 0;
	statusId: number = 0;
	deliveryTypeId: number = 0;
	messageSearch: any = 0;
	fromCreatedDate: any = 0;
	toCreatedDate: any = 0;
	isActive: number = 1;
	sortId: number = 1;
	sortDir: string = "DESC";
	pageNo: number = 0;


}
export class MessageSearchCSV {
    messageId: number = 0;
	userId: number = 0;
	contactId: number = 0;
	clientId: number = 0;
	callerName: any = 0;
	phone: any = 0;
	email: any = 0;
	reference: any = 0;
	company: any = 0;
	statusId: number = 0;
	deliveryTypeId: number = 0;
	messageSearch: any = 0;
	fromCreatedDate: any = 0;
	toCreatedDate: any = 0;
	isActive: number = 1;
	sortId: number = 1;
	sortDir: string = "DESC";
}

export class MessageEmailReport {
	userId: number = 0;
	contactId: number = 0;
	clientId: number = 0;
	callerName: any = 0;
	phone: any = 0;
	email: any = 0;
	reference: any = 0;
	company: any = 0;
	statusId: number = 0;
    deliveryTypeId: number = 0;
    messageSearch: any = 0;
	message: string = '';
	fromCreatedDate: any = 0;
	toCreatedDate: any = 0;
	isActive: number = 1;
}