import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
//import { MatDialog, MatDialogRef, MatTabChangeEvent } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy, ParamMap} from '@angular/router';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';

import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { UserData, UserDetails, Users, User } from '../../models/userData';
import { RoleData, Roles, Role } from '../../models/roleData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';

import { NoticesComponent } from '../../components/notices/notices.component';
import { TemplatesComponent } from '../../components/templates/templates.component';
import { AddUserDialogComponent } from '../../components/add-user-dialog/add-user-dialog.component';
import { AddClientDialogComponent } from '../../components/add-client-dialog/add-client-dialog.component';
import { AddTemplateDialogComponent } from '../../components/add-template-dialog/add-template-dialog.component';
import { AddNoticeDialogComponent } from '../../components/add-notice-dialog/add-notice-dialog.component';

import { UsersComponent } from '../../components/users/users.component';
import { InvoiceSettingsComponent } from '../../components/invoice-settings/invoice-settings.component';
import { NoticeData, NoticeDetails, NoticeDetail } from '../../models/noticeData';

import { AddInvoiceProductDialogComponent } from '../../components/add-invoice-product-dialog/add-invoice-product-dialog.component';
import { InvoicingProductsComponent } from '../../components/invoicing-products/invoicing-products.component';

import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-office-detail',
  templateUrl: './office-detail.component.html',
  styleUrls: ['./office-detail.component.css'],
  providers: [OfficesUsersRolesService, ClientsContactsService, InvoiceSettingsComponent, UserSessionService]
})
export class OfficeDetailComponent implements OnInit {
  listOfOffices: OfficeData;
  sub: any;
  id: number;
  rForm: FormGroup;
  post: any;
  test: any;
  updateInfo: any;
  officeSelected: Office;
  filteredUsers: User[] = [];
  selectedOption: any;
  officeOwner: any;
  showHide: boolean = true;
  public ownerId: number;
  autoCompleteValid: boolean = true;
  listOfRoles: RoleData;
  selectedTab = 0;
  param: any;
  officeId: number;
  listOfSourceTypes: SourceTypeData;
  paymentTypes: PaymentTypesData;
  taxCodes: TaxData;
  listOfNotice: NoticeDetail[] = [];
  tab: number = 1;

  @ViewChild(UsersComponent) usersComponent: UsersComponent;
  @ViewChild(TemplatesComponent) templatesComponent: TemplatesComponent;
  @ViewChild(NoticesComponent) noticesComponent: NoticesComponent;
  @ViewChild(InvoiceSettingsComponent) invoiceSettingsComponent: InvoiceSettingsComponent;
  @ViewChild(InvoicingProductsComponent) invoicingProductsComponent: InvoicingProductsComponent;


  constructor(private location: Location, public officesUsersRolesService: OfficesUsersRolesService, public userSessionService: UserSessionService, public clientsContactsService: ClientsContactsService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, public snackBar: MatSnackBar, public dialog: MatDialog) {

    route.url.subscribe((u) => {
      console.log(route.snapshot.params.officeId);
      this.getOffices(route.snapshot.params.officeId, '0', 0, 1);
    });
   /* route.params.subscribe(val => {
      // put the code from `ngOnInit` here

      this.officeId = val.officeId;
      this.getOffices(this.officeId, '0', 0, 1);
      console.log(val.officeId  + "//" + this.officeId);
    });
*/

    this.rForm = fb.group({

      'name': [null, Validators.required],
      'addressLine1': [null, Validators.required],
      'addressLine2': [null],
      'addressLine3': [null],
      'townCity': [null, Validators.required],
      'postCode': [null, Validators.required],
      'country': [null, Validators.required],
      'ownerId': [null, Validators.required],
      'phoneNo': [null, Validators.required],
      'emailAddress': [null],
      'isOutOfHours': [false],
      'isHeadOffice': [false],
      'referenceNo': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      'temp': [null],
      'ownerUser': [null],
      'isActive': [null],
      'isUseSSL': [false],
      'port': [null],
      'serverName': [null],
      'userName': [null],
      'password': [null],
      'smsAccountPassword': [null],
      'smsAccountUserName': [null],
      'smsSubAccountName': [null]

    });

    this.myControlUsers.valueChanges
      .subscribe(name => {
        console.log(this.myControlUsers.value);
        if (this.myControlUsers.value === '' || typeof this.myControlUsers.value != 'string') {
          return this.filteredUsers = null;
        } else {
          this.officesUsersRolesService.getUsers(0, this.myControlUsers.value, this.officeId, 1, 1, 'ASC', 1)
            .subscribe(res => {
              return this.filteredUsers = res.userDetails.users;
            })
        }
      });
  }

  displayFnUsers(user: User): any {
    return user ? user.userName : user;
  }


  myControlUsers: FormControl = new FormControl();
  options = [];

  filteredOptions: Observable<User[]>;

  private acceptedRoutes: string[] = ["offices/:officeId/tab/0"];

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // check to see if the route's path is in our acceptedRoutes array
    if (this.acceptedRoutes.indexOf(route.routeConfig.path) > -1) {
        console.log("detaching", route);
        return true;
    } else {
        return false; // will be "view/:resultId" when user navigates to result
    }
}

  ngOnInit() {

     this.route.params.subscribe(params => {
      console.log(params.officeId);
      this.officeId = params.officeId;
      //this.officeId = +this.route.snapshot.paramMap.get('officeId');
    });
    
    this.tab = +this.route.snapshot.paramMap.get('tabId');
    console.log(this.tab);
    this.selectedTab = this.tab;

    if (this.selectedTab === 1) {
      console.log("Users");
      this.usersComponent.officeId = this.officeId;
      this.usersComponent.getUsers(this.officeId, 1, 1);
    }
    if (this.selectedTab === 2) {
      console.log("Templates");
      this.templatesComponent.officeId = this.officeId;
      this.templatesComponent.getTemplates(0, this.officeId, 0, 0, 1);
    }
    if (this.selectedTab === 3) {
      console.log("End of Day");
      this.noticesComponent.officeId = this.officeId;
      this.noticesComponent.getNotices(this.officeId, 1);
    }

   
    this.getOffices(this.officeId, '0', 0, 1);
    this.usersComponent.officeId = this.officeId;
    this.invoiceSettingsComponent.officeId = this.officeId;

    this.noticesComponent.officeId = this.officeId;

    //let id = +this.route.snapshot.paramMap.get('id');
    this.showHide = true;

    this.officeSelected = new Office();

    this.getNotices();

  }
  checkOwner(event) {

    console.log(event);
    if (event === '') {

      this.autoCompleteValid = false;

    } else {
      this.autoCompleteValid = true;
    }
  }


  filter(userName: string, officeId: number): User[] {

    this.officesUsersRolesService.getUsers(0, userName, officeId, 1, 1, 'ASC', 1).subscribe(resp => {
      this.options = [];
      //this.filteredUsers = resp.userDetails.users;

      resp.userDetails.users.forEach((users) => {

        let user = new User();
        user = users;
        this.options.push(user);
      });

      console.log(this.options[0]);
    });
    userName = userName.toString()
    return this.options.filter(option =>
      option.userName.toLowerCase().indexOf(userName.toLowerCase()) === 0);

  }

  getOwnerUser(userId: number) {
    this.officesUsersRolesService.getUsers(userId, '0', 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.officeOwner = new User;
        this.officeOwner = resp.userDetails.users[0];
        this.myControlUsers.setValue({ userId: this.officeOwner.userId, userName: this.officeOwner.userName });

        this.officeSelected.ownerUser = resp.userDetails.users[0];

      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.selectedTab = tabChangeEvent.index;
    this.router.navigate(['/offices', this.officeId, 'tab', tabChangeEvent.index]);

    if (tabChangeEvent.index === 1) {
      console.log("Users");
      this.usersComponent.officeId = this.officeId;
      this.usersComponent.getUsers(this.officeId, 1, 1);
    }
    if (tabChangeEvent.index === 2) {
      console.log("Templates");
      this.templatesComponent.officeId = this.officeId;
      this.templatesComponent.getTemplates(0, this.officeId, 0, 0, 1);
    }
    if (tabChangeEvent.index === 3) {
      console.log("End of Day");
      this.noticesComponent.officeId = this.officeId;
      this.noticesComponent.getNotices(this.officeId, 1);
    }

  }

  setUser(user) {
    console.log(user)
    this.officeSelected.ownerId = user.userId;
    this.rForm.controls['ownerId'].setValue(user.userId);
  }

  public getUsers(userName: string, officeId: number) {
    this.officesUsersRolesService.getUsers(0, userName, officeId, 1, 1, 'ASC', 1).subscribe(resp => {

      resp.userDetails.users.forEach((users) => {
        //console.log(users);
        let user = new User();
        user = users;
        this.filteredUsers.push(user);
      });
      return this.filteredUsers;
      //console.log(this.listOfOffices);
    });

  }

  getOffices(id: number, officeName: string, isActive: number, officePageNo: number) {
    this.officesUsersRolesService.getOffices(id, officeName, isActive, officePageNo).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfOffices = resp;

        this.officeSelected = resp.officeDetails.offices[0];

      /*
        resp.officeDetails.offices.forEach((offices) => {
          //console.log(offices);

          this.officeSelected = offices;

        });
        */
        if(this.officeSelected.ownerId !=null){
        this.getOwnerUser(this.officeSelected.ownerId);
        }
      }
    });

  }
  submitted = false;


  updateOffice(post) {
    //console.log(post);

    this.officeSelected.name = post.name;
    this.submitted = true;
    // this.officeSelected.ownerId = this.officeSelected.ownerUser.userId;


    this.officesUsersRolesService.updateOffice(this.officeSelected.officeId, this.officeSelected).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Office updated");
        this.ngOnInit();
        this.updateInfo = resp;
      }

    });

  }

  getNotices() {
    this.officesUsersRolesService.getNotices(0, 0, 0).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.listOfNotice = resp.notices;
      }
    });
  }
  addClientDialog(): void {

    this.clientsContactsService.getSourceType(0, '0', 1).subscribe(resp => {

      this.listOfSourceTypes = resp;


      this.officesUsersRolesService.getOffices(0, '0', 1, 1).subscribe(resp => {
        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
        } else {
          this.listOfOffices = resp;

          this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {
            this.paymentTypes = resp;


            this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {
              this.taxCodes = resp;


              let dialogRef = this.dialog.open(AddClientDialogComponent, {
                width: '1000px',
                data: {
                  sourceData: this.listOfSourceTypes,
                  officeData: this.listOfOffices,
                  officeSelected: this.officeSelected,
                  paymentTypes: this.paymentTypes,
                  taxCode: this.taxCodes
                }
              });
              dialogRef.afterClosed().subscribe(result => {

                this.ngOnInit();
              });

            });

          });

        }
      });
    });
  }

  addUserDialog(): void {


    this.officesUsersRolesService.getRoles(0, '0', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfRoles = resp;

        let dialogRef = this.dialog.open(AddUserDialogComponent, {
          width: '1000px',
          data: { roleDataPassed: this.listOfRoles, office: this.officeSelected }
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed');
          this.selectedTab = 1;
          console.log(result);
          this.usersComponent.triggerUpdate();
        });
      }
    });
  }
  addTemplateDialog(): void {

    let dialogRef = this.dialog.open(AddTemplateDialogComponent, {
      width: '1000px',
      data: { officeId: this.officeId, clientId: 0, isGlobal: true, clientName: '' }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.templatesComponent.getTemplates(0, this.officeId, 0, 0, 1);
      //this.router.navigate(['/offices', this.officeId, 'tab', 2]);
      this.selectedTab = 2;
      console.log(result);
    });

  }

  addNoticeDialog(): void {

    let dialogRef = this.dialog.open(AddNoticeDialogComponent, {
      width: '1000px',
      data: { officeId: this.officeId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.noticesComponent.getNotices(this.officeId, 1);
      this.selectedTab = 3;
      console.log(this.selectedTab);
      this.ngOnInit();
    });

  }

  addInvoiceProductDialog(): void {

    let dialogRef = this.dialog.open(AddInvoiceProductDialogComponent, {
      width: '1000px',
      data: { office: this.officeSelected }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.selectedTab = 4;
      console.log(result);
      this.invoicingProductsComponent.getInvoicingProducts(0, 1, 1);
      //this.usersComponent.triggerUpdate();
    });

  }

} 
