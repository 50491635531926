import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { NoticeData, NoticeDetails, NoticeDetail } from '../../models/noticeData';

@Component({
  selector: 'app-add-notice-dialog',
  templateUrl: './add-notice-dialog.component.html',
  styleUrls: ['./add-notice-dialog.component.css'],
  providers: [OfficesUsersRolesService]
})
export class AddNoticeDialogComponent implements OnInit {
  selectedOfficeId: number;
  rForm: FormGroup;
newNotice: NoticeDetail;
  constructor(public dialogRef: MatDialogRef<AddNoticeDialogComponent>, public officesUsersRolesService: OfficesUsersRolesService, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar, public dialog: MatDialog) { 

    this.rForm = fb.group({
            'officeId': [null],
            'notice': [null],
            'startDate': [moment().toDate(), Validators.required],
            'reminderDate': [moment().toDate(), Validators.required],
            'endDate': [moment().add(7, 'days').toDate(), Validators.required],
            'isActive': [true]
          });
  }

  ngOnInit() {
    this.newNotice = new NoticeDetail();
    this.selectedOfficeId = this.data.officeId;
    this.newNotice.officeId = this.selectedOfficeId; 
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  addNotice(post) {
    if(moment(post.startDate).isValid()){
      this.newNotice.startDate = post.startDate;
    }
    if(moment(post.reminderDate).isValid()){
      this.newNotice.reminderDate = post.reminderDate;
    }
    if(moment(post.endDate).isValid()){
      this.newNotice.endDate = post.endDate;
    }

    this.officesUsersRolesService.addNotices(this.newNotice).subscribe(resp => {
      
          //this.addInfo = resp;
          if (resp.errors.length > 0) {
            this.openSnackBar(resp.errors[0].error);
          } else {
      
          this.openSnackBar("Notice Added");
          this.dialogRef.close();
        
          }
      });
  }
}
