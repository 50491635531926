export class TemplateData{
    errors: any[] | null;
    templates: Templates[];
  }
  export class Templates{
    clientId: number;
    clientName: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    deliveryTypeId: number;
    deliveryTypeName: string;
    isActive: boolean;
    isGlobal: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: string | null;
    lastUpdatedDate: string | null;
    message: string;
    templateId: number;
    officeId: number;
  }
  export class Template{
    clientId: number;
    clientName: string;
    createdBy: string;
    createdById: number;
    createdDate: string;
    deliveryTypeId: number;
    deliveryTypeName: string;
    isActive: boolean;
    isGlobal: boolean;
    lastUpdatedBy: string | null;
    lastUpdatedById: string | null;
    lastUpdatedDate: string | null;
    message: string;
    templateId: number;
    officeId: number;
  }