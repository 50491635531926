import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData } from '../../models/invoiceData';

@Component({
  selector: 'app-email-invoice-dialog',
  templateUrl: './email-invoice-dialog.component.html',
  styleUrls: ['./email-invoice-dialog.component.css'],
  providers: [InvoicingService]
})
export class EmailInvoiceDialogComponent implements OnInit {
  emailDetails: EmailInvoiceData;
  rForm: FormGroup;
  emailData: any;
  loading: boolean = false;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<EmailInvoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'toEmailAddress': ['', Validators.required],
      'emailMessage': [''],
      'bccEmailAddress': [''],
      'ccEmailAddress': ['']
    });
   }

  ngOnInit() {

    this.emailData = {
      toEmailAddress: '',
      emailMessage: '',
      bccEmailAddress: '',
      ccEmailAddress: '',

    }

    this.emailDetails = new EmailInvoiceData();

    this.emailData.toEmailAddress = this.data.client.invoiceToEmails;
    this.emailData.emailMessage = this.data.invoiceSettings.invoiceEmailTemplate;

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  emailInvoice(post){
    this.loading = true;
    this.invoicingService.sendInvoice(this.data.invoice.invoiceId, this.emailData).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.openSnackBar("Invoice Emailed");
        this.loading = false;
        this.dialogRef.close();
      }
    })
  }
}
