import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Router } from '@angular/router';
//import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  versionCheck :any;
 
  //constructor(public loader: LoadingBarService) {}
  constructor(private swUpdate: SwUpdate, private router: Router) {

}

   ngOnInit() {

    if (this.swUpdate.isEnabled) {

        this.swUpdate.available.subscribe(() => {

            if(confirm("New version of Kendcom is available. Load New Version?")) {

                window.location.reload();
            }
        });
    }      
      // listenging to routing navigation event
    //this.router.events.subscribe(event => this.modifyHeader(event));
    //console.log('NavigationEnd:', event);  
}
   
 
}
