import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatAutocompleteModule } from '@angular/material/autocomplete';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';
import { UserSessionService } from '../../services/user-session.service';
import { InvoicingService } from '../../services/invoicing.service';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettings, InvoiceSetting } from '../../models/invoiceData';
import { UserData, UserDetails, Users, User } from '../../models/userData';

import { AddLineItemDialogComponent } from '../../components/add-line-item-dialog/add-line-item-dialog.component';
import { ViewLineItemDialogComponent } from '../../components/view-line-item-dialog/view-line-item-dialog.component';
import { DeleteLineItemDialogComponent } from '../../components/delete-line-item-dialog/delete-line-item-dialog.component';
import { VoidInvoiceDialogComponent } from '../../components/void-invoice-dialog/void-invoice-dialog.component';
import { FinaliseInvoiceDialogComponent } from '../../components/finalise-invoice-dialog/finalise-invoice-dialog.component';
import { CompleteInvoiceDialogComponent } from '../../components/complete-invoice-dialog/complete-invoice-dialog.component';
import { EmailInvoiceDialogComponent } from '../../components/email-invoice-dialog/email-invoice-dialog.component';
import { ChangeInvoiceReferenceDialogComponent } from '../../components/change-invoice-reference-dialog/change-invoice-reference-dialog.component';
import { ChangeInvoiceDateDialogComponent } from '../../components/change-invoice-date-dialog/change-invoice-date-dialog.component';
import { ChangeInvoicePaymentTypeDialogComponent } from '../../components/change-invoice-payment-type-dialog/change-invoice-payment-type-dialog.component';
import { ChangeInvoiceTariffDialogComponent } from '../../components/change-invoice-tariff-dialog/change-invoice-tariff-dialog.component';
import { RaiseCreditNoteDialogComponent } from '../../components/raise-credit-note-dialog/raise-credit-note-dialog.component';

@Component({
  selector: 'app-invoicing-detail',
  templateUrl: './invoicing-detail.component.html',
  styleUrls: ['./invoicing-detail.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ClientsContactsService, OfficesUsersRolesService, UserSessionService, InvoicingService]
})
export class InvoicingDetailComponent implements OnInit {
  invoiceId: number = 0;
  selectedInvoice: Invoice;
  lineItems: LineItems[] = [];
  invoiceSettings: InvoiceSettings;
  officeId: number = 0;
  officeIdSubject: Observable<string>;
  clientId: number = 0;
  clientDetails: Client;
  officeDetails: Office;
  userDetails: User;

  constructor(public officesUsersRolesService: OfficesUsersRolesService, private route: ActivatedRoute, public clientsContactsService: ClientsContactsService, public invoicingService: InvoicingService, public userSessionService: UserSessionService, public router: Router, public snackBar: MatSnackBar, public dialog: MatDialog, private fb: FormBuilder) {

    this.officeIdSubject = userSessionService.getOfficeId();
  }

  ngOnInit() {

    this.selectedInvoice = new Invoice();
    this.clientDetails = new Client();
    this.officeDetails = new Office();
    this.userDetails = new User();

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.route.params.forEach(params => {
      this.invoiceId = params["id"];
      //call your function, like getUserInfo()
      this.getInvoice(this.invoiceId);
    });
    this.invoiceSettings = new InvoiceSettings();
    this.getInvoiceSettings(this.officeId)
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getUser(userId: number) {

    this.officesUsersRolesService.getUsers(userId, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.userDetails = resp.userDetails.users[0];
      }
    })

  }

  getInvoice(invoiceId: number) {

    this.invoicingService.getInvoices(invoiceId, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.selectedInvoice = resp.invoices.invoices[0];
        this.lineItems = resp.invoices.invoices[0].lineItems;
        this.clientId = resp.invoices.invoices[0].clientId;
        this.getClient(this.clientId);
        this.getOffice(this.officeId);
      }

    })

  }

  getOffice(officeId: number) {

    this.officesUsersRolesService.getOffices(officeId, 0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.officeDetails = resp.officeDetails.offices[0];
      }


    })

  }

  getClient(clientId: number) {

    this.clientsContactsService.getClients(clientId, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.clientDetails = resp.clientDetails.clients[0];
      }

    })

  }

  getInvoiceSettings(officeId: number) {

    this.invoicingService.getInvoiceSettings(0, officeId, 0, 1).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.invoiceSettings = resp.invoiceSettings[0];

       // this.getUser(this.invoiceSettings.ownerId);

      }
    })

  }

  updateReference(selectedInvoice): void {
    
    let dialogRef = this.dialog.open(ChangeInvoiceReferenceDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  
  completeInvoiceDialog(selectedInvoice): void {
    
    let dialogRef = this.dialog.open(CompleteInvoiceDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  finaliseInvoiceDialog(selectedInvoice): void {
    
        let dialogRef = this.dialog.open(FinaliseInvoiceDialogComponent, {
          width: '500px',
          data: { invoice: selectedInvoice }
        });
        dialogRef.afterClosed().subscribe(result => {
          this.getInvoice(this.invoiceId);
    
          console.log(result);
          if(result == 'email'){
          this.emailInvoiceDialog(this.selectedInvoice);
          }
        });
    
      }

      printInvoiceDialog(selectedInvoice): void {
    
        this.router.navigate(['/print-invoice', selectedInvoice.invoiceId]);
    
      }

  voidInvoiceDialog(selectedInvoice): void {

    let dialogRef = this.dialog.open(VoidInvoiceDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  emailInvoiceDialog(selectedInvoice): void {

    let dialogRef = this.dialog.open(EmailInvoiceDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice, client: this.clientDetails, invoiceSettings: this.invoiceSettings }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }


  
  changeDatesDialog(selectedInvoice): void {

    let dialogRef = this.dialog.open(ChangeInvoiceDateDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  
  changeTariffDialog(selectedInvoice): void {

    let dialogRef = this.dialog.open(ChangeInvoiceTariffDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  
  changePaymentDialog(selectedInvoice): void {

    let dialogRef = this.dialog.open(ChangeInvoicePaymentTypeDialogComponent, {
      width: '500px',
      data: { invoice: selectedInvoice, client: this.clientDetails}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  pdfExport(invoiceId){

    this.invoicingService.downloadInvoice(invoiceId).subscribe(resp => {

      this.downloadFile(resp);

    })


  }

  downloadFile(data: any) {
    
    let parsedResponse = data;
    let blob = new Blob([parsedResponse], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'data.pdf');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'data.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);

  }


  deleteLineItemDialog(Item): void {

    let dialogRef = this.dialog.open(DeleteLineItemDialogComponent, {
      width: '500px',
      data: { lineItem: Item }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.getInvoice(this.invoiceId);
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  addCreditDialog(invoice): void {

    let dialogRef = this.dialog.open(RaiseCreditNoteDialogComponent, {
      width: '50%',
      data: { invoiceData: invoice }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.contactsComponent.getContacts(this.clientId, 1);
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  viewLineItemDialog(Item): void {

    let dialogRef = this.dialog.open(ViewLineItemDialogComponent, {
      width: '1000px',
      data: { lineItem: Item }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);

      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  addLineItemDialog(): void {

    let dialogRef = this.dialog.open(AddLineItemDialogComponent, {
      width: '1000px',
      data: { invoice: this.selectedInvoice, client: this.clientDetails }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getInvoice(this.invoiceId);
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }
}
