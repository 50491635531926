export class NoticeData{
    errors: any[] | null;
    notices: NoticeDetails[] | null;
  }
  export class NoticeDetails{
    createdBy: string;
    createdById: number;
    createdDate: string;
    endDate: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    notice: string;
    noticeId: number;
    officeId: number;
    officeName: string;
    reminderDate: string;
    startDate: string;
  }
  export class NoticeDetail{
    createdBy: string;
    createdById: number;
    createdDate: string;
    endDate: string;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: number;
    lastUpdatedDate: string;
    notice: string;
    noticeId: number;
    officeId: number;
    officeName: string;
    reminderDate: string;
    startDate: string;
  }
 