export class eodData {
    errors: any[] | null;
    userId: number = 0;
    contactId: number =  0;
    clientId: number = 0;
    callerName: number = 0;
    phone: number = 0;
    email: number = 0;
    reference: number = 0;
    company: number = 0;
    statusId: number = 0;
    deliveryTypeId: number = 0;
    message: number = 0;
    messageSearch: number = 0;
    fromCreatedDate: string;
    toCreatedDate: string;
    isActive: number = 1; 
  }
