import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import * as moment from 'moment';

import { TotalMessagesByUserAndDateData, UsersReporting } from '../../models/totalMessagesByUserAndDateData';
import { TotalMessagesByClientAndDateData, ClientsReporting } from '../../models/totalMessagesByClientAndDateData';
import { TodayTotalMessagesData, todayTotalMessages } from '../../models/todayTotalMessagesData';

import { ReportingService } from '../../services/reporting.service';
import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-message-stats',
  templateUrl: './message-stats.component.html',
  styleUrls: ['./message-stats.component.css'],
  providers: [ReportingService, UserSessionService]
})
export class MessageStatsComponent implements OnInit {

  todaysDate: any;
  dayTotal: number = 0;
  officeIdSubject: Observable<string>;
  officeId: number;

  public barChartColors:Array<any> = [
    { // grey
      backgroundColor: '#7986CB',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: '#7986CB',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }
  ];

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: false,
    layout: {
      padding: {
          left: 50,
          right: 0,
          top: 0,
          bottom: 0
      }
  },
    scales: {
      xAxes: [{
          ticks: {
              beginAtZero: true,
              stepSize: 10,
              min: 0,
          }
      }],
      
     
  }
  };
  public barChartLabels:string[] = new Array();
  public barChartLabels2:string[] = new Array();
  public barChartType:string = 'horizontalBar';
  public barChartLegend:boolean = false;

  public lineChartLabels:Array<any> = [];
  public lineChartOptions:any = {
    responsive: false,
    elements: {
      line: {
          tension: 0, // disables bezier curves
      },
    },
      scales: {
        yAxes: [{
          ticks: {
            stepSize: 10,
            min: 0,
          }
      }]
  
  }
  };
 
  public barChartData:any[] = [
    {data: '', label: 'Users'}
  ];
  public barChartData2:any[] = [
    {data: '', label: 'Clients'}
  ];

  public lineChartData:Array<any> = [
    {data: '', label: 'Messages'}
  ];

  clientReportingStats: ClientsReporting[] = [];
  userReportingStats: UsersReporting[] = [];
  todayStats: todayTotalMessages[] = [];
  userData:any[] = new Array();
  todayData:any[] = new Array();
  clientData:any[] = new Array();
  userLabel:any[] = new Array();
  clientLabel:any[] = new Array();

  public lineChartLegend:boolean = false;
  public lineChartType:string = 'line';


  constructor(public snackBar: MatSnackBar, public reportingService: ReportingService, public userSessionService: UserSessionService) {

    this.officeIdSubject = userSessionService.getOfficeId();
   }

  ngOnInit() {
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));
this.todaysDate = moment().format('YYYY-MM-DD');
    
    this.getTotalMessagesByUserAndDate();
    this.getTotalMessagesByClientAndDate();
    this.getTodayTotalMessages();
  }
  getTodayTotalMessages() {
    this.reportingService.getTodayTotalMessages(this.officeId).subscribe(resp => {
      this.todayStats = resp.messages.todayTotalMessages;
      this.dayTotal = resp.messages.messageTotal;

      this.todayStats.forEach((messages) => {
        this.todayData.push(messages.totalMessages);
        this.lineChartLabels.push(messages.timeId);
      });
      this.lineChartData = [
        {data: this.todayData, label: 'Messages'}
      ];
    });
  }
  getTotalMessagesByUserAndDate() {
    this.reportingService.getTotalMessagesByUserAndDate(0, 0, this.todaysDate, this.todaysDate).subscribe(resp => {
      this.userReportingStats = resp.users;

      this.userReportingStats.forEach((user) => {
        this.userData.push(user.totalMessages);
        this.barChartLabels.push(user.userName);
      });
      this.barChartData = [
        {data: this.userData, label: 'Users'}
      ];
    });
  }


  getTotalMessagesByClientAndDate() {
    this.reportingService.getTotalMessagesByClientAndDate(0, this.todaysDate, this.todaysDate, 10).subscribe(resp => {
      this.clientReportingStats = resp.clients;

      this.clientReportingStats.forEach((client) => {
        this.clientData.push(client.totalMessages);
        this.barChartLabels2.push(client.clientName);
      });
      this.barChartData2 = [
        {data: this.clientData, label: 'Clients'}
      ];
    });
  }

 
  // events
  public chartClicked(e:any):void {
    console.log(e);
  }
 
  public chartHovered(e:any):void {
    console.log(e);
  }
 


}
