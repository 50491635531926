import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule }    from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';

import { MomentModule } from 'ngx-moment';
import { ChartsModule } from 'ng2-charts';
import { UpdateService } from './services/update.service';

import 'moment/locale/en-gb';


import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MaterialModule} from './material-module';
import { FlexLayoutModule } from '@angular/flex-layout';
//import 'hammerjs';

import { AppComponent } from './app.component';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { LoginService } from './services/login.service';
import { JournalsService } from './services/journals.service';
import { UserSessionService } from './services/user-session.service';

import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard.service';

import { LoginComponent } from './components/login/login.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ClientsComponent } from './components/clients/clients.component';

import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { InvoicingComponent } from './components/invoicing/invoicing.component';
import { OfficesComponent } from './components/offices/offices.component';
import { AddOfficeDialogComponent } from './components/add-office-dialog/add-office-dialog.component';
import { UsersComponent } from './components/users/users.component';
import { OfficeDetailComponent } from './components/office-detail/office-detail.component';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { ClientDetailComponent } from './components/client-detail/client-detail.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { OutboxComponent } from './components/outbox/outbox.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { ContactsDetailComponent } from './components/contacts-detail/contacts-detail.component';
import { ClientMovementsComponent } from './components/client-movements/client-movements.component';
import { OfficeClientsComponent } from './components/office-clients/office-clients.component';
import { AddClientDialogComponent } from './components/add-client-dialog/add-client-dialog.component';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { AdminComponent } from './components/admin/admin.component';
import { ViewMessageDialogComponent } from './components/view-message-dialog/view-message-dialog.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { AddTemplateDialogComponent } from './components/add-template-dialog/add-template-dialog.component';
import { ClientMessagesComponent } from './components/client-messages/client-messages.component';
import { MessageStatsComponent } from './components/message-stats/message-stats.component';
import { JournalsComponent } from './components/journals/journals.component';
import { ViewTemplateDialogComponent } from './components/view-template-dialog/view-template-dialog.component';
import { AddMovementDialogComponent } from './components/add-movement-dialog/add-movement-dialog.component';
import { ViewMovementDialogComponent } from './components/view-movement-dialog/view-movement-dialog.component';
import { AddContactDialogComponent } from './components/add-contact-dialog/add-contact-dialog.component';
import { ResetComponent } from './components/reset/reset.component';

import { HidePipe } from './pipes/hide.pipe';
import { AddNoticeDialogComponent } from './components/add-notice-dialog/add-notice-dialog.component';
import { ViewNoticeDialogComponent } from './components/view-notice-dialog/view-notice-dialog.component';
import { NoticesComponent } from './components/notices/notices.component';
import { PrintMovementComponent } from './components/print-movement/print-movement.component';
import { InvoicingProductsComponent } from './components/invoicing-products/invoicing-products.component';
import { InvoicingDetailComponent } from './components/invoicing-detail/invoicing-detail.component';
import { ViewLineItemDialogComponent } from './components/view-line-item-dialog/view-line-item-dialog.component';
import { AddLineItemDialogComponent } from './components/add-line-item-dialog/add-line-item-dialog.component';
import { AddInvoiceProductDialogComponent } from './components/add-invoice-product-dialog/add-invoice-product-dialog.component';
import { DeleteLineItemDialogComponent } from './components/delete-line-item-dialog/delete-line-item-dialog.component';
import { InvoiceSettingsComponent } from './components/invoice-settings/invoice-settings.component';
import { ClientRecurringProductsComponent } from './components/client-recurring-products/client-recurring-products.component';
import { ClientInvoicingComponent } from './components/client-invoicing/client-invoicing.component';
import { AddInvoiceDialogComponent } from './components/add-invoice-dialog/add-invoice-dialog.component';
import { ViewInvoiceProductDialogComponent } from './components/view-invoice-product-dialog/view-invoice-product-dialog.component';
import { VoidInvoiceDialogComponent } from './components/void-invoice-dialog/void-invoice-dialog.component';
import { AssignRecurringProductDialogComponent } from './components/assign-recurring-product-dialog/assign-recurring-product-dialog.component';
import { ConfirmPatchDialogComponent } from './components/confirm-patch-dialog/confirm-patch-dialog.component';
import { AddCreditNoteDialogComponent } from './components/add-credit-note-dialog/add-credit-note-dialog.component';
import { FinaliseInvoiceDialogComponent } from './components/finalise-invoice-dialog/finalise-invoice-dialog.component';
import { EmailInvoiceDialogComponent } from './components/email-invoice-dialog/email-invoice-dialog.component';
import { ClientSentInvoicesComponent } from './components/client-sent-invoices/client-sent-invoices.component';
import { CompleteInvoiceDialogComponent } from './components/complete-invoice-dialog/complete-invoice-dialog.component';
import { ChangeInvoicePaymentTypeDialogComponent } from './components/change-invoice-payment-type-dialog/change-invoice-payment-type-dialog.component';
import { ChangeInvoiceTariffDialogComponent } from './components/change-invoice-tariff-dialog/change-invoice-tariff-dialog.component';
import { ChangeInvoiceReferenceDialogComponent } from './components/change-invoice-reference-dialog/change-invoice-reference-dialog.component';
import { ChangeInvoiceDateDialogComponent } from './components/change-invoice-date-dialog/change-invoice-date-dialog.component';
import { ViewContactsDialogComponent } from './components/view-contacts-dialog/view-contacts-dialog.component';
import { PrintInvoiceComponent } from './components/print-invoice/print-invoice.component';
import { EndOfDayDialogComponent } from './components/end-of-day-dialog/end-of-day-dialog.component'; 

import { CustomReuseStrategy } from './reuse-strategy';
import { ServiceWorkerModule, SwUpdate, SwPush } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { EmailMessageReportDialogComponent } from './components/email-message-report-dialog/email-message-report-dialog.component';
import { RaiseCreditNoteDialogComponent } from './components/raise-credit-note-dialog/raise-credit-note-dialog.component';
import { ClientTemplatesComponent } from './components/client-templates/client-templates.component'; 

import { MAT_DATE_LOCALE } from '@angular/material/core';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MessagesComponent,
    ClientsComponent,
    HeaderComponent,
    PageNotFoundComponent,
    HomeComponent,
    MyAccountComponent,
    InvoicingComponent,
    OfficesComponent,
    UsersComponent,
    OfficeDetailComponent,
    AddOfficeDialogComponent,
    UserDetailComponent,
    ClientDetailComponent,
    ReportingComponent,
    OutboxComponent,
    ContactsComponent,
    ContactsDetailComponent,
    ClientMovementsComponent,
    OfficeClientsComponent,
    AddClientDialogComponent,
    AddUserDialogComponent,
    AdminComponent,
    ViewMessageDialogComponent,
    TemplatesComponent,
    AddTemplateDialogComponent,
    ClientMessagesComponent,
    MessageStatsComponent,
    JournalsComponent,
    ViewTemplateDialogComponent,
    AddMovementDialogComponent,
    ViewMovementDialogComponent,
    AddContactDialogComponent,
    ResetComponent,
    HidePipe,
    AddNoticeDialogComponent,
    ViewNoticeDialogComponent,
    NoticesComponent,
    PrintMovementComponent,
    InvoicingProductsComponent,
    InvoicingDetailComponent,
    ViewLineItemDialogComponent,
    AddLineItemDialogComponent,
    AddInvoiceProductDialogComponent,
    DeleteLineItemDialogComponent,
    InvoiceSettingsComponent,
    ClientRecurringProductsComponent,
    ClientInvoicingComponent,
    AddInvoiceDialogComponent,
    ViewInvoiceProductDialogComponent,
    VoidInvoiceDialogComponent,
    AssignRecurringProductDialogComponent,
    ConfirmPatchDialogComponent,
    AddCreditNoteDialogComponent,
    FinaliseInvoiceDialogComponent,
    EmailInvoiceDialogComponent,
    ClientSentInvoicesComponent,
    CompleteInvoiceDialogComponent,
    ChangeInvoicePaymentTypeDialogComponent,
    ChangeInvoiceTariffDialogComponent,
    ChangeInvoiceReferenceDialogComponent,
    ChangeInvoiceDateDialogComponent,
    ViewContactsDialogComponent,
    PrintInvoiceComponent,
    EndOfDayDialogComponent,
    EmailMessageReportDialogComponent,
    RaiseCreditNoteDialogComponent,
    ClientTemplatesComponent,
    
  ],
  imports: [
    BrowserModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MomentModule,
    ChartsModule,
    MatMomentDateModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  entryComponents: [AddOfficeDialogComponent,
     AddUserDialogComponent, 
     AddClientDialogComponent, 
     ViewMessageDialogComponent, 
     AddTemplateDialogComponent, 
     ViewTemplateDialogComponent,
     AddMovementDialogComponent,
     ViewMovementDialogComponent,
     AddContactDialogComponent,
    AddNoticeDialogComponent,
    ViewNoticeDialogComponent,
    ViewLineItemDialogComponent,
    DeleteLineItemDialogComponent, 
    AddInvoiceDialogComponent, 
    AddLineItemDialogComponent,
    ViewInvoiceProductDialogComponent,
    VoidInvoiceDialogComponent,
    AddInvoiceProductDialogComponent, 
    AssignRecurringProductDialogComponent,
    ConfirmPatchDialogComponent, 
    AddCreditNoteDialogComponent,
    FinaliseInvoiceDialogComponent,
    EmailInvoiceDialogComponent,
    CompleteInvoiceDialogComponent,
    ChangeInvoiceReferenceDialogComponent,
    ChangeInvoiceDateDialogComponent,
    ChangeInvoicePaymentTypeDialogComponent,
    ChangeInvoiceTariffDialogComponent,
    ViewContactsDialogComponent,
    EmailMessageReportDialogComponent,
    EndOfDayDialogComponent,
    RaiseCreditNoteDialogComponent],
  providers: [LoginService, AuthGuard, JournalsService, UserSessionService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-gb' },
    {provide: LOCALE_ID, useValue: 'en-gb'},
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(){
    //dateAdapter.setLocale('en-gb');


  }



 }
