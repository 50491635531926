import { Component, OnInit } from '@angular/core';
import { RouterModule, Router }  from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public router:Router) { 

    
  }

  ngOnInit() {
    console.log(this.router.url);
    if(this.router.url === '/'){
      this.router.navigate(['/messages']);

    }
  }

}
