import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';



import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { UserData, UserDetails, Users, User } from '../../models/userData';
import { RoleData, Roles, Role } from '../../models/roleData';
import { UserRolesData, UserRoles } from '../../models/userRolesData';
import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css'],
  providers: [OfficesUsersRolesService, UserSessionService]
})
export class UserDetailComponent implements OnInit {
  listOfUsers: any;
  listOfRoles: Roles[];
  temp: string;
  userSelected: any;
  rForm: FormGroup;
  selectedRoles: string;
  userId: number;
  listOfUsersActiveRoles: UserRolesData;
  arrayOfActiveRoles: number[] = new Array();
  me: any;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  officeSelected: Office;
  officeIsHeadOffice: boolean = false;

  get roles(): FormArray {
    return <FormArray>this.rForm.get('roles');
  }

  constructor(public officesUsersRolesService: OfficesUsersRolesService, public userSessionService: UserSessionService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'firstName': [false, Validators.required],
      'surname': [false, Validators.required],
      'emailAddress': [false, [Validators.required, Validators.email]],
      'isActive': [false, Validators.required],
      //'isSelected': [false, Validators.required],
      'isEODSummary': [false],
      roles: this.fb.array([])
    });


  }
  addRole(selected, id, disabled): void {

    this.roles.push(this.buildRoles(selected, id, disabled));
  }

  buildRoles(selected, id, disabled): FormGroup {
    return this.fb.group({
      roleId: id,
      locked: disabled,
      isSelected: new FormControl({ value: selected, disabled: disabled })
    })
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  private getMe() {
    console.log("header calling");
    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        //this.me = new Me();


        this.me = resp.user[0];
        let arrayOfRoles = new Array();
        this.me.roles.forEach((role) => {
          arrayOfRoles.push(role.roleId);
        });
        if (arrayOfRoles.includes(3)) {
          this.navRoleOffice = true;
        }
        if (arrayOfRoles.includes(2)) {
          this.navRoleInvoicing = true;
        }
        if (arrayOfRoles.includes(5)) {
          this.navRoleJournal = true;
        }
        if (arrayOfRoles.includes(6)) {
          this.navRoleHeadOffice = true;
        }

        this.getUserRoles(this.userId);
      }
      //this.userSessionService.updateUser(resp);
    });
  }

  ngOnInit() {
    this.getMe();
    this.route.params.forEach(params => {
      this.userId = parseInt(params["userId"]);
      //this.tabId = parseInt(params["tabId"]);
      //call your function, like getUserInfo()
      this.getUsers(this.userId, 0);

    })

    this.userSelected = new User();
    this.listOfUsers = new UserData();

    this.listOfUsersActiveRoles = new UserRolesData();

    
  }
  private getUserRoles(userId: number) {
    this.officesUsersRolesService.getUserRoles(userId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.arrayOfActiveRoles = [];
        this.listOfUsersActiveRoles = resp;
        console.log(this.listOfUsersActiveRoles);
        let tempList = this.listOfUsersActiveRoles.userRoles[0];
        tempList.roles.forEach((role) => {

          this.arrayOfActiveRoles.push(role.roleId);
        });
        this.getRoles(0, '0', 0);
      }
    });
  }


  getOffices(id: number, officeName: string, isActive: number, officePageNo: number) {
    this.officesUsersRolesService.getOffices(id, officeName, isActive, officePageNo).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.officeSelected = resp.officeDetails.offices[0];

        if(this.officeSelected.isHeadOffice){
          this.officeIsHeadOffice = true;
        }else{
          this.officeIsHeadOffice = false;
        }

      }
    });

  }



  private getUsers(userId: number, officeId: number) {
    this.officesUsersRolesService.getUsers(userId, '0', officeId, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfUsers = resp.userDetails;
        resp.userDetails.users.forEach((users) => {

          this.userSelected = users;

        });

        this.getOffices(this.userSelected.officeId, '0', 0, 0);
      }
    });

  }
  private getRoles(roleId: number, name: any, isActive: number) {
    this.officesUsersRolesService.getRoles(0, '0', 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {

        resp.roles.forEach((roles) => {
          roles.isSelected = false;
          console.log(this.arrayOfActiveRoles);
          if (this.arrayOfActiveRoles.includes(roles.roleId)) {

              if (roles.roleId === 1) {
                this.addRole(true, roles.roleId, true);
              } else if (roles.roleId === 6) {

                if (this.navRoleHeadOffice) {

                  this.addRole(true, roles.roleId, false);
                }else{
                  this.addRole(true, roles.roleId, true);
                }
              }else{
                this.addRole(true, roles.roleId, false);
              }

            } else {
              if (roles.roleId === 1) {
                this.addRole(true, roles.roleId, true);
              } else if (roles.roleId === 6)  {
                if (this.navRoleHeadOffice && this.officeIsHeadOffice) {

                  this.addRole(false, roles.roleId, false);
                }else{
                  this.addRole(false, roles.roleId, true);
                }
              }else{
                this.addRole(false, roles.roleId, false);

              }
            }

          });

        this.listOfRoles = resp.roles;

        return this.listOfRoles;
      }

    });

  }

  updateUser(post) {
    console.log(post);
    this.selectedRoles = '';

    post.roles.forEach((role, i) => {
      if (!role.hasOwnProperty("isSelected")) {

        if(role.locked && role.roleId !=1){

        }else{
          this.selectedRoles += role.roleId + ",";
        }
        
      } else {
        if (role.isSelected) {
     
          this.selectedRoles += role.roleId + ",";
        }
      }
    });
    console.log(this.selectedRoles);
    this.selectedRoles = this.selectedRoles.slice(0, -1);

    this.officesUsersRolesService.updateUser(this.userSelected.userId, this.selectedRoles, this.userSelected).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
     
      } else {
        this.openSnackBar("User updated");
      
        //this.ngOnInit();

      }

    });
  }
}