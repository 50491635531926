import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { MessagesService } from '../../services/messages.service';

import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';
import { MessageSend, NewMessage, MessageData, MessageDetails, Messages } from '../../models/messageData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { CallerData, Callers } from '../../models/callerData';


@Component({
  selector: 'app-view-message-dialog',
  templateUrl: './view-message-dialog.component.html',
  styleUrls: ['./view-message-dialog.component.css'],
  providers: [MessagesService, ClientsContactsService]
})
export class ViewMessageDialogComponent implements OnInit {
  messageData: Messages;
  dateTaken: any;
  createdDate: any;
  listOfDelivery: DeliveryType[] = [];
  rForm: FormGroup;
  filteredContacts: ContactAutocomplete[] = [];
  filteredCallers: Callers[] = [];
  myControlContacts: FormControl = new FormControl();
  myControlCaller: FormControl = new FormControl();
  selectedClientId: number = 0;
  listOfContacts: Contact[] = [];
  contactControl: FormControl = new FormControl();
  showDelete: boolean = false;
  contactInfo: Contact;
  loading: boolean = false;
  smsLimit: number = 1361;
  callerLength: number = 0;
  phoneLength: number = 0;
  companyLength: number = 0;
  emailLength: number = 0;
  referenceLength: number = 0;

  filteredOptionsContacts: Observable<ContactAutocomplete[]>;

  constructor(public clientsContactsService: ClientsContactsService, public dialogRef: MatDialogRef<ViewMessageDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public messagesService: MessagesService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'dateTaken': ['', Validators.required],
      'contactId': [null, Validators.required],
      'clientId': ['', Validators.required],
      'message': ['', Validators.required],
      'messageFor': ['', Validators.required],
      'messageTypeId': ['', Validators.required],
      'callerName': [null, Validators.required],
      'phoneNo': ['', Validators.required],
      'email': [''],
      'reference': [''],
      'company': [''],
      'isEod': ['', Validators.required],
      'deliveryTypeId': ['', Validators.required],
      'eodDeliveryTypeId': ['', Validators.required],
      'callerFound': [false]
    });


    this.myControlContacts.valueChanges
      .subscribe(name => {
        console.log(this.myControlContacts.value);
        if (this.myControlContacts.value === '' || typeof this.myControlContacts.value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts.value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts = res.contacts
            })
        }

      });

    this.myControlCaller.valueChanges
      .subscribe(name => {
        console.log(this.myControlCaller.value);
        if (this.myControlCaller.value === '' || typeof this.myControlCaller.value != 'string') {
          return this.filteredCallers = null;
        } else {
          this.messagesService.getCaller(this.myControlCaller.value, this.selectedClientId)
            .subscribe(res => {
              return this.filteredCallers = res.callers
            })
        }

      });
  }

  ngOnInit() {
    this.messageData = this.data.messageData;
    this.dateTaken = moment(this.messageData.dateTaken).toDate();
    this.createdDate = moment(this.messageData.createdDate).toDate();
    this.myControlContacts.setValue({ contactId: this.messageData.contactId, contactName: this.messageData.contactName });
    this.myControlCaller.setValue({ callerName: this.messageData.callerName });

    this.selectedClientId = this.data.messageData.clientId;
    this.getDeliveryType();
    this.getContacts();

    this.callerLength = this.messageData.callerName.length;
    this.phoneLength = this.messageData.phoneNo.length;
    console.log(this.callerLength )
  }




  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }

  displayFnCallers(caller: Callers): any {
    return caller ? caller.callerName : caller;
  }


  public submit(event) {
    switch (event) {
      case 'GET': this.updateMessage(); break;
      case 'POST': this.resend(); break;
      default: break;
    }
  }



  callerUpdate(callerData){
  
    this.callerLength = callerData.length;
 
   }
 
   phoneUpdate(phoneData){
    
    this.phoneLength = phoneData.length;
 
   }
 
   companyUpdate(companyData){
 
 
     if(companyData.length === 0){
       this.companyLength = 0;
     }else{
     this.companyLength = companyData.length + 10;
     }
   }
   emailUpdate(emailData){
 
     if(emailData.length === 0){
       this.emailLength = 0;
     }else{
     this.emailLength = emailData.length + 8;
     }
 
   }
   referenceUpdate(referenceData){
 
     if(referenceData.length === 0){
       this.referenceLength = 0;
     }else{
       this.referenceLength = referenceData.length +12;
     }

   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getContacts() {

    this.clientsContactsService.getContacts(0, 0, this.messageData.clientId, 1, 0, 0, 1, 'DESC', 0).subscribe(resp => {

      this.listOfContacts = resp.contactDetails.contacts;
      if (resp.contactDetails.totalCount === 1) {
        //this.myControlContacts.setValue({ contactId: resp.contactDetails.contacts[0].clientId, fullName: resp.contactDetails.contacts[0].fullName });
        this.messageData.contactId = this.messageData.contactId;
        this.rForm.controls['contactId'].setValue(this.messageData.contactId);
        //this.newMessage.messageFor = resp.contactDetails.contacts[0].fullName;
        // this.setContact(resp.contactDetails.contacts[0]);
      }
    })

  }

  getContact(contactId: number) {
    this.clientsContactsService.getContacts(contactId, '0', 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length == 0) {
        this.contactInfo = resp.contactDetails.contacts[0];

        this.rForm.controls['deliveryTypeId'].setValue(this.contactInfo.deliveryTypeId);

      } else {
        this.openSnackBar(resp.errors[0].error);

      }

    });
  }


  setContact(contact) {


    if (contact.contactId > 0) {
      //this.rForm.get('messageFor').setValue(contact.contactId);
      //this.messageData.contactId = contact.contactId;
      this.messageData.messageFor = contact.fullName;

      this.getContact(contact.contactId);
      //this.rForm.controls['deliveryTypeId'].setValue(this.contactInfo.deliveryTypeId);
    }

  }

  setCaller(caller) {
    console.log(caller);
    const callerFound = this.rForm.get('callerFound');


    if (caller.callerId) {
      callerFound.setValue(true);
      this.rForm.get('callerName').setValue(caller.callerName);
      this.messageData.callerName = caller.callerName;
      this.rForm.get('company').setValue(caller.company);
      this.messageData.company = caller.company;
      this.rForm.get('phoneNo').setValue(caller.phone);
      this.messageData.phoneNo = caller.phone;
      this.rForm.get('email').setValue(caller.email);
      this.messageData.email = caller.email;
      this.rForm.get('reference').setValue(caller.reference);
      this.messageData.reference = caller.reference;
    } else {
      //callerFound
      this.rForm.get('callerName').setValue(caller.callerName);
      this.messageData.callerName = caller.callerName;
      callerFound.setValue(false);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  resend() {

    let tempLimit = Number(this.smsLimit) - (Number(this.callerLength) + Number(this.phoneLength) + Number(this.companyLength) + Number(this.emailLength) + Number(this.referenceLength));

    console.log(tempLimit + "||" + this.smsLimit);
    if(this.messageData.deliveryTypeId === 1 && this.messageData.message.length + this.callerLength + this.phoneLength +  this.companyLength + this.emailLength + this.referenceLength > this.smsLimit || this.messageData.deliveryTypeId === 3 && this.messageData.message.length + this.callerLength + this.phoneLength + this.companyLength + this.emailLength + this.referenceLength > this.smsLimit){
      this.openSnackBar("Message is too long for SMS delivery, please edit to be " + tempLimit + " characters or less.");
    }else{

    this.loading = true;
    this.messageData.dateTaken = moment(this.messageData.dateTaken).toDate();

    this.messagesService.resendMessage(this.messageData.messageId, this.messageData).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.loading = false;
      } else {
        this.loading = false;
        this.openSnackBar("Message resent");
        this.dialogRef.close("reload");

      }
    });
  }

  }
  getDeliveryType() {
    this.clientsContactsService.getDeliveryType(0, '0', 1).subscribe(resp => {
      this.listOfDelivery = resp.deliveryTypes;
    });
  }
  updateMessage() {
    this.messageData.dateTaken = moment(this.messageData.dateTaken).toDate();

    this.messagesService.updateMessage(this.messageData.messageId, this.messageData).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Message updated");
        this.dialogRef.close("reload");

      }
    });

  }

  deactivateMessageStep() {

    if (this.showDelete) {
      this.showDelete = false;

    } else {
      this.showDelete = true;

    }

  }
  deactivateMessage() {
    this.messageData.dateTaken = moment(this.messageData.dateTaken).toDate();
    this.messageData.isActive = false;

    this.messagesService.updateMessage(this.messageData.messageId, this.messageData).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Message deactivated");
        this.dialogRef.close("reload");

      }
    });


  }
}
