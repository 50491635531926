import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import * as moment from 'moment';


import { TemplatesService } from '../../services/templates.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';


import { ClientData, ClientAutocomplete } from '../../models/clientData';

import { TemplateData, Templates, Template } from '../../models/templateData';

import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-view-template-dialog',
  templateUrl: './view-template-dialog.component.html',
  styleUrls: ['./view-template-dialog.component.css'],
  providers: [TemplatesService, ClientsContactsService]
})
export class ViewTemplateDialogComponent implements OnInit {
  rForm: FormGroup;
  templateData: Template;
  filteredClients: ClientAutocomplete[] = [];
  officeIdSubject: Observable<string>;
  officeId: number;

  myControl: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<ViewTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public userSessionService: UserSessionService, public clientsContactsService: ClientsContactsService, private fb:FormBuilder, public templatesService:TemplatesService, public snackBar: MatSnackBar) { 

    this.officeIdSubject = userSessionService.getOfficeId();

    this.rForm = fb.group({
      
      'message': [null, Validators.required],
      'officeId': [null],
      'clientId': [null], 
      'isGlobal': [true, Validators.required],
      'deliveryTypeId' : [1],
      'isActive':  [true, Validators.required],
      'clientOwner': fb.group({
        'clientId': [null], 
        'clientName': [null], 
      })

          });


          this.myControl.valueChanges
          .subscribe(name => {
            //console.log(this.rForm.controls['clientOwner'].value);
            if (this.myControl.value === '' || typeof this.myControl.value != 'string') {
              return this.filteredClients = null;
            } else {
              this.clientsContactsService.getClientsAutocomplete(this.myControl.value, 0, this.officeId, 0, 0)
                .subscribe(res => {
                  return this.filteredClients = res.clients
                })
            }
    
          });

  }

  ngOnInit() {
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));
    this.templateData = this.data.templateData;
    //this.filteredClients.push({clientId: this.templateData.clientId, clientName: this.templateData.clientName});
    //this.rForm.controls['clientOwner.clientId'].setValue(this.templateData.clientId);
    this.myControl.setValue({clientId: this.templateData.clientId, clientName: this.templateData.clientName});
    //this.rForm.patchValue({clientOwner: {clientId: this.templateData.clientId }});
    //this.rForm.patchValue({clientOwner: {clientName: this.templateData.clientName }});
  }

  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  globalChange(event: Event) {
    const ctrl = this.rForm.get('clientId');
  
    if (event) {
      this.templateData.clientId= null;
      this.myControl.setValue({clientId: null, clientName: ''});
      this.myControl.disable();
      ctrl.setValue(null);
    } else {
      
      this.myControl.enable();
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
  setClient(client) {
    
        //const contactField = this.rForm.get('contactId');
        //const templateId = this.rForm.get('templateId');
    
        if (client.clientId > 0) {
          this.templateData.clientId = client.clientId;
        }
      }
  updateTemplate(post){
  this.templatesService.updateTemplates(this.templateData.templateId, this.templateData).subscribe(resp => {
    
        //this.addInfo = resp;
        if (resp.errors.length > 0) {
          this.openSnackBar(resp.errors[0].error);
        } else {
    
        this.openSnackBar("Template updated");
        this.dialogRef.close();
      
        }
    });
  }
}
