import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { NoticeData, NoticeDetails, NoticeDetail } from '../../models/noticeData';

@Component({
  selector: 'app-view-notice-dialog',
  templateUrl: './view-notice-dialog.component.html',
  styleUrls: ['./view-notice-dialog.component.css'],
  providers: [OfficesUsersRolesService]
})
export class ViewNoticeDialogComponent implements OnInit {

  rForm: FormGroup;
  noticeData: NoticeDetail

  constructor(public officesUsersRolesService: OfficesUsersRolesService, public dialogRef: MatDialogRef<ViewNoticeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar, ) { 
    this.rForm = fb.group({
      'officeId': [null],
      'notice': [null],
      'startDate': [moment().toDate(), Validators.required],
      'reminderDate': [moment().toDate(), Validators.required],
      'endDate': [moment().add(7, 'days').toDate(), Validators.required],
      'isActive': [true]
    });
  }

  ngOnInit() {

    this.noticeData = this.data.noticeData;
    this.rForm.controls['startDate'].setValue(moment(this.data.noticeData.startDate).toDate());
    this.rForm.controls['reminderDate'].setValue(moment(this.data.noticeData.reminderDate).toDate());
    this.rForm.controls['endDate'].setValue(moment(this.data.noticeData.endDate).toDate());
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  updateNotice(post) {
    if(moment(post.startDate).isValid()){
      this.noticeData.startDate = post.startDate;
    }
    if(moment(post.reminderDate).isValid()){
      this.noticeData.reminderDate = post.reminderDate;
    }
    if(moment(post.endDate).isValid()){
      this.noticeData.endDate = post.endDate;
    }


    this.officesUsersRolesService.updateNotices(this.noticeData, this.noticeData.noticeId).subscribe(resp => {
      
          //this.addInfo = resp;
          if (resp.errors.length > 0) {
            this.openSnackBar(resp.errors[0].error);
          } else {
      
          this.openSnackBar("Notice Updated");
          this.dialogRef.close();
        
          }
      });


  }



}
