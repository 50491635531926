import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';




import { InvoicingService } from '../../services/invoicing.service';

import { StatusTypesData, StatusTypes, StatusType } from '../../models/invoiceStatusData';

import { InvoiceData, Invoices, Invoice } from '../../models/invoiceData';

@Component({
  selector: 'app-client-invoicing',
  templateUrl: './client-invoicing.component.html',
  styleUrls: ['./client-invoicing.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ClientInvoicingComponent implements OnInit {
  listOfInvoices:  Invoice[] = [];
  clientId: number = 0;
  isActiveFilter: number = 0;
  isInvoiceTypeFilter: number = 0;
  noOfInvoices: number = 0;
  listOfInvoiceStatus: StatusType[] = [];
  sortId: number = 4;
  sortDir: string = 'DESC';
  statusTypeId: number = 0;
  pageNo: number = 1;

  activeStates = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Active' },
    { value: 2, viewValue: 'Inactive' }
  ];


  invoiceTypes = [
    { value: 0, viewValue: 'Any' },
    { value: 1, viewValue: 'Invoice' },
    { value: 2, viewValue: 'Credit Note' }
  ];


  constructor(public invoicingService: InvoicingService, public snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
   
  }

  getInvoiceStatus(){

    this.invoicingService.getInvoiceStatusType(0,0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfInvoiceStatus = resp.statusTypes;
        this.listOfInvoiceStatus.unshift({statusTypeId: 0, name: "Any", isActive: true});
      }
    })
  }

  reset(){

    this.isActiveFilter = 1;
    this.pageNo = 1;
    this.isInvoiceTypeFilter = 1;
    this.sortId = 4;
    this.statusTypeId =1;
    this.sortDir = 'DESC';
    
    this.getInvoices(this.clientId, this.isInvoiceTypeFilter, this.statusTypeId,  this.isActiveFilter, this.sortId, this.sortDir, 1);
  }

  changeDir(sortId) {
    if (this.sortDir === 'ASC') {
      this.sortDir = 'DESC';
      this.sortId = sortId;
      this.getInvoices(this.clientId, this.isInvoiceTypeFilter, this.statusTypeId,  this.isActiveFilter, this.sortId, this.sortDir, this.pageNo);
    } else {
      this.sortDir = 'ASC';
      this.sortId = sortId;
      this.getInvoices(this.clientId, this.isInvoiceTypeFilter, this.statusTypeId,  this.isActiveFilter, this.sortId, this.sortDir, this.pageNo);
    }

  }

  setPage(event) {
    console.log(event);
    this.getInvoices(this.clientId, this.isInvoiceTypeFilter, this.statusTypeId,  this.isActiveFilter, this.sortId, this.sortDir, event.pageIndex + 1);

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getInvoices(clientId: number, invoiceTypeId: number, statusId: number,  isActive: number, sortId: number, sortDir: any, pageNo: number){

    this.invoicingService.getInvoices(0, clientId, 0, invoiceTypeId, statusId, isActive, sortId, sortDir, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfInvoices = resp.invoices.invoices;
        this.noOfInvoices = resp.invoices.totalCount;

      }
    })
  }
}
