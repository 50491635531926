import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppSettings } from '../app-settings';

import { TotalMessagesByUserAndDateData, UsersReporting } from '../models/totalMessagesByUserAndDateData';
import { TotalMessagesByClientAndDateData, ClientsReporting } from '../models/totalMessagesByClientAndDateData';
import { TodayTotalMessagesData, Messages } from '../models/todayTotalMessagesData';

@Injectable()
export class ReportingService {

  constructor(public http: HttpClient) { }
  
    getTotalMessagesByUserAndDate(userId:number, clientId: number, fromCreatedDate: string, toCreatedDate:string): Observable<TotalMessagesByUserAndDateData> {
      return this.http.get<TotalMessagesByUserAndDateData>(AppSettings.API_ENDPOINT + "/totalMessagesByUserAndDate/" + userId + '/' + clientId + '/' + fromCreatedDate + '/' + toCreatedDate );
    }
    getTotalMessagesByClientAndDate(clientId:number, fromCreatedDate: string, toCreatedDate:string, limit: number): Observable<TotalMessagesByClientAndDateData> {
      return this.http.get<TotalMessagesByClientAndDateData>(AppSettings.API_ENDPOINT + "/totalMessagesByClientAndDate/" + clientId + '/' + fromCreatedDate + '/' + toCreatedDate + '/' + limit);
    }
    getTodayTotalMessages(officeId:number): Observable<TodayTotalMessagesData> {
      return this.http.get<TodayTotalMessagesData>(AppSettings.API_ENDPOINT + "/todayTotalMessages" );
    }
    getCSVTotalMessagesByUserAndDate(userId:number, clientId: number, fromCreatedDate: string, toCreatedDate:string) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForTotalMessagesByUserAndDate/" + userId + '/' + clientId + '/' + fromCreatedDate + '/' + toCreatedDate, {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }
    getCSVTotalMessagesByClientAndDate(clientId:number, fromCreatedDate: string, toCreatedDate:string) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForTotalMessagesByClientAndDate/" + clientId + '/' + fromCreatedDate + '/' + toCreatedDate, {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }
    getCSVForMessagesCount(fromCreatedDate: string, toCreatedDate:string) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForMessagesCount/" + fromCreatedDate + '/' + toCreatedDate, {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }
    getCSVForClients(fromCreatedDate: number, toCreatedDate:number) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForClients/" + fromCreatedDate + "/" + toCreatedDate, {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }
    getCSVForInvoice(officeId: number, clientId: number, statusId: number, fromDate: any, endDate: any ) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForInvoice/" + officeId + "/" + clientId + "/" + statusId + "/" +fromDate + "/" + endDate , {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }
    getCSVForInvoiceWithLineItems(officeId: number, clientId: number, statusId: number, fromDate: any, endDate: any ) {
      return this.http.get(AppSettings.API_ENDPOINT + "/csvForInvoiceWithLineItems/" + officeId + "/" + clientId + "/" + statusId + "/" +fromDate + "/" + endDate , {headers: new HttpHeaders().set('Content-Type', 'text/csv'), responseType: 'blob'} );
    }

}
