import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem } from '../../models/invoiceData';


@Component({
  selector: 'app-void-invoice-dialog',
  templateUrl: './void-invoice-dialog.component.html',
  styleUrls: ['./void-invoice-dialog.component.css'],
  providers: [InvoicingService]
})
export class VoidInvoiceDialogComponent implements OnInit {
  invoiceId: number;

  constructor(public dialogRef: MatDialogRef<VoidInvoiceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    this.invoiceId = this.data.invoice.invoiceId;

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  voidInvoice(invoiceId: number){

    this.invoicingService.voidInvoice(invoiceId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Invoice marked as Void");

        this.dialogRef.close();

      }

    })

  }
}
