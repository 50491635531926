import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { MovementsService } from '../../services/movements.service';
import { MovementDetails, MovementData, Movements, Movement } from '../../models/movementData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';

@Component({
  selector: 'app-add-movement-dialog',
  templateUrl: './add-movement-dialog.component.html',
  styleUrls: ['./add-movement-dialog.component.css'],
  providers: [MovementsService, ClientsContactsService]
})
export class AddMovementDialogComponent implements OnInit {

  rForm: FormGroup;
  newMovement: Movement;
  myControlContacts: FormControl = new FormControl();
  myControlContacts2: FormControl = new FormControl();
  filteredContacts: ContactAutocomplete[] = [];
  filteredContacts2: ContactAutocomplete[] = [];
  selectedClientId: number;

  constructor(public dialogRef: MatDialogRef<AddMovementDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public snackBar: MatSnackBar, public movementsService: MovementsService, public clientsContactsService: ClientsContactsService) {


    this.rForm = fb.group({

      'clientId': [null],
      'notes': [null],
      'startDate': [moment().toDate(), Validators.required],
      'endDate': [moment().toDate(), Validators.required],
      'outOfOfficeId': [null],
      'forwardToId': [null],
    });


    this.myControlContacts.valueChanges
      .subscribe(name => {
        console.log(this.myControlContacts.value);
        if (this.myControlContacts.value === '' || typeof this.myControlContacts.value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts.value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts = res.contacts
            })
        }

      });



    this.myControlContacts2.valueChanges
      .subscribe(name => {
        console.log(this.myControlContacts2.value);
        if (this.myControlContacts2.value === '' || typeof this.myControlContacts2.value != 'string') {
          return this.filteredContacts2 = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts2.value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts2 = res.contacts
            })
        }

      });
  }

  ngOnInit() {
    this.newMovement = new Movement();
    this.selectedClientId = this.data.clientId;

    this.newMovement.clientId = this.selectedClientId;
  }
  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }

  setOutofOffice(contact) {
  
    if (contact.contactId > 0) {
      this.newMovement.outOfOfficeId = contact.contactId;
    }


  }
  setForwardTo(contact) {

    if (contact.contactId > 0) {
      this.newMovement.forwardToId = contact.contactId;
    }

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  addMovement(post) {


    post.startDate = moment(post.startDate).set({hour:0,minute:0,second:0,millisecond:0}).toDate();

    post.endDate = moment(post.endDate).set({hour:0,minute:0,second:0,millisecond:0}).toDate();
    
    if (moment(post.startDate).isValid()) {
      this.newMovement.startDate = post.startDate;
    }
    if (moment(post.endDate).isValid()) {
      this.newMovement.endDate = post.endDate;
    }


    console.log(this.newMovement);
    this.movementsService.addMovements(this.newMovement).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Movement Added");
        this.dialogRef.close();

      }
    });


  }

}
