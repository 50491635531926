import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';



import { ViewMessageDialogComponent } from '../../components/view-message-dialog/view-message-dialog.component';
import { JournalData, JournalMessages, JournalMessage } from '../../models/journalData';
import { MessageSend, NewMessage, MessageData, MessageDetails, Messages, MessageSearch } from '../../models/messageData';

import { MessagesService } from '../../services/messages.service';

import { JournalsService } from '../../services/journals.service';

import { UserSessionService } from '../../services/user-session.service';

@Component({
  selector: 'app-journals',
  templateUrl: './journals.component.html',
  styleUrls: ['./journals.component.css'],
  providers: [UserSessionService, MessagesService]
})
export class JournalsComponent implements OnInit {
  myJournals: JournalMessage[] = [];
  myJournalsNo: number = 0;
  otherJournals: JournalMessage[] = [];
  otherJournalsNo: number = 0;
  userIdSubject: Observable<string>;
  listOfMessages: Messages;
  pageNo: number = 0;
  messageSearchData: MessageSearch;

  constructor(public messagesService: MessagesService, public journalsService: JournalsService, public snackBar: MatSnackBar, public userSessionService: UserSessionService, public dialog: MatDialog) {


    this.userIdSubject = userSessionService.getUserId();

    console.log(this.userIdSubject);
  }

  ngOnInit() {

    this.messageSearchData = new MessageSearch()
    this.userIdSubject.subscribe(id => this.getMyJournals(Number(id), 1))

    this.getOtherJournals(0, 1);
  }


  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getMyJournals(userId: number, pageNo: number) {

    this.journalsService.getJournals(userId, 0, 0, 0, 0, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.myJournals = resp.messageId.messages;
        this.myJournalsNo = resp.messageId.totalCount;
      }
    });

  }

  setPage(event) {
    this.pageNo = event.pageIndex;
    this.userIdSubject.subscribe(id => this.getMyJournals(Number(id), event.pageIndex + 1))
 
  }

  setPageOther(event) {
    this.pageNo = event.pageIndex;
    this.getOtherJournals(0, event.pageIndex + 1);
  }

  getOtherJournals(userId: number, pageNo: number) {

    this.journalsService.getJournals(userId, 0, 0, 0, 0, pageNo).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.otherJournals = resp.messageId.messages;
        this.otherJournalsNo = resp.messageId.totalCount;
        // this.journalsService.updateJournal(resp);
      }
    });

  }

  completeMyJournal(journal) {

    this.journalsService.addJournals(journal.messageId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.userIdSubject.subscribe(id => this.getMyJournals(Number(id), 1))
        this.openSnackBar("Journal Completed");
      }
    });

  }


  completeOtherJournal(journal) {


    this.journalsService.addJournals(journal.messageId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.getOtherJournals(0, 1);
        this.openSnackBar("Journal Completed");
      }
    });

  }

  viewMessagetDialog(message): void {

    this.messageSearchData.messageId = message.messageId;

    this.messagesService.getMessages(this.messageSearchData).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        let dialogRef = this.dialog.open(ViewMessageDialogComponent, {
          width: '1000px',
          data: { messageData: resp.messageDetails.messages[0] }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
      }
    })
  }
}
