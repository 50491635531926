import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import * as moment from 'moment';
import { EmailValidator } from '@angular/forms';

import { ClientsContactsService } from '../../services/clients-contacts.service';

import { ClientData, ClientDetails, Clients, Client } from '../../models/clientData';
import { ContactData, ContactDetails, Contacts, Contact } from '../../models/contactData';
import { DeliveryData, DeliveryType, DeliveryTypes } from '../../models/deliveryData';

@Component({
  selector: 'app-add-contact-dialog',
  templateUrl: './add-contact-dialog.component.html',
  styleUrls: ['./add-contact-dialog.component.css'],
  providers: [ClientsContactsService]
})
export class AddContactDialogComponent implements OnInit {
  rForm: FormGroup;
  listOfDeliveryTypes: DeliveryType[] = [];
  newContact: Contact;

  constructor(public dialogRef: MatDialogRef<AddContactDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb:FormBuilder, public clientsContactsService:ClientsContactsService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      
      'title': [null],
      'firstName': [null, Validators.required],
      'surname': [null],
      'deliveryTypeId': [null, Validators.required],
      'emailAddress1': [''],
      'emailAddress2': [''],
      'faxNo': [null],
      'isDefaultContact': [false],
      'isEmailAddress1Eod': [false],
      'isEmailAddress1Mod': [false],
      'isEmailAddress1Private': [false],
      'isEmailAddress2Eod': [false],
      'isEmailAddress2Mod': [false],
      'isEmailAddress2Private': [false],
      'isFaxNoPrivate': [false],
      'position': [null],
      'isMobileNoPrivate': [false],
      'isPrinciple': [false],
      'isPhoneNoPrivate': [false],
      'mobileNo': [null],
      'phoneNo': [null],
    });
   }

  ngOnInit() {
    this.newContact = new Contact();
    this.newContact.clientId = this.data.clientId;
    this.getDeliveryTypes();
  }
  getDeliveryTypes(){

    this.clientsContactsService.getDeliveryType(0, '0', 1).subscribe(resp =>{

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.listOfDeliveryTypes = resp.deliveryTypes;
      }
    })
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
  addContact(post){

    this.clientsContactsService.addContact(this.newContact).subscribe(resp => {
      if (resp.errors.length > 0) {
            this.openSnackBar(resp.errors[0].error);
          } else {
            this.openSnackBar("Contact added");
            this.dialogRef.close();
          }    
      });
  }
}
