import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';




import { MeData } from '../../models/meData';
import { TemplateData, Templates, Template } from '../../models/templateData';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';
import { MovementData, MovementDetails, Movements, Movement } from '../../models/movementData';
import { NewMessage, MessageData, MessageDetails, Messages, MessageSearch } from '../../models/messageData';
import { CallerData, Callers } from '../../models/callerData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { MessagesService } from '../../services/messages.service';
import { ViewMessageDialogComponent } from '../../components/view-message-dialog/view-message-dialog.component';
import { ConfirmPatchDialogComponent } from '../../components/confirm-patch-dialog/confirm-patch-dialog.component';
import { ViewContactsDialogComponent } from '../../components/view-contacts-dialog/view-contacts-dialog.component';
import { TemplatesService } from '../../services/templates.service';

import { MovementsService } from '../../services/movements.service';

import { UserSessionService } from '../../services/user-session.service';
import { JournalData, JournalMessages } from '../../models/journalData';

import { JournalsService } from '../../services/journals.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
  providers: [ClientsContactsService, MovementsService, TemplatesService, MessagesService, UserSessionService]
})
export class MessagesComponent implements OnInit {
  showForm: boolean = true;
  useraccount: MeData;
  stateCtrl: FormControl;
  listOfMovements: Movement[] = [];
  selectedValue: string;
  listOfTemplates: any;
  useraccount2: any;
  email: any;
  rForm: FormGroup;
  listOfMessages: Messages[] = [];
  selectedOption: ClientAutocomplete;
  selectedOption2: ContactAutocomplete;
  options = [];
  options2 = [];
  selectedClient: Client;
  selectedContact: Contact;
  messageSearchData: MessageSearch;
  newMessage: NewMessage;
  viewClientData: boolean = false;
  filteredClients: ClientAutocomplete[] = [];
  filteredContacts: ContactAutocomplete[] = [];
  filteredCallers: Callers[] = [];
  selectedClientId: number = 0;
  contactInfo: Contact;
  listOfDelivery: DeliveryType[] = [];
  item: number;
  subscription: Subscription;
  message: any;
  userOfficeId: string;
  userId: string;
  clientId: number = 0;
  userRoles: string;
  userIdSubject: Observable<string>;
  officeIdSubject: Observable<string>;
  rolesSubject: Observable<string>;
  ignoreMovement: boolean = false;
  beforeMovement: Contact;
  contactMovement: Movement;
  hour: number;
  minute: number;
  listOfContacts: Contact[] = [];
  me: any;
  navRoleOffice: boolean = false;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  noOfMessages: number = 0;
  officeId: number;
  movementContact: Contact;
  listOfMovementIds = [];
  outOfOfficeContact: Contact;
  smsLimit: number = 1361;
  callerLength: number = 0;
  phoneLength: number = 0;
  companyLength: number = 0;
  emailLength: number = 0;
  referenceLength: number = 0;

  myControlClients: FormControl = new FormControl();

  myControlContacts: FormControl = new FormControl();

  contactControl: FormControl = new FormControl();


  testTime: any;
  hours = [
    { id: 0, viewValue: '00' },
    { id: 1, viewValue: '01' },
    { id: 2, viewValue: '02' },
    { id: 3, viewValue: '03' },
    { id: 4, viewValue: '04' },
    { id: 5, viewValue: '05' },
    { id: 6, viewValue: '06' },
    { id: 7, viewValue: '07' },
    { id: 8, viewValue: '08' },
    { id: 9, viewValue: '09' },
    { id: 10, viewValue: '10' },
    { id: 11, viewValue: '11' },
    { id: 12, viewValue: '12' },
    { id: 13, viewValue: '13' },
    { id: 14, viewValue: '14' },
    { id: 15, viewValue: '15' },
    { id: 16, viewValue: '16' },
    { id: 17, viewValue: '17' },
    { id: 18, viewValue: '18' },
    { id: 19, viewValue: '19' },
    { id: 20, viewValue: '20' },
    { id: 21, viewValue: '21' },
    { id: 22, viewValue: '22' },
    { id: 23, viewValue: '23' }

  ];

  minutes = [];

  constructor(public dialog: MatDialog, public journalsService: JournalsService, public snackBar: MatSnackBar, private fb: FormBuilder, public userSessionService: UserSessionService, public messagesService: MessagesService, public clientsContactsService: ClientsContactsService, public movementsService: MovementsService, public templatesService: TemplatesService) {

    this.userIdSubject = userSessionService.getUserId();

    this.officeIdSubject = userSessionService.getOfficeId();
    //console.log(this.officeIdSubject);
    this.rolesSubject = userSessionService.getRoles();

    this.testTime = moment();
    console.log(this.testTime.toISOString());


    this.rForm = fb.group({

      'clientId': new FormControl(),
      'contactId': new FormControl({ value: '', disabled: true }, Validators.required),
      'callerName': ['', Validators.required],
      'templateId': [{ value: 0, disabled: true }],
      'message': ['', Validators.required],
      'email': [''],
      'reference': [''],
      'dateTaken': [moment().toDate(), Validators.required],
      'phoneNo': ['', Validators.required],
      'isEod': [true],
      'messageFor': ['', Validators.required],
      'company': [false],
      'messageTypeId': [1],
      'callerFound': [false],
      'deliveryTypeId': [1, Validators.required],
      'hour': new FormControl(),
      'minute': new FormControl(),
    });

    this.rForm.controls['clientId'].valueChanges
      .subscribe(name => {
        //console.log(this.rForm.controls['clientId'].value);
        if (this.rForm.controls['clientId'].value === '' || typeof this.rForm.controls['clientId'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientId'].value, 0, this.officeId, 0, 1)
            .subscribe(res => {
              if (res.errors.length == 0) {
                return this.filteredClients = res.clients
              } else {
                this.openSnackBarError(res.errors[0].error);
                this.userSessionService.checkToken().subscribe();
              }

            })
        }

      });

    this.myControlContacts.valueChanges
      .subscribe(name => {
        //console.log(this.myControlContacts.value);
        if (this.myControlContacts.value === '' || typeof this.myControlContacts.value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.myControlContacts.value, 0, this.selectedClientId, 1)
            .subscribe(res => {
              if (res.errors.length == 0) {
                return this.filteredContacts = res.contacts
              } else {
                this.openSnackBarError(res.errors[0].error);
                this.userSessionService.checkToken().subscribe();
              }

            })
        }

      });

  }


  private getMe() {
    //console.log("header calling");
    this.userSessionService.getMe().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        //this.me = new Me();


        this.me = resp.user[0];
        let arrayOfRoles = new Array();
        this.me.roles.forEach((role) => {
          arrayOfRoles.push(role.roleId);
        });
        if (arrayOfRoles.includes(3)) {
          this.navRoleOffice = true;
        }
        if (arrayOfRoles.includes(2)) {
          this.navRoleInvoicing = true;
        }
        if (arrayOfRoles.includes(5)) {
          this.navRoleJournal = true;
        }
        if (arrayOfRoles.includes(6)) {
          this.navRoleHeadOffice = true;
        }
      }
      //this.userSessionService.updateUser(resp);
    });
  }


  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }
  displayFnCallers(caller: Callers): any {
    return caller ? caller.callerName : caller;
  }

  filter(clientName: string): ClientAutocomplete[] {

    this.clientsContactsService.getClientsAutocomplete(clientName, 0, this.officeId, 0, 1).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.options = [];
        resp.clients.forEach((clients) => {

          let client = new ClientAutocomplete();
          client = clients;
          this.options.push(client);
        });
      } else {
        this.openSnackBarError(resp.errors[0].error);
      }

    });

    return this.options.filter(option =>
      option.clientName.toLowerCase().indexOf(clientName.toLowerCase()) === 0);

  }

  filter2(contactName: string): ContactsAutocomplete[] {

    this.clientsContactsService.getContactsAutocomplete(contactName, 0, 0, 1).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.options2 = [];
        resp.contacts.forEach((contacts) => {

          let contact = new ContactAutocomplete();
          contact = contacts;
          this.options2.push(contact);
        });
      } else {
        this.openSnackBarError(resp.errors[0].error);
      }

    });
    return this.options2.filter(option =>
      option.contactName.toLowerCase().indexOf(contactName.toLowerCase()) === 0);

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 4000,
    });
  }
  openSnackBarError(message: string) {
    this.snackBar.open(message, "close", {
    });
  }

  undoMovement() {
    this.rForm.controls['contactId'].setValue(this.beforeMovement);
    this.selectedContact = this.beforeMovement;
    this.contactMovement = null;
    this.contactInfo = this.beforeMovement;
    this.rForm.controls['deliveryTypeId'].setValue(this.beforeMovement.deliveryTypeId);
    this.newMessage.messageFor = this.beforeMovement.fullName;
  }

  ngOnInit() {
    this.getMe();
    let i: number = 0;

    for (i = 0; i < 60; i++) {
      if (i < 10) {
        this.minutes.push({ id: i, viewValue: '0' + i });
      } else {
        this.minutes.push({ id: i, viewValue: String(i) });
      }
    }

    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.hour = Number(moment().format('HH'));

    this.minute = Number(moment().format('mm'));

    this.getDeliveryType();
    this.outOfOfficeContact = new Contact();
    this.movementContact = new Contact();
    this.beforeMovement = new Contact();
    this.newMessage = new NewMessage();
    this.contactInfo = new Contact();
    this.messageSearchData = new MessageSearch();

  }

  private getClients(id: number, clientName: string, clientAcroynm: string, officeId: number, isActive: number, officePageNo: number) {
    this.clientsContactsService.getClients(id, clientName, clientAcroynm, officeId, isActive, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', officePageNo).subscribe(resp => {

      resp.clientDetails.clients.forEach((clients) => {
        this.selectedClient = clients;
        this.viewClientData = true;

        this.clientsContactsService.getContacts(0, 0, this.selectedClient.clientId, 1, 0, 0, 1, 'ASC', 0).subscribe(resp => {

          this.listOfContacts = resp.contactDetails.contacts;

          //set if only one contact
          if (resp.contactDetails.totalCount === 1) {
            //this.myControlContacts.setValue({ contactId: resp.contactDetails.contacts[0].clientId, fullName: resp.contactDetails.contacts[0].fullName });
            this.newMessage.contactId = resp.contactDetails.contacts[0].contactId;
            this.rForm.controls['contactId'].setValue(resp.contactDetails.contacts[0]);
            this.newMessage.messageFor = resp.contactDetails.contacts[0].fullName;
            let contactTemp = { 'value': resp.contactDetails.contacts[0] }
            this.rForm.controls['deliveryTypeId'].setValue(resp.contactDetails.contacts[0].deliveryTypeId);
            this.selectedContact = resp.contactDetails.contacts[0];
            this.contactInfo = resp.contactDetails.contacts[0];
          }
        })
        //Parse Web url link with https or http prefix
        if (this.selectedClient.webUrl === '' || this.selectedClient.webUrl === null) {

        } else {
          if (this.selectedClient.webUrl.startsWith("http://") || this.selectedClient.webUrl.startsWith("https://")) {
          } else {
            this.selectedClient.webUrl = "http://" + this.selectedClient.webUrl;
          }
        }

        if (this.selectedClient.privateUrl === '' || this.selectedClient.privateUrl === null) {

        } else {
          if (this.selectedClient.privateUrl.startsWith("http://") || this.selectedClient.privateUrl.startsWith("https://")) {
          } else {
            this.selectedClient.privateUrl = "http://" + this.selectedClient.privateUrl;
          }
        }
        if (this.selectedClient.formUrl === '' || this.selectedClient.formUrl === null) {

        } else {
          if (this.selectedClient.formUrl.startsWith("http://") || this.selectedClient.formUrl.startsWith("https://")) {
          } else {
            this.selectedClient.formUrl = "http://" + this.selectedClient.formUrl;
          }
        }
      });
    });
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    if (tabChangeEvent.index === 2) {
      this.getMessages(this.clientId);
    }
  }
  private focused = false;

  public focusout = (): void => {
    this.focused = false;
  };

  public focus = (): void => {
    if (this.focused) return;
    this.focused = true;

    // Timeout for cross browser compatibility (Chrome)
    setTimeout(() => { document.execCommand('selectall', null, "false"); });
  };

  getMovements(clientId: number) {
    this.movementsService.getMovements(0, clientId, 1, 0, 0, 1, 'DESC', 1).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.listOfMovements = resp.movementDetails.movements;

        //check if any contacts are listed in movements
        this.listOfMovements.forEach(movement => {

          this.listOfMovementIds.push(movement.outOfOfficeId)

        });

      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }
    });
  }

  getTemplates(clientId: number) {
    this.templatesService.getTemplates(0, '0', this.officeId, clientId, 0, 1).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.listOfTemplates = resp.templates;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }

    });
  }

  callerUpdate(callerData){
  
   this.callerLength = callerData.length;

  }

  phoneUpdate(phoneData){
   
   this.phoneLength = phoneData.length;

  }

  companyUpdate(companyData){


    if(companyData.length === 0){
      this.companyLength = 0;
    }else{
    this.companyLength = companyData.length + 10;
    }
  }
  emailUpdate(emailData){

    if(emailData.length === 0){
      this.emailLength = 0;
    }else{
    this.emailLength = emailData.length + 8;
    }

  }
  referenceUpdate(referenceData){

    if(referenceData.length === 0){
      this.referenceLength = 0;
    }else{
      this.referenceLength = referenceData.length +12;
    }

    

  
  }


  getDeliveryType() {
    this.clientsContactsService.getDeliveryType(0, '0', 1).subscribe(resp => {
      if (resp.errors.length == 0) {
        this.listOfDelivery = resp.deliveryTypes;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }

    });
  }
  getMessages(clientId: number) {


    this.messageSearchData.clientId = clientId;

    this.messagesService.getMessages(this.messageSearchData).subscribe(resp => {
      //this.listOfMessages = new MessageData();

      if (resp.errors.length == 0) {
        this.listOfMessages = resp.messageDetails.messages;
        this.noOfMessages = resp.messageDetails.totalCount;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }

    });

  }

  getContact(contact: Contact) {
    this.clientsContactsService.getContacts(contact.contactId, '0', 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {

      if (resp.errors.length == 0) {
        this.contactInfo = resp.contactDetails.contacts[0];
        console.log(this.contactInfo)
        this.newMessage.isEod = resp.contactDetails.contacts[0].isEmailAddress1Eod;
        this.rForm.controls['deliveryTypeId'].setValue(this.contactInfo.deliveryTypeId);
        this.newMessage.messageFor = this.contactInfo.fullName;
        this.contactInfo = resp.contactDetails.contacts[0];
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      }

    });
  }
  setClient(client: any) {
    console.log(client);
    this.getMessages(client.clientId);
    this.listOfMessages = [];
    this.contactInfo = new Contact();

    this.newMessage.messageFor = '';
    this.rForm.controls['dateTaken'].setValue(moment().toDate());
    this.hour = Number(moment().format('HH'));

    this.minute = Number(moment().format('mm'));
    this.rForm.controls['hour'].setValue(this.hour);
    this.rForm.controls['minute'].setValue(this.minute);

    const contactField = this.rForm.get('contactId');
    const templateId = this.rForm.get('templateId');

    if (client.clientId > 0) {
      this.newMessage.clientId = client.clientId;
      this.clientId = client.clientId;
      contactField.enable();
      templateId.enable();
      this.getClients(client.clientId, '0', '0', 0, 0, 1);

      this.getMovements(client.clientId);
      this.getTemplates(client.clientId);

      this.selectedClientId = client.clientId;

      this.newMessage.contactId = null;
      //get client details
    } else {
      contactField.disable();
      templateId.disable();
      contactField.setValue('');
    }
  }
  setContact(contactTemp: any) {
    console.log("set contact called")
    let contact = contactTemp.value;

    console.log(contact)
    const callerField = this.rForm.get('callerName');
    const messageFor = this.rForm.get('messageFor');

    if (contact.contactId > 0) {

      if(this.beforeMovement.contactId > 0){
      //this.undoMovement();
      }

      this.newMessage.contactId = contact.contactId;

      this.beforeMovement = contact;

      this.selectedContact = contact;

      this.newMessage.messageFor = contact.fullName;
      this.contactInfo = contact;
      console.log(this.contactInfo)
      this.newMessage.isEod = contact.isEmailAddress1Eod;
      this.rForm.controls['deliveryTypeId'].setValue(contact.deliveryTypeId);

      
      if (this.listOfMovementIds.includes(contact.contactId)) {


        console.log(this.listOfMovements.find(e => e.outOfOfficeId === contact.contactId));
        //change to this user
        this.contactMovement = this.listOfMovements.find(e => e.outOfOfficeId === contact.contactId);


        this.outOfOfficeContact =this.listOfContacts.find(e => e.contactId === this.contactMovement.forwardToId);
        this.rForm.controls['contactId'].setValue(this.outOfOfficeContact );
  

        this.clientsContactsService.getContacts(this.contactMovement.forwardToId, '0', 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {
          this.rForm.controls['deliveryTypeId'].setValue(resp.contactDetails.contacts[0].deliveryTypeId);
          this.selectedContact = resp.contactDetails.contacts[0];
          this.contactInfo = resp.contactDetails.contacts[0];

        });

      }


    } else {

    }
  }


  setTemplate(template) {
    //console.log(template);
    if (template.templateId > 0) {
      this.newMessage.message = template.message;
    }
  }

  reset() {
    this.rForm.reset();
    this.rForm.markAsPristine();
    this.rForm.markAsUntouched();

    this.newMessage = new NewMessage();
    this.myControlContacts.setValue({ contactId: null, contactName: null });
    this.listOfContacts = null;
    this.rForm.controls['messageTypeId'].setValue(1);
    this.filteredClients = null;
    this.filteredContacts = null;
    this.selectedContact = new Contact();
    this.viewClientData = false;
    this.rForm.controls['dateTaken'].setValue(moment());

    this.contactInfo = new Contact();
    let i: number = 0;

    for (i = 0; i < 60; i++) {
      if (i < 10) {
        this.minutes.push({ id: i, viewValue: '0' + i });
      } else {
        this.minutes.push({ id: i, viewValue: String(i) });
      }
    }

    this.hour = Number(moment().format('HH'));

    this.minute = Number(moment().format('mm'));
    this.rForm.controls['hour'].setValue(this.hour);
    this.rForm.controls['minute'].setValue(this.minute);

    this.contactMovement = null;
  }

  viewContactDialog(contacts): void {

    let dialogRef = this.dialog.open(ViewContactsDialogComponent, {
      width: '80%',
      data: { contactsData: contacts }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result.value.contactId > 0) {
        this.newMessage.contactId = result.value.contactId;
        console.log(result.value)
        this.rForm.controls['contactId'].setValue(result.value);
        this.newMessage.messageFor = result.value.fullName;
        let contactTemp = { 'value': result.value }
        this.setContact(result)
      }
    });

  }

  viewMessagesDialog(message): void {

    let dialogRef = this.dialog.open(ViewMessageDialogComponent, {
      width: '1000px',
      data: { messageData: message }
    });
    dialogRef.afterClosed().subscribe(result => {

    });

  }


  copyLink(text: string) {
    var event = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      document.removeEventListener('copy', event);
      this.openSnackBar("Copied to clipboard");
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');
  }

  confirmPatchDialog(post): void {

    if (post.deliveryTypeId == 4) {
      let dialogRef = this.dialog.open(ConfirmPatchDialogComponent, {
        width: '1000px',
        data: { post: post }
      });
      dialogRef.afterClosed().subscribe(result => {

        if (result === null) {

        } else {
          this.sendMessage(this.rForm.value);
        }

      });
    } else {
      this.sendMessage(this.rForm.value);
    }
  }
  sendMessage(post: any) {

    console.log(post);

    let tempLimit = Number(this.smsLimit) - (Number(this.callerLength) + Number(this.phoneLength) + Number(this.companyLength) + Number(this.emailLength) + Number(this.referenceLength));

    if(this.newMessage.deliveryTypeId === 1 && this.newMessage.message.length + this.callerLength + this.phoneLength + this.companyLength + this.emailLength + this.referenceLength > this.smsLimit || this.newMessage.deliveryTypeId === 3 && this.newMessage.message.length + this.callerLength + this.phoneLength + this.companyLength + this.emailLength + this.referenceLength > this.smsLimit){
      this.openSnackBarError("Message is too long for SMS delivery, please edit to be " + tempLimit + " characters or less.");
    }else{

    this.showForm = false;
    this.openSnackBarError("Sending Message...");

    this.newMessage.clientId = this.selectedClient.clientId;
    this.newMessage.dateTaken = moment(post.dateTaken).set('hour', this.hour).set('minutes', this.minute)
    this.newMessage.dateTaken = moment(this.newMessage.dateTaken);

    this.newMessage.contactId = this.selectedContact.contactId;
    this.newMessage.messageTypeId = 1;

    if (post.callerName.callerName) {
      this.newMessage.callerName = post.callerName.callerName;
    } else {
      this.newMessage.callerName = post.callerName;
    }

    if (post.phoneNo == null) {
      this.newMessage.phoneNo = '';
    } else {
      this.newMessage.phoneNo = post.phoneNo;
    }
    if (post.email == null) {
      this.newMessage.email = '';
    } else {
      this.newMessage.email = post.email;
    }
    if (post.reference == null) {
      this.newMessage.reference = '';
    } else {
      this.newMessage.reference = post.reference;
    }
    if (post.company == null) {
      this.newMessage.company = '';
    } else {
      this.newMessage.company = post.company;
    }

    let successString: string = '';
    //get delivery method
    if (this.newMessage.deliveryTypeId == 1) {

      successString = "Message successfully sent via SMS to " + this.contactInfo.mobileNo;

    } else if (this.newMessage.deliveryTypeId == 2) {

      successString = "Message successfully sent via Email to " + this.contactInfo.emailAddress1;

    } else if (this.newMessage.deliveryTypeId == 3) {

      successString = "Message successfully sent via SMS Email to " + this.contactInfo.mobileNo + " and " + this.contactInfo.emailAddress1;

    } else if (this.newMessage.deliveryTypeId == 4) {

      successString = "Call patch saved.";

    } else if (this.newMessage.deliveryTypeId == 4) {

      successString = "Message saved as No Message.";

    } else if (this.newMessage.deliveryTypeId == 5) {

      successString = "Message saved as No Message.";

    }
    this.newMessage.isEod = true;
    //get details



    this.messagesService.sendMessage(this.newMessage).subscribe(resp => {

      if (resp.errors.length == 0) {
        this.openSnackBar(successString);
        if (this.navRoleJournal) {
          this.journalsService.getJournals(this.me.userId, 0, 0, 0, 0, 1).subscribe(resp => {

            if (resp.errors.length > 0) {
              this.openSnackBarError(resp.errors[0].error);
            } else {
              console.log("message action : " + resp.messageId.totalCount);

              //this.journalsService.updateJournal(resp);

            }
          });
        }
        //this.reset();
        this.reset();

        this.showForm = true;
      } else {
        this.openSnackBarError(resp.errors[0].error);
        this.showForm = true;
      }

    });
  }

  }

  public submit(data, event) {
    switch (event) {
      case 'GET': this.confirmPatchDialog(data); break;
      case 'POST': this.reset(); break;
      default: break;
    }
  }
}
