import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { MessagesService } from '../../services/messages.service';

import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';
import { MessageSend, NewMessage, MessageData, MessageDetails, Messages, MessageEmailReport } from '../../models/messageData';
import { DeliveryData, DeliveryTypes, DeliveryType } from '../../models/deliveryData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { CallerData, Callers } from '../../models/callerData';

@Component({
  selector: 'app-email-message-report-dialog',
  templateUrl: './email-message-report-dialog.component.html',
  styleUrls: ['./email-message-report-dialog.component.css'],
  providers: [MessagesService, ClientsContactsService]
})
export class EmailMessageReportDialogComponent implements OnInit {
  rForm: FormGroup;
  messageEmailReportData: MessageEmailReport;
  noOfMessages: number;

  constructor(public dialogRef: MatDialogRef<EmailMessageReportDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public messagesService: MessagesService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({

      'message': [null],

    });
   }

   openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 5000,
    });
  }

  ngOnInit() {

    this.messageEmailReportData = new MessageEmailReport();
    this.noOfMessages = this.data.noOfMessages;
    setTimeout(() => {
      if(this.noOfMessages > 500){

        this.openSnackBar("Email reports can only send up to 500 records, please reduce the amount of messages in the search.");
      }
    },500);
    
console.log(this.data.reportData);
    this.messageEmailReportData = this.data.reportData;
  }


  sendReport(form:any){

      this.messagesService.endOfDeliveryForClient(this.messageEmailReportData).subscribe(resp => {

        if (resp.errors.length == 0) {
          this.openSnackBar("Email report sent");
          this.dialogRef.close();
        } else {
          this.openSnackBar(resp.errors[0].error);
          this.dialogRef.close();
        }
      });
  }

}
