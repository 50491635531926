export class InvoiceProductsData{
    errors: any[] | null;
    invoiceProducts: InvoiceProducts | null;
}
export class InvoiceProducts{
    products: Products[] | null;
    totalCount: number | null;
}
export class Products{
    code: string;
    createdBy: string;
    createdById: number;
    createdDate: any;
    description: string;
    includedMessages: number;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: any;
    lastUpdatedDate: any;
    messageCost: number;
    officeId: number;
    officeName: string;
    productCode: string;
    productId; number;
    productName: string;
    productTypeId: number;
    productTypeName: string;
    rate: any;
    taxId: number;
    totalCount: number;
    unitCost: number;
    isGlobal: boolean;
    isDisplayInvoice: boolean = true;
}
export class Product{
    code: string;
    createdBy: string;
    createdById: number;
    createdDate: any;
    description: string;
    includedMessages: number;
    isActive: boolean;
    lastUpdatedBy: string;
    lastUpdatedById: any;
    lastUpdatedDate: any;
    messageCost: number;
    officeId: number;
    officeName: string;
    productCode: string;
    productId; number;
    productName: string;
    productTypeId: number;
    productTypeName: string;
    rate: any;
    taxId: number;
    totalCount: number;
    unitCost: number;
    isGlobal: boolean;
    isDisplayInvoice: boolean = true;
}
export class InvoiceRecurringProductsData{
    errors: any[] | null;
    recurringProducts: RecurringProducts[] | null;
}
export class RecurringProducts{
    clientId: number;
    clientName: string
    productCode: string;
    productId: number;
    productName: string;
}
export class RecurringProduct{
    clientId: number;
    clientName: string
    productCode: string;
    productId: number;
    productName: string;
}