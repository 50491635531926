import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';


import * as moment from 'moment';
import { EmailValidator } from '@angular/forms';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';
import { UserSessionService } from '../../services/user-session.service';
import { TemplatesService } from '../../services/templates.service';
import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, InvoiceSettings } from '../../models/invoiceData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';


import { SourceTypeData, SourceTypes, SourceType } from '../../models/sourceData';
import { PaymentTypesData, PaymentTypes, PaymentType } from '../../models/paymentData';
import { TaxData, TaxCodes, TaxCode } from '../../models/taxData';

import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete, SubAccount } from '../../models/clientData';
import { OfficeData, OfficeDetails, Offices, Office } from '../../models/officeData';
import { MeData, Me } from '../../models/meData';

import { ContactsComponent } from '../../components/contacts/contacts.component';
import { ClientRecurringProductsComponent } from '../../components/client-recurring-products/client-recurring-products.component';

import { ClientInvoicingComponent } from '../../components/client-invoicing/client-invoicing.component';

import { ClientMessagesComponent } from '../../components/client-messages/client-messages.component';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';

import { TemplatesComponent } from '../../components/templates/templates.component';
import { ClientTemplatesComponent } from '../../components/client-templates/client-templates.component';
import { ClientMovementsComponent } from '../../components/client-movements/client-movements.component';

import { AddMovementDialogComponent } from '../../components/add-movement-dialog/add-movement-dialog.component';
import { ViewMovementDialogComponent } from '../../components/view-movement-dialog/view-movement-dialog.component';
import { AddInvoiceDialogComponent } from '../../components/add-invoice-dialog/add-invoice-dialog.component';
import { AddCreditNoteDialogComponent } from '../../components/add-credit-note-dialog/add-credit-note-dialog.component';
import { ClientSentInvoicesComponent } from '../../components/client-sent-invoices/client-sent-invoices.component';

import { AddContactDialogComponent } from '../../components/add-contact-dialog/add-contact-dialog.component';
import { AssignRecurringProductDialogComponent } from '../../components/assign-recurring-product-dialog/assign-recurring-product-dialog.component';

import { AddTemplateDialogComponent } from '../../components/add-template-dialog/add-template-dialog.component';
import { BillingData, Billings } from '../../models/billingData';
import { eodData } from '../../models/endOfDayClient';


@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css'],
  providers: [ClientsContactsService, OfficesUsersRolesService, UserSessionService, TemplatesService, InvoicingService]
})
export class ClientDetailComponent implements OnInit, AfterViewInit {
  eodData: eodData;
  listOfClients: ClientData;
  listOfOffices: Offices[];
  selectedClient: any;
  rForm: FormGroup;
  message: string;
  temp: any;
  selectedUser: MeData;
  userOffice: number = 0;
  selectedOffices: string;
  sourceTypes: SourceTypes[];
  paymentTypes: PaymentTypes[];
  taxCodes: TaxCodes[];
  formLoading: boolean;
  selectedTab: number;
  loadingContent: boolean = true;
  clientId: number;
  filteredClients: ClientAutocomplete[] = [];
  filteredContacts: ContactAutocomplete[] = [];
  selectedClientId: number = 0;
  officeIdSubject: Observable<string>;
  officeId: number;
  listOfBillingTypes: Billings[];
  me: any;
  navRoleInvoicing: boolean = false;
  navRoleHeadOffice: boolean = false;
  navRoleJournal: boolean = false;
  navRoleClientAdmin: boolean = false;
  listOfTariffs: Products[] = [];
  listOfSubAccounts: SubAccount[] = [];
  tabId: number = 0;
  listOfQuarterMonths: any[];
  listOfMonths: any[];
  primaryOwnerId: number;
  officesLoaded: boolean = false;
  @ViewChild(ClientMessagesComponent) clientMessagesComponent: ClientMessagesComponent;
  @ViewChild(TemplatesComponent) templatesComponent: TemplatesComponent;
  @ViewChild(ClientTemplatesComponent) clientTemplatesComponent: ClientTemplatesComponent;
  @ViewChild(ClientMovementsComponent) clientMovementsComponent: ClientMovementsComponent;
  @ViewChild(ContactsComponent) contactsComponent: ContactsComponent | undefined;
  @ViewChild(ClientInvoicingComponent) clientInvoicingComponent: ClientInvoicingComponent;
  @ViewChild(ClientSentInvoicesComponent) clientSentComponent: ClientSentInvoicesComponent;
  @ViewChild(ClientRecurringProductsComponent) clientRecurringProductsComponent: ClientRecurringProductsComponent;

  get offices(): FormArray {
    return <FormArray>this.rForm.get('offices');
  }

  constructor(public dialog: MatDialog, public snackBar: MatSnackBar, public invoicingService: InvoicingService, public clientsContactsService: ClientsContactsService, public officesUsersRolesService: OfficesUsersRolesService, public userSessionService: UserSessionService, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) {

    this.officeIdSubject = userSessionService.getOfficeId();



    this.rForm = fb.group({
      'clientName': [null, Validators.required],
      'clientAcronym': [{ value: '', disabled: true }, Validators.required],
      'clientCode': ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
      'addressLine1': [null, Validators.required],
      'addressLine2': [null],
      'addressLine3': [null],
      'townCity': [false, Validators.required],
      'postCode': [false],
      'country': [false],
      'county': [false],
      'isTrial': [false],
      'trialClosedDate': [null],
      'serviceStartDate': [null],
      'billingDate': [{ value: null, disabled: false }, Validators.required],
      'billingFrequencyId': [null],
      'isActive': [false],
      'clientId': [0, Validators.required],
      'closeDate': [null],
      'sourceTypeId': [null],
      'internalDDI': [false],
      'phoneNo': [false],
      'faxNo': [false],
      'emergencyNo': [null],
      'privateUrl': [null],
      'webUrl': [null],
      'formUrl': [null],
      'answerScript': [null],
      'instruction': [null],
      'hangUpScript': [null],
      'notes': [null],
      'mainContactId': [null],
      'paymentTypeId': [null],
      'taxId': [null],
      'isOutOfHours': [false],
      'masterAccountId': [null],
      'tariffId': [null],
      'isVatExempt': [false],
      offices: this.fb.array([]),
      'clientAutocomplete': new FormControl(),
      'contactAutocomplete': new FormControl(),
      'isInvoiceScheduled': [null],
      'invoiceToEmails': [null],
      'financeContact': [null],
      'monthInQuarter': [{ value: 1}],
      'monthNumber': [{ value: 1}],
    });

    this.rForm.controls['clientAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientAutocomplete'].value);
        if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 1, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }
      });

    this.rForm.controls['contactAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['contactAutocomplete'].value);
        if (this.rForm.controls['contactAutocomplete'].value === '' || typeof this.rForm.controls['contactAutocomplete'].value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.rForm.controls['contactAutocomplete'].value, 0, this.clientId, 0)
            .subscribe(res => {
              return this.filteredContacts = res.contacts
            })
        }
      });
  }

  

  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }
  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    console.log('tabChangeEvent => ', tabChangeEvent);
    console.log('index => ', tabChangeEvent.index);
    this.selectedTab = tabChangeEvent.index;
    this.router.navigate(['/clients', this.selectedClient.clientId, 'tab', tabChangeEvent.index]);
    if (tabChangeEvent.index === 0) {
      console.log("Client");
      this.getSourceTypes();
      this.getTaxCodes();
      this.getPaymentTypes();
      this.getBillingFrequency();
      this.getSubAccounts(this.clientId);
      this.getTariffs(this.officeId);
    }
    if (tabChangeEvent.index === 1) {
      console.log("Contacts");
      this.contactsComponent.clientId = this.clientId;
      this.contactsComponent.getContacts(this.clientId, 1, 1);
    }
    if (tabChangeEvent.index === 2) {
      console.log("Messages");
      this.clientMessagesComponent.clientId = this.clientId;
      this.clientMessagesComponent.getMessages(this.clientId, 0, 0, 1);
      this.clientMessagesComponent.getDeliveryTypes();
    }
    if (tabChangeEvent.index === 3) {
      console.log("Movements");
      this.clientMovementsComponent.clientId = this.clientId;
      this.clientMovementsComponent.getMovements(this.clientId, 1, 1);
    }
    if (tabChangeEvent.index === 4) {
      console.log("Templates");
      this.clientTemplatesComponent.clientId = this.clientId;
      this.clientTemplatesComponent.getTemplates(0, this.officeId,  this.clientId, 2, 1);
    }
    if (tabChangeEvent.index === 5) {
      console.log("Invoices");
      this.clientInvoicingComponent.clientId = this.clientId;
      this.clientInvoicingComponent.getInvoices(this.clientId, 0, 0, 1, 4, 'DESC', 1);
      this.clientInvoicingComponent.getInvoiceStatus();
    }
    if (tabChangeEvent.index === 6) {
      console.log("Recurring Products");
      this.clientRecurringProductsComponent.clientId = this.clientId;
      this.clientRecurringProductsComponent.getRecurringProducts(this.clientId)
    }
    if (tabChangeEvent.index === 7) {
      console.log("Sent Invoices");
      this.clientSentComponent.clientId = this.clientId;
      this.clientSentComponent.getSentInvoices(this.clientId, 1);
    }
  }

  setClient(client) {
    if (client.clientId > 0) {
      this.selectedClientId = client.clientId;
    } else {
      this.selectedClientId = null;
    }
  }
  setDates(billingId){
console.log(billingId);
    if(billingId === 3){
      this.listOfMonths = [
        {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
        {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6}
        
      ]
    }else{
      this.listOfMonths = [
        {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
        {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6},
        {id: 7, value: 7}, {id: 8, value: 8}, {id: 9, value: 9},
        {id: 10, value: 10}, {id: 11, value: 11}, {id: 12, value: 12}
        
      ]
    }

    if(billingId === 2){
      if(this.selectedClient.monthInQuarter === null){
        this.selectedClient.monthInQuarter = 1;
      }
    }else if(billingId === 3 || billingId === 4){
      if(this.selectedClient.monthNumber === null){
        this.selectedClient.monthNumber = 1;
      }
    }

  }
  setMainContact(contact) {
    console.log(contact.contactId)
    this.selectedClient.mainContactId = contact.contactId;
  }

  billingFilter = (d: Date): boolean => {
    console.log(d);
    d = moment(d).toDate();
    const day = d.getDate();
    // Prevent Saturday and Sunday from being selected.
    return day !== 29 && day !== 30 && day !== 31;
  }

  getSubAccounts(clientId: number) {

    this.clientsContactsService.getClientSubAccounts(clientId).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfSubAccounts = resp.clients;
        console.log("sub len " + this.listOfSubAccounts.length);
      }
    })
  }
  onSelectChange = ($event: any): void => {

    console.log($event);
    this.clientMessagesComponent.getMessages(this.clientId, 0, 0, 1);

    this.clientMessagesComponent.clientId = this.clientId;
    this.clientTemplatesComponent.clientId = this.clientId;
    this.clientTemplatesComponent.getTemplates(0, this.officeId, this.clientId, 0, 1);
  }
  ngAfterViewInit()
  {
    this.listOfQuarterMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3}
    ]
    this.listOfMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
      {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6},
      {id: 7, value: 7}, {id: 8, value: 8}, {id: 9, value: 9},
      {id: 10, value: 10}, {id: 11, value: 11}, {id: 12, value: 12}
      
    ]
    this.listOfSubAccounts = [];
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.route.params.forEach(params => {
      this.clientId = parseInt(params["clientId"]);
      //this.tabId = parseInt(params["tabId"]);
      //call your function, like getUserInfo()
      this.getClients(this.clientId, '0', '0', 0, 0, 1);
    })
    //this.clientInvoicingComponent.clientId = this.clientId;
    this.tabId = +this.route.snapshot.paramMap.get('tabId');

    this.selectedTab = this.tabId;

    if (this.selectedTab === 0) {
      console.log("Client");
      this.getSourceTypes();
      this.getTaxCodes();
      this.getPaymentTypes();
      this.getBillingFrequency();
      this.getSubAccounts(this.clientId);
      this.getTariffs(this.officeId);
    }
    if (this.selectedTab === 1) {
      console.log("Contacts");
      if(this.contactsComponent)
      {
        this.contactsComponent.clientId = this.clientId;
        this.contactsComponent.getContacts(this.clientId, 1, 1);
      }
    }
  }
  ngOnInit() {
    this.listOfQuarterMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3}
    ]
    this.listOfMonths = [
      {id: 1, value: 1}, {id: 2, value: 2}, {id: 3, value: 3},
      {id: 4, value: 4}, {id: 5, value: 5}, {id: 6, value: 6},
      {id: 7, value: 7}, {id: 8, value: 8}, {id: 9, value: 9},
      {id: 10, value: 10}, {id: 11, value: 11}, {id: 12, value: 12}
      
    ]
    this.listOfSubAccounts = [];
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));

    this.route.params.forEach(params => {
      this.clientId = parseInt(params["clientId"]);
      //this.tabId = parseInt(params["tabId"]);
      //call your function, like getUserInfo()
      this.getClients(this.clientId, '0', '0', 0, 0, 1);
    })
    //this.clientInvoicingComponent.clientId = this.clientId;
    this.tabId = +this.route.snapshot.paramMap.get('tabId');

    this.selectedTab = this.tabId;

    if (this.selectedTab === 0) {
      console.log("Client");
      this.getSourceTypes();
      this.getTaxCodes();
      this.getPaymentTypes();
      this.getBillingFrequency();
      this.getSubAccounts(this.clientId);
      this.getTariffs(this.officeId);
    }
    if (this.selectedTab === 1) {
      console.log("Contacts");
      if(this.contactsComponent)
      {
        this.contactsComponent.clientId = this.clientId;
        this.contactsComponent.getContacts(this.clientId, 1, 1);
      }
    }
    if (this.selectedTab === 2) {
      console.log("Messages");
      this.clientMessagesComponent.clientId = this.clientId;
      this.clientMessagesComponent.getMessages(this.clientId, 0, 0, 1);
      this.clientMessagesComponent.getDeliveryTypes();
    }
    if (this.selectedTab === 3) {
      console.log("Movements");
      this.clientMovementsComponent.clientId = this.clientId;
      this.clientMovementsComponent.getMovements(this.clientId, 1, 1);
    }
    if (this.selectedTab === 4) {
      console.log("Templates");
      this.clientTemplatesComponent.clientId = this.clientId;
      this.clientTemplatesComponent.getTemplates(0, 0, this.clientId, 0, 1);
    }
    if (this.selectedTab === 5) {
      console.log("Invoices");
      this.clientInvoicingComponent.clientId = this.clientId;
      this.clientInvoicingComponent.getInvoices(this.clientId, 0, 0, 1, 1, 'DESC', 1);
      this.clientInvoicingComponent.getInvoiceStatus();
    }
    if (this.selectedTab === 6) {
      console.log("Recurring Products");
      this.clientRecurringProductsComponent.clientId = this.clientId;
      this.clientRecurringProductsComponent.getRecurringProducts(this.clientId)
    }
    if (this.selectedTab === 7) {
      console.log("Sent Invoices");
      this.clientSentComponent.clientId = this.clientId;
      this.clientSentComponent.getSentInvoices(this.clientId, 1);
    }
    if (this.clientMovementsComponent) {
      this.clientMovementsComponent.clientId = this.clientId;
      this.clientSentComponent.clientId = this.clientId;
    }
    this.selectedClient = new Client();


    this.getMe();

  }

  addOffice(selected, id, disabled): void {

    this.offices.push(this.buildOffices(selected, id, disabled));
  }

  buildOffices(selected, id, disabled): FormGroup {
    return this.fb.group({
      officeId: id,
      locked: disabled,
      isSelected: new FormControl({ value: selected, disabled: disabled })
    })
  }
  private getMe() {
    this.getOffices();
    this.userSessionService.getMe().subscribe(resp => {

      this.selectedUser = resp;
      this.userOffice = resp.user[0].officeId;
      
      this.me = resp.user[0];
      let arrayOfRoles = new Array();
      this.me.roles.forEach((role) => {
        arrayOfRoles.push(role.roleId);
      });
      if (arrayOfRoles.includes(2)) {
        this.navRoleClientAdmin = true;
        this.navRoleInvoicing = true;
      }
      if (arrayOfRoles.includes(4)) {
        this.navRoleInvoicing = true;
      }
      if (arrayOfRoles.includes(5)) {
        this.navRoleJournal = true;
      }
      if (arrayOfRoles.includes(6)) {

        this.navRoleHeadOffice = true;
      }
    });
  }

  updateClientAcronym($event) {

    console.log($event);

    //let tempAcronym = $event.split("-", 2); 

    let temp123 = this.rForm.get("clientAcronym").value;

    let tempCode = temp123.split("-", 3);;

    this.rForm.get("clientAcronym").setValue($event + '-' + tempCode[1] + '-' + tempCode[2]);

  }

  updateClientAcronymDate($event) {

    let newDate = moment($event).format("DDMM");

    let temp123 = this.rForm.get("clientAcronym").value;

    let tempCode = temp123.split("-", 3);

    this.rForm.get("clientAcronym").setValue(tempCode[0] + '-' + tempCode[1] + '-' + newDate);

  }

  eod() {
    this.eodData = new eodData();
    this.eodData.clientId = this.selectedClient.clientId;
    this.eodData.fromCreatedDate = (moment().toDate()).toDateString();
    this.eodData.toCreatedDate = (moment().toDate()).toDateString();

    this.clientsContactsService.endOfDelivery(this.eodData).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
     
      } else {
        this.openSnackBar("EOD Sent for Client");
      }
    });
  }

  private getSourceTypes() {
    this.clientsContactsService.getSourceType(0, 0, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.sourceTypes = resp.sourceTypes;
      }
    });
  }
  private getPaymentTypes() {
    this.clientsContactsService.getPaymentType(0, 0, 1).subscribe(resp => {
      
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.paymentTypes = resp.paymentTypes;
      }
    });
  }
  private getTaxCodes() {
    this.clientsContactsService.getTaxCode(0, 0, 1).subscribe(resp => {
     
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.taxCodes = resp.taxCodes;
      }
    });
  }
  private getBillingFrequency() {
    this.clientsContactsService.getBillingFrequency().subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfBillingTypes = resp.deliveryTypes;
      }

      
    });
  }
  private getTariffs(officeId: number) {

    this.invoicingService.getInvoiceProduct(0, 0, 0, 1, 0, 0, 1, 0, 1, 'ASC', 1).subscribe(resp => {
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {
        this.listOfTariffs = resp.invoiceProducts.products;

      }
     
    })

  }

  officeArray: number[] = new Array();

  private getClients(id: number, clientName: string, clientAcroynm: string, officeId: number, isActive: number, officePageNo: number) {
    this.clientsContactsService.getClients(id, clientName, clientAcroynm, officeId, isActive, 0, 0, 0, 0, 0, 0, 0, 1, 'ASC', 1).subscribe(resp => {
      this.listOfSubAccounts = [];

      this.getSubAccounts(this.clientId);

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
        this.userSessionService.checkToken().subscribe();
      } else {

        this.listOfClients = new ClientData();
        this.listOfClients = resp;
        this.rForm.controls['clientId'].setValue(resp.clientDetails.clients[0].clientId);
        if (moment(resp.clientDetails.clients[0].closeDate).isValid()) {
          this.rForm.controls['closeDate'].setValue(moment(resp.clientDetails.clients[0].closeDate).toDate());
        } else {
          this.rForm.controls['closeDate'].setValue(null);
        }
        if (moment(resp.clientDetails.clients[0].trialClosedDate).isValid()) {
          this.rForm.controls['trialClosedDate'].setValue(moment(resp.clientDetails.clients[0].trialClosedDate).toDate());
        } else {
          this.rForm.controls['trialClosedDate'].setValue(null);
        }
        if (moment(resp.clientDetails.clients[0].serviceStartDate).isValid()) {
          this.rForm.controls['serviceStartDate'].setValue(moment(resp.clientDetails.clients[0].serviceStartDate).toDate());
        } else {
          this.rForm.controls['serviceStartDate'].setValue(null);
        }
        if (moment(resp.clientDetails.clients[0].billingDate).isValid()) {
          this.rForm.controls['billingDate'].setValue(moment(resp.clientDetails.clients[0].billingDate).toDate());
        } else {
          this.rForm.controls['billingDate'].setValue(null);
        }
  
  
        this.selectedClientId = resp.clientDetails.clients[0].masterAccountId;
  
        let tempClient = new ClientAutocomplete();
  
        tempClient.clientName = resp.clientDetails.clients[0].masterAccountName;
        tempClient.clientId = resp.clientDetails.clients[0].masterAccountId;
  
        this.rForm.controls['clientAutocomplete'].setValue(tempClient);
  
        let tempContact = new ContactAutocomplete();
  
        tempContact.contactName = resp.clientDetails.clients[0].mainContactName;
        tempContact.contactId = resp.clientDetails.clients[0].mainContactId;
  
        this.rForm.controls['clientAutocomplete'].setValue(tempClient);
        this.rForm.controls['contactAutocomplete'].setValue(tempContact);
  
        resp.clientDetails.clients.forEach((clients, i) => {
  
          this.selectedClient = clients;
          this.selectedClient.serviceStartDate = moment(clients.serviceStartDate).toDate();
          this.selectedClient.trialClosedDate = moment(clients.trialClosedDate).toDate();
          this.selectedClient.billingDate = moment(clients.billingDate).toDate();
  
          this.selectedClient.closeDate = moment(clients.closeDate).toDate();
          this.selectedClient.serviceStartDate = moment(clients.serviceStartDate).toDate();
          let tempCode = clients.clientAcronym.split("-", 3);
          this.rForm.get("clientCode").setValue(tempCode[0]);
          this.selectedClient.clientCode = tempCode[0];
  
        });
        this.loadingContent = false;
        resp.clientDetails.clients[0].offices.forEach((office) => {
  
          if(office.isPrimaryOwner){
            this.primaryOwnerId = office.officeId;
          }
          this.officeArray.push(office.officeId);
        });
  
        console.log(this.officeArray);
       
      }
    });

  }

  public getOffices() {
    
    this.officesUsersRolesService.getOffices(0, '0', 1, 1).subscribe(resp => {
      console.log("called now---------123")


      setTimeout(()=>{    //<<<---    using ()=> syntax
    


      resp.officeDetails.offices.map((office) => {

        console.log(this.officeArray.includes(office.officeId));

        if (office.officeId == this.userOffice) {
          // office.isSelected = true;
          console.log("called now------------")
          this.addOffice(true, office.officeId, true);

        } else if (this.officeArray.includes(office.officeId)) {
          //office.isSelected = false;
          this.addOffice(true, office.officeId, false);
        } else {

          this.addOffice(false, office.officeId, false);
        }
        //return office;
      });
      this.officesLoaded = true;
    }, 800);
      
    this.listOfOffices = resp.officeDetails.offices;

     // return this.listOfOffices;

    }
    );

  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  updateClient(post) {
    this.formLoading = true;

    this.selectedOffices = '';
    post.offices.forEach((office, i) => {
      if (!office.hasOwnProperty("isSelected")) {
        this.selectedOffices += office.officeId + ",";
      } else {
        if (office.isSelected) {
          this.selectedOffices += office.officeId + ",";
        }
      }
    });

    if (moment(post.closeDate).isValid()) {
      this.selectedClient.closeDate = post.closeDate;

    }
    if (moment(post.trialClosedDate).isValid()) {
      this.selectedClient.trialClosedDate = post.trialClosedDate;
    }
    if (moment(post.billingDate).isValid()) {
      this.selectedClient.billingDate = post.billingDate;
    }

    if (moment(post.serviceStartDate).isValid()) {
      this.selectedClient.serviceStartDate = post.serviceStartDate;
    }
    this.selectedOffices = this.selectedOffices.slice(0, -1);
    if (this.selectedClientId > 0) {
      this.selectedClient.masterAccountId = this.selectedClientId;
    } else {
      this.selectedClient.masterAccountId = null;
    }

    this.clientsContactsService.updateClient(this.selectedClient.clientId, this.selectedOffices, this.selectedClient).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Client updated");
      }

      this.formLoading = false;
    });
  }

  addTemplateDialog(): void {

    let dialogRef = this.dialog.open(AddTemplateDialogComponent, {
      width: '1000px',
      data: { officeId: this.officeId, clientId: this.clientId, isGlobal: false, clientName: this.selectedClient.clientName }
    });
    dialogRef.afterClosed().subscribe(result => {

      this.selectedTab = 4;
      console.log(result);
      this.clientTemplatesComponent.getTemplates(0, 0,  this.clientId, 2, 1);
      //this.usersComponent.triggerUpdate();
    });

  }

  addMovementDialog(): void {

    let dialogRef = this.dialog.open(AddMovementDialogComponent, {
      width: '1000px',
      data: { clientId: this.clientId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.clientMovementsComponent.getMovements(this.clientId, 1, 1);
      this.selectedTab = 3;

      //console.log(result);
      //this.usersComponent.triggerUpdate();
    });
  }

  addContactDialog(): void {

    let dialogRef = this.dialog.open(AddContactDialogComponent, {
      width: '1000px',
      data: { clientId: this.clientId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.contactsComponent.getContacts(this.clientId, 1, 1);
      this.selectedTab = 1;
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  addInvoiceDialog(): void {

    let dialogRef = this.dialog.open(AddInvoiceDialogComponent, {
      width: '400px',
      data: { clientId: this.clientId, officeId: this.officeId, invoiceTypeId: 1 }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.contactsComponent.getContacts(this.clientId, 1);
      this.selectedTab = 5;
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });

  }

  addCreditDialog(): void {

    let dialogRef = this.dialog.open(AddCreditNoteDialogComponent, {
      width: '500px',
      data: { clientId: this.clientId }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.contactsComponent.getContacts(this.clientId, 1);
      this.selectedTab = 5;
      console.log(result);
      //this.usersComponent.triggerUpdate();
    });
  }

  assignRecurringProductDialog(): void {

    let dialogRef = this.dialog.open(AssignRecurringProductDialogComponent, {
      width: '50%',
      data: { clientId: this.clientId, officeId: this.officeId }
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.contactsComponent.getContacts(this.clientId, 1);
      this.selectedTab = 6;
      this.clientRecurringProductsComponent.getRecurringProducts(this.clientId)
    });
  }
}
