import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject , Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormsModule } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';




import { ClientsContactsService } from '../../services/clients-contacts.service';

import { ContactData, ContactDetails, Contacts, Contact } from '../../models/contactData';


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
  providers: [ClientsContactsService]
})
export class ContactsComponent implements OnInit {
  listOfContacts: any;
  noOfContacts: any;
  clientId: number = 0;

  isActiveFilter:number = 1;
  activeStates = [
    {value: 0, viewValue: 'All'},
    {value: 1, viewValue: 'Active'},
    {value: 2, viewValue: 'Inactive'}
  ];

  constructor(public clientsContactsService: ClientsContactsService, private router: Router,  private route: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.clientId = +this.route.snapshot.paramMap.get('clientId');

    //this.getContacts(this.clientId , this.isActiveFilter, 1);
  }

  reset(){

    this.isActiveFilter = 1;
    this.getContacts(this.clientId , this.isActiveFilter, 1);
  }

  pageEvent: PageEvent;
  setPage(event) {
    console.log(event);
    this.getContacts(this.clientId , this.isActiveFilter, event.pageIndex + 1);
   
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }


  copyLink(text:string) {
    var event = (e: ClipboardEvent) => {
        e.clipboardData.setData('text/plain', text);
        e.preventDefault();
        document.removeEventListener('copy', event);
        this.openSnackBar("Copied to clipboard");
    }
    document.addEventListener('copy', event);
    document.execCommand('copy');
}

  getContacts(clientId: number, isActive: number, pageNo: number) {
    this.clientsContactsService.getContacts(0, '0', clientId, isActive, 0, 0, 1, 'ASC', pageNo).subscribe(resp => {
      this.noOfContacts = resp.contactDetails.totalCount;
      this.listOfContacts = resp.contactDetails.contacts;


    });
  }
}
