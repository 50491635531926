import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, InvoiceSettings, InvoiceSetting } from '../../models/invoiceData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';

@Component({
  selector: 'app-assign-recurring-product-dialog',
  templateUrl: './assign-recurring-product-dialog.component.html',
  styleUrls: ['./assign-recurring-product-dialog.component.css'],
  providers: [InvoicingService]
})
export class AssignRecurringProductDialogComponent implements OnInit {
  rForm: FormGroup;
  clientId: number = 0;
  officeId: number = 0;
  productId: number = 0;
  listOfRecurringProducts: Products[] = [];
  qty: number = 1;

  constructor(public dialogRef: MatDialogRef<AssignRecurringProductDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public invoicingService: InvoicingService, public snackBar: MatSnackBar) { 

    this.rForm = fb.group({
      
            'productId': [ Validators.required],
            'qty': [Validators.required],
          });
  }

  ngOnInit() {

    this.clientId = this.data.clientId;
    this.officeId = this.data.officeId;

    this.getRecurringProducts(this.officeId);
  }

  getRecurringProducts(officeId: number){

    this.invoicingService.getInvoiceProduct(0, 0, 0, 2, officeId, 0, 1, 1, 1, 'ASC', 0).subscribe(resp => {

      this.listOfRecurringProducts = resp.invoiceProducts.products;

    })
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  assignProductClient(post){

    this.invoicingService.assignInvoiceRecurringProduct(this.productId, this.clientId, this.qty).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Recurring product assigned");
        this.dialogRef.close();
      }


    })


    
  }

}
