import { Component, OnInit, Inject } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';

import { ClientsContactsService } from '../../services/clients-contacts.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData } from '../../models/invoiceData';

import { PaymentTypesData, PaymentTypes, PaymentType} from '../../models/paymentData';
import { InvoiceProductsData, InvoiceProducts, Products, Product } from '../../models/invoicingProductsData';


@Component({
  selector: 'app-change-invoice-tariff-dialog',
  templateUrl: './change-invoice-tariff-dialog.component.html',
  styleUrls: ['./change-invoice-tariff-dialog.component.css'],
  providers: [InvoicingService, ClientsContactsService]
})
export class ChangeInvoiceTariffDialogComponent implements OnInit {
  rForm: FormGroup;
  invoiceData: Invoice;
  tariffId: number = 0;
  listOfTariffs: Products[] = [];

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ChangeInvoiceTariffDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public clientsContactsService: ClientsContactsService, public snackBar: MatSnackBar) {

    this.rForm = fb.group({
      'tariffId': ['', Validators.required]
    });

   }

  ngOnInit() {

    this.invoiceData = this.data.invoice;
    this.getTariffs();
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getTariffs(){

    this.invoicingService.getInvoiceProduct(0, 0, 0, 1, 0, 0, 1, 0, 1, 'ASC', 1).subscribe(resp => {


      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
    
      } else {

        this.listOfTariffs = resp.invoiceProducts.products;

      }
    })


  }

  changePayment(post){

    this.invoicingService.changeInvoiceTariff(this.invoiceData.invoiceId, this.tariffId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.openSnackBar("Invoice tariff updated");

        this.dialogRef.close();

      }
    })

  }

}
