import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';

import { InvoicingService } from '../../services/invoicing.service';
import { InvoiceData, Invoices, Invoice, LineItems, AddInvoiceData } from '../../models/invoiceData';

@Component({
  selector: 'app-add-credit-note-dialog',
  templateUrl: './add-credit-note-dialog.component.html',
  styleUrls: ['./add-credit-note-dialog.component.css'],
  providers: [InvoicingService]
})
export class AddCreditNoteDialogComponent implements OnInit {
  clientId: number = 0;

  constructor(private router: Router, private route: ActivatedRoute, public dialogRef: MatDialogRef<AddCreditNoteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public invoicingService: InvoicingService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.clientId = this.data.clientId;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  add(clientId: number) {

    this.invoicingService.raiseBlankCreditNote(clientId).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {

        this.openSnackBar("Credit note created");
        this.router.navigate(['/invoicing/invoice/' + resp.invoiceId]);
        this.dialogRef.close();

      }
    })
  }
}
