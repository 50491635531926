import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import * as moment from 'moment';


import { TemplatesService } from '../../services/templates.service';
import { UserSessionService } from '../../services/user-session.service';
import { ClientsContactsService } from '../../services/clients-contacts.service';


import { ClientData, ClientAutocomplete } from '../../models/clientData';
import { TemplateData, Templates, Template } from '../../models/templateData';


@Component({
  selector: 'app-add-template-dialog',
  templateUrl: './add-template-dialog.component.html',
  styleUrls: ['./add-template-dialog.component.css'],
  providers: [TemplatesService, UserSessionService, ClientsContactsService]
})
export class AddTemplateDialogComponent implements OnInit {
  rForm: FormGroup;
  newTemplate: Template;
  officeIdSubject: Observable<string>;
  filteredClients: ClientAutocomplete[] = [];

  constructor(public dialogRef: MatDialogRef<AddTemplateDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, public clientsContactsService: ClientsContactsService, public userSessionService: UserSessionService, public templatesService: TemplatesService, public snackBar: MatSnackBar) {
    this.officeIdSubject = userSessionService.getOfficeId();

    this.rForm = fb.group({

      'message': [null, Validators.required],
      'officeId': [null],
      'clientId': [{ value: null, disable: true }, Validators.required],
      'isGlobal': [true],
      'deliveryTypeId': [1],

    });

    this.rForm.controls['clientId'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientId'].value);
        if (this.rForm.controls['clientId'].value === '' || typeof this.rForm.controls['clientId'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientId'].value, 0, this.newTemplate.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }

      });

  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }

  ngOnInit() {
    this.newTemplate = new Template();
    this.newTemplate.isGlobal = this.data.isGlobal;
    this.newTemplate.deliveryTypeId = 1;
    this.newTemplate.officeId = this.data.officeId;
    
    if(this.data.clientId > 0){
      this.newTemplate.clientId = this.data.clientId;
      this.rForm.controls['clientId'].setValue({clientId: this.data.clientId, clientName: this.data.clientName})

    }
    //this.officeIdSubject.subscribe(id => this.newTemplate.officeId = Number(id));
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }
  setClient(client) {

    if (client.clientId > 0) {
      this.newTemplate.clientId = client.clientId;
    }
  }
  globalChange(event: Event) {
    const ctrl = this.rForm.get('clientId');

    if (event) {
      ctrl.disable();
    } else {

      ctrl.enable();
    }
  }
  addTemplate(post) {

    this.templatesService.addTemplates(this.newTemplate).subscribe(resp => {

      //this.addInfo = resp;
      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);

      } else {

        this.openSnackBar("Template Added");
        this.dialogRef.close();

      }
    });

  }
}
