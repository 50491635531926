import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';



import { ClientData, ClientDetails, Clients, Client, ClientsAutocomplete, ClientAutocomplete } from '../../models/clientData';
import { ContactData, ContactDetails, Contacts, Contact, ContactsAutocomplete, ContactAutocomplete } from '../../models/contactData';
import { UserSessionService } from '../../services/user-session.service';

import { OfficesUsersRolesService } from '../../services/offices-users-roles.service';

import { OfficeData, OfficeDetails, Offices } from '../../models/officeData';
import { CallerData, Callers } from '../../models/callerData';

import { ClientsContactsService } from '../../services/clients-contacts.service';
import { MessagesService } from '../../services/messages.service';

import { AddOfficeDialogComponent } from '../../components/add-office-dialog/add-office-dialog.component';

import { OfficesComponent } from '../../components/offices/offices.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [ClientsContactsService, MessagesService, OfficesUsersRolesService]
})
export class AdminComponent implements OnInit {
  listOfOffices: Offices[];
  noOfOffices: any;
  filteredData: Observable<any[]>; // async pipe needs to be an Observable
  isActiveFilter: number = 1;
  myformGroup: FormGroup;
  rForm: FormGroup;
  filteredClients: ClientAutocomplete[] = [];
  filteredContacts: ContactAutocomplete[] = [];
  filteredCallers: Callers[] = [];
  officeIdSubject: Observable<string>;
  officeId: number;

  selectedClientId: number = 0;

  @ViewChild(OfficesComponent) officeComponent: OfficesComponent;

  constructor(public clientsContactsService: ClientsContactsService, public userSessionService: UserSessionService, public officesUsersRolesService: OfficesUsersRolesService, public messagesService: MessagesService, private fb: FormBuilder, public dialog: MatDialog, public snackBar: MatSnackBar) {
    this.officeIdSubject = userSessionService.getOfficeId();

    this.rForm = fb.group({
      'clientAutocomplete': new FormControl(),
      'contactAutocomplete': new FormControl(),
      'callerAutocomplete': new FormControl()
    });
    this.rForm.controls['clientAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['clientAutocomplete'].value);
        if (this.rForm.controls['clientAutocomplete'].value === '' || typeof this.rForm.controls['clientAutocomplete'].value != 'string') {
          return this.filteredClients = null;
        } else {
          this.clientsContactsService.getClientsAutocomplete(this.rForm.controls['clientAutocomplete'].value, 0, this.officeId, 0, 0)
            .subscribe(res => {
              return this.filteredClients = res.clients
            })
        }
      });

    this.rForm.controls['contactAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['contactAutocomplete'].value);
        if (this.rForm.controls['contactAutocomplete'].value === '' || typeof this.rForm.controls['contactAutocomplete'].value != 'string') {
          return this.filteredContacts = null;
        } else {
          this.clientsContactsService.getContactsAutocomplete(this.rForm.controls['contactAutocomplete'].value, 0, this.selectedClientId, 0)
            .subscribe(res => {
              return this.filteredContacts = res.contacts
            })
        }
      });

    this.rForm.controls['callerAutocomplete'].valueChanges
      .subscribe(name => {
        console.log(this.rForm.controls['callerAutocomplete'].value);
        if (this.rForm.controls['callerAutocomplete'].value === '' || typeof this.rForm.controls['callerAutocomplete'].value != 'string') {
          return this.filteredCallers = null;
        } else {
          this.messagesService.getCaller(this.rForm.controls['callerAutocomplete'].value, this.selectedClientId)
            .subscribe(res => {
              return this.filteredCallers = res.callers
            })
        }
      });
  }

  setClient(client) {
    if (client.clientId > 0) {
      this.selectedClientId = client.clientId;
    }
  }
  test(option) {

    console.log(option);
  }
  onKey($event) {
    console.log($event)
  }
  displayFnClients(client: ClientAutocomplete): any {
    return client ? client.clientName : client;
  }


  displayFnContacts(contact: ContactAutocomplete): any {
    return contact ? contact.contactName : contact;
  }

  displayFnCallers(caller: Callers): any {
    return caller ? caller.callerName : caller;
  }

  addOfficeDialog(): void {

    let dialogRef = this.dialog.open(AddOfficeDialogComponent, {
      width: '1000px'
    });
    dialogRef.afterClosed().subscribe(result => {

      this.officeComponent.getOffices(1);
    });

  }

  getOffices(isActive: number) {
    this.officesUsersRolesService.getOffices(0, '0', isActive, 1).subscribe(resp => {

      if (resp.errors.length > 0) {
        this.openSnackBar(resp.errors[0].error);
      } else {
        this.noOfOffices = resp.officeDetails.totalCount;
        this.listOfOffices = resp.officeDetails.offices;
      }
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  myControl: FormControl = new FormControl();

  options = [
    'One',
    'Two',
    'Three'
  ];

  filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.officeIdSubject.subscribe(id => this.officeId = Number(id));
    
    this.filteredOptions = this.myControl.valueChanges
      .startWith(null)
      .map(val => val ? this.filter(val) : this.options.slice());
  }

  filter(val: string): string[] {
    return this.options.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

}
