import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';



import * as moment from 'moment';


import { InvoicingService } from '../../services/invoicing.service';

import { InvoiceData, Invoices, Invoice, LineItems, InvoiceSettingsData, AddInvoiceData, AddInvoicePOST, AddLinePOST, AddLineItem, EmailInvoiceData, SentInvoiceData, InvoiceSentItem } from '../../models/invoiceData';


@Component({
  selector: 'app-client-sent-invoices',
  templateUrl: './client-sent-invoices.component.html',
  styleUrls: ['./client-sent-invoices.component.css'],
  providers: [InvoicingService]
})
export class ClientSentInvoicesComponent implements OnInit {
  listOfSentItems: InvoiceSentItem[] = [];
  noOfSentItems: number = 0;
  clientId: number = 0;

  constructor(public invoicingService: InvoicingService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    //this.getSentInvoices(this.clientId, 1);
  }

  setPage(event){
    this.getSentInvoices(this.clientId, event.pageIndex + 1);  
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "close", {
      duration: 2500,
    });
  }

  getSentInvoices(clientId: number, pageNo: number){

    this.invoicingService.invoiceSentItems(clientId, pageNo).subscribe(resp => {
      this.listOfSentItems = resp.sentInvoices.invoiceSentItems;
      this.noOfSentItems = resp.sentInvoices.totalCount;
    })
  }
}
